@media (min-width: 995px) {
}
@media (min-width: 995px) {
}
#expressyourself {
  display: block;
  width: 100%;
  background-image: url("assets\loginbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
#expressyourself .question {
  background-color: white;
}
#expressyourself .question textarea {
  border-color: #a8a8a8;
}
#expressyourself .restaurant-slide.enquete {
  display: none;
}
@media (min-width: 995px) {
  #expressyourself .restaurant-slide.enquete {
    display: block;
    margin-top: 20px;
  }
}
