﻿@import (reference) '_variables.less';

.login-page {
    background-image: url('./assets/bg-login.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: @lightBgGray;
    position: relative;
    padding: 0 20px;

    // padding à 0 pour l'affichage du carousel sur la page de connexion TimechefPro
    .tastytime &, 
    .timechefpro &, 
    .roomservicebyelior & {
        padding: 0;
    }

    .md( {
        background-image: url('./assets/bg-login-big3.jpg');
        background-size: cover;
        //dirty fix en attendant refonte css
        margin-top: -20px;
    }

    );

    .login-header {
        position: relative;
        background-color: @darkHeaderColor;
        height: 200px;
        width: 100%;
        display: flex;

        .roomservicebyelior & {
            background-color: white;
        }
    }

    .form-login-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 35px;

        .login-form-pro {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .form-column {
                width: 30%;
            }
        }
    }

    &.scrollable-content {
        height: 100vh;
    }

    .login-title {
        padding: 15px;
        box-sizing: border-box;
    }

    .lines .line {
        border-color: black;
    }

    header {
        background-color: transparent;
        position: relative;
        width: 100%;

        h1 {
            line-height: 70px;
            font-size: 42px;
        }
    }

    .login-container {
        .md( {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -40%);
            background-color: rgba(255, 255, 255, 0.7);
            padding: 0 20px 35px 20px;
        }

        );
    }

    .ssologin {
        text-align: center;
        margin: 0px 75px;
        font-size: 28px;

        .error {
            font-size: 16px;
            margin-top: 25px;
        }
    }

    .login-form {
        .md( {
            width: 500px;
        }

        );
    }

    [role=alert].error {
        font-weight: bold; //sinon rouge sur noir se voit mal
    }
}

.footer {
    margin-top: 0px;

    .services {
        margin-bottom: 0px;
    }
}
