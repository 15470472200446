.arrow-button-container {
  min-height: 100vh;
  width: 100%;
  margin: 0px auto 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 9999;
  display: none;
}
@media (min-width: 995px) {
  .arrow-button-container {
    display: block;
  }
}
.arrow-button-container .arrow-button {
  cursor: pointer;
  height: 100px;
  right: 25px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  position: absolute;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  pointer-events: auto;
}
.arrow-button-container .arrow-button .arrow {
  border-style: solid;
  border-color: white;
  border-width: 5px 5px 0 0;
  height: 1em;
  width: 1em;
  margin-top: 7px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
