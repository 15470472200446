﻿@import (reference) '_variables.less'; //pas référence sinon on a pas les fontfaces
@import (reference) '_mixins.less';

@import '_fonts.less';
@import '_header.less';
@import '_footer.less';

@import '_themes.less';
@import '_menu.less';
@import '_restaurants.less';
@import '_login.less';
@import '_registration.less';
@import '_controls.less';
@import '_allergene.less';
@import '_nutriscore.less';
@import '_animations.less';
@import '_culture.less';
@import '_infos.less';
@import '_legal.less';
@import '_theme.less';
@import '_forgotpassword.less';
@import '_resetpassword.less';
@import '_enquete.less';
@import '_nv.d3.less';
@import '_parametres.less';
@import '_dropdowncontainer.less';
@import '_checkbox.less';
@import '_email.less';
@import '_phone.less';
@import '_password.less';
@import '_accesEntreprise.less';
@import '_aide.less';
@import '_help.less';
@import '_conviveMoyenPaiements.less';
@import 'vendors/_vendors.override.less';

// réactivation du "mode mobile" pour TCPro
// @import '_desactivatedMobile.less';

img {
    outline: none;
}

.position-relative {
    position: relative;
}

html,
body {
    height: 100%;
    overflow: auto;
    user-select: none;
    margin: 0;
    background-color: @mainColor;
    font-family: @font;
    touch-action: none;

    .xs( {
        -ms-overflow-style: none !important;
    }

    );

    .md( {
        overflow: auto;
        height: auto;
    }

    );

    > [ui-view] {
        height: 100%;
    }
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h2 {
    font-size: 20px;

    @borderWidth: 40px;

    span {
        position: relative;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: normal;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: @borderWidth;
            top: 50%;
            transform: translateY(-50%);
            border-top: 2px solid;
            border-color: inherit;
        }

        &::before {
            left: -(@borderWidth + 10px);
        }

        &::after {
            right: -(@borderWidth + 10px);
        }
    }
}

a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    &.link {
        text-decoration: underline;
        font-style: italic;
    }
}

[ui-sref],
[data-ui-sref],
[x-ui-sref] {
    cursor: pointer;
}

.right {
    float: right;
}

.hide {
    display: none;
}

.pointer {
    cursor: pointer;
}

.hidden-desktop {
    .md( {
        display: none !important;
    }

    );
}

.scrollable-content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .md( {
        position: static;
        top: 0;
        overflow-y: hidden;

        &.help-page,
        &.aide-page {
            position: absolute;
            bottom: 0;
            margin-top: 295px;
            width: ~'calc(100% - 40px)';

            .min(1400px, {
                margin-top: @headerMdHeight;

                body[app="timechefpro"] & {
                    //surcharge pour timechefPro où l'entête est plus petite
                    margin-top: 190px;
                }
            }

            );

            body[app="timechefpro"] & {
                //surcharge pour timechefPro où l'entête est plus petite
                margin-top: 190px;
            }
        }
    }

    );

    &.momentum {
        overflow-y: inherit;
        top: inherit;
        bottom: inherit;
    }
}

::-ms-clear {
    display: none;
}

[disabled] {
    opacity: 0.5;
}

#container {
    [data-ui-view] {
        transition: transform .6s;
        backface-visibility: hidden;

        .md( {
            &.ng-leave {
                display: none;
            }
        }

        );
    }

    .header {
        transition: height 0.3s ease-in-out;

        .max(@screen-sm-max, {
            &.active {
                &:nth-of-type(1) {
                    ~ .content:nth-of-type(2) {
                        min-height: ~'calc(100% - @{headerXsHeight})';
                        top: @headerXsHeight;
                    }
                }

                &:nth-of-type(2) {
                    ~ .content:nth-of-type(3) {
                        min-height: ~'calc(100% - @{headerXsHeight})';
                        top: @headerXsHeight;
                    }
                }
            }
        }

        );

        &:not(.active) {
            display: none;
        }
    }

    .footer {
        &:not(.active) {
            display: none;
        }
    }

    .content {
        min-height: 100%;
        background: white;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        .md( {
            position: static;
            min-height: 0; //dirty fix en attendant refonte css
            margin-top: 20px;

            header {
                //dirty fix en attendant refonte css
                margin-top: -30px;
                padding-top: 15px;

                &.easypass-title {
                    margin-top: 0;
                }
            }
        }

        );
    }

    .max(@screen-sm-max, {
        &.top-transition [data-ui-view] {
            &.ng-enter {
                transform: translate3d(0, 100vh, 0);
            }

            &.ng-enter-active {
                transform: translate3d(0, 0, 0);
            }

            &.ng-leave-active {
                transform: translate3d(0, -100vh, 0);
            }
        }

        &.none-transition [data-ui-view] {
            transition: 0s;

            &.ng-leave {
                display: none;
            }
        }

        &.bottom-transition [data-ui-view] {
            &.ng-enter {
                transform: translate3d(0, -100vh, 0);
            }

            &.ng-enter-active {
                transform: translate3d(0, 0, 0);
            }

            &.ng-leave-active {
                transform: translate3d(0, 100vh, 0);
            }
        }

        &.right-transition [data-ui-view] {
            &.ng-enter {
                transform: translate3d(-100%, 0, 0);
            }

            &.ng-enter-active {
                transform: translate3d(0, 0, 0);
            }

            &.ng-leave-active {
                transform: translate3d(100%, 0, 0);
            }
        }

        &.left-transition [data-ui-view], [data-ui-view] {
            &.ng-enter {
                transform: translate3d(100%, 0, 0);
            }

            &.ng-enter-active {
                transform: translate3d(0, 0, 0);
            }

            &.ng-leave-active {
                transform: translate3d(-100%, 0, 0);
            }
        }
    }

    );
}

.margin-top {
    margin-top: 12px;
}

.margin-bottom {
    margin-bottom: 12px;
}

.square-button {
    border: none;
    text-transform: uppercase;
    padding: 5px;
    font-size: 12px;
    color: white;
    background: #363636;
    margin: 0 5px 0 0;
    display: inline-block;

    &.red{
        background: red!important;
    }

    &.white{
        background: #7b7676!important;
        color: white;
    }

    &.orange{
        background: orange!important;
    }

    &.left{
        float: left;
    }

    img,
    svg {
        max-height: 15px;
        max-width: 20px;
        margin-right: 5px;
        margin-bottom: -4px;
    }

    &.big {
        font-size: 15px;
        padding: 4px;

        img,
        svg {
            max-height: 14px;
            margin-right: 4px;
            margin-bottom: -2px;
        }
    }

    span{
        float: right;
    }
}

.loading-message {
    margin-top: 40px;
    margin-bottom: 40px; //pour limiter l'effet d'ascenceur qui clignote avec l'affichage du loader
    position: relative;
    display: flex;
    justify-content: center;

    span {
        line-height: 40px;
        font-weight: normal;
    }

    .loader {
        margin-right: 10px;
        animation: rotate 0.7s linear infinite;
    }

    [type=submit].loading + .loader {
        display: block;
    }
}

.loader-single {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    &.loader-bckg {
        bottom: 0;
        background-color: @lightBgGray;

        .tastytime & {
            background-color: @lightBeige;
        }

        .roomservicebyelior & {
            background-color: @eliorLightRed;
        }
    }
}

.clear {
    &:after {
        clear: both;
        content: '';
        display: block;
    }
}

@toogleWidth: 60px;
@innerMargin: 8px;
@pictoSize: 18px;

.toogle-button {
    width: @toogleWidth;
    height: @toogleWidth/2;
    margin-left: auto;
    position: relative;
    border-radius: 2px;
    margin-right: 20px;
    background: url('./assets/toogle-button/cross.png') @innerMargin~"/"@pictoSize - 2 no-repeat, url('./assets/toogle-button/check.png') @toogleWidth - (@pictoSize+@innerMargin) ~"/"@pictoSize no-repeat, #999;

    @thumbMargin: 3px;

    &:before {
        content: ' ';
        display: block;
        border-radius: 2px;
        transition: transform .4s;
        background: url('./assets/toogle-button/burger.png') center/@pictoSize - 8 #fff no-repeat;
        position: absolute;
        transform: translateX(@toogleWidth/2);
        margin: @thumbMargin;
        width: @toogleWidth/2 - @thumbMargin*2;
        height: @toogleWidth/2 - @thumbMargin*2;
    }

    &.active {
        background-color: @green;

        &:before {
            transform: translateX(0);
        }
    }
}

@arrowSize: 10px;

.cheerLeader-batoon {

    .mobile( {
        border-left: 7px solid #67a538;

        img {
            padding-left: 17px;
        }
    }

    );

    .timechefpro & {
        .md( {
            border-bottom: 3px solid white;
        }

        );
    }

    .tastytime & {
        .md( {
            border-bottom: 3px solid white;
        }

        );
    }

    .roomservicebyelior & {
        .md( {
            border-bottom: 3px solid white;
        }

        );
    }

    .md( {
        border-bottom: 3px solid #67a538;
    }

    );
}

.chevroon {
    display: block;
    width: @arrowSize;
    height: @arrowSize;
    border-right: 1px solid black;
    border-top: 1px solid black;
    transform: rotate(45deg);
    transition: all 0.5s ease;

    @arrowBigSize: 15px;
    @arrowSmallSize: 8px;

    &.left {
        transform: rotate(-135deg);
    }

    &.down {
        transform: rotate(135deg);
    }

    &.big {
        width: @arrowBigSize;
        height: @arrowBigSize;
    }

    &.small {
        width: @arrowSmallSize;
        height: @arrowSmallSize;
    }

    &.disable {
        opacity: .2;
    }
}

.account {
    padding: 10px 0;
    position: relative;
    @qrcodeSize: 80px;

    .timechefpro & {
        position: absolute;
        top: 50px;
        right: 30px;
        z-index: 9999;
    }

    .tastytime & {
        position: absolute;
        top: 50px;
        right: 30px;
        z-index: 9999;
    }

    .roomservicebyelior & {
        position: absolute;
        top: 50px;
        right: 30px;
        z-index: 9999;
    }

    .qrcode {
        margin: 0 20px;
        border-radius: 50%;
        width: @qrcodeSize;
        height: @qrcodeSize;
        background: white;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        .qrcode-button {
            height: 100%;
            width: 100%;
            background-image: url(assets/qrcode.svg);
            background-size: 65%;
            background-repeat: no-repeat;
            background-position: center;
        }

        span {
            font-size: 37px;
            color: #999;
        }
    }

    .account-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
            color: #fff;
            font-size: 20px;
        }
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

.sites {
    padding: 10px 0;
    position: relative;
    @qrcodeSize: 80px;

    .timechefpro & {
        position: absolute;
        top: 100px;
        right: 30px;
        z-index: 9999;
    }

    .tastytime & {
        position: absolute;
        top: 100px;
        right: 30px;
        z-index: 9999;
    }

    .roomservicebyelior & {
        position: absolute;
        top: 100px;
        right: 30px;
        z-index: 9999;
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rotate {
    .refresh-icon {
        animation: rotating 0.8s linear infinite;
    }
}

ul.menu-list.scrollable-content {
    height: calc(~'100vh - 70px');

    .md( {
        height: auto;
    }

    );
}

ul.menu-list,
div.menu-list {
    padding: 0;
    margin: 0;
    list-style: none;

    .error {
        border: none !important;
    }

    .md( {
        margin: 50px auto;
        width: 970px;
        background: #fff;
        box-shadow: 0 0 5px fade(#000, 50);

        .error {
            border: none !important;
            margin-bottom: 15px;
        }
    }

    );

    li,
    div {

        &:not(.no-border):not(.fix-ios-scroll) {
            padding-left: 18px;
            padding-right: 18px;
        }

        &:not(.separator):not(.no-border):not(.fix-ios-scroll) {
            height: 1.3em;
            padding-top: 15px;
            padding-bottom: 15px;
            border-top: 1px solid @lightGray;
            border-bottom: 1px solid @lightGray;

            + li:not(.separator),
            + div:not(.separator) {
                border-top: none;
            }

            .picto {
                width: 1.3em;
                margin-right: 12px;
                float: left;
            }

            .title {
                font-size: 18px;
                position: relative;
            }

            .subtext {
                color: @grayControl;
                float: right;
                font-size: 1.1em;

                .md( {
                    /*text-align: center;
                    float: none;*/
                }

                );
            }

            .delete {
                @size: 30px;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
                width: @size;
                height: @size;
                background-color: #e6e6e6;
                background-position: 50%;
                background-size: 50%;
                background-repeat: no-repeat;
                background-image: url('./assets/pictos/croix_gray.png');
                padding: 0;
                cursor: pointer;
            }

            &.has-next {
                .title::after {
                    content: '';
                    height: 1.3em;
                    width: 1em;
                    background: url(./assets/arrow-right.png) center / 50% no-repeat;
                    margin-left: 5px;
                    font-size: 18px;
                    display: block;
                    position: absolute;
                    top: 0;
                    right: -20px;
                }
            }

            &.selected {
                // ":last-child::after" ne fonctionne pas sous Chrome lorsque la classe "selected" est changée dynamiquement avec Angular
                &::after {
                    content: '';
                    height: 1.3em;
                    width: 1em;
                    float: right;
                    background: url(./assets/check.png) center / contain no-repeat;
                    font-size: 18px;
                }
            }
        }

        &.separator {
            background: @lightGray;
            color: @gray;
            text-transform: uppercase;
            font-size: 13px;
            padding-top: 7px;
            padding-bottom: 7px;

            .md( {
                text-align: center;
            }

            );
        }
    }
}

.score colored-svg svg {
    width: 20px;
    height: 20px;

    * {
        transition: fill .6s;
    }
}

.center {
    display: block;
    margin: auto;
}

.subtitle {
    background: #e7e7e7;
    display: block;
    color: #949494;
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 18px;
}

.content-padding {
    padding: 20px;
}

.container {
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    &:extend(.clearfix all);

    > * {
        box-sizing: border-box;
    }

    .md( {
        padding-left: floor((@gutter-width / 2));
        padding-right: ceil((@gutter-width / 2));
        width: @container-md;
        margin-bottom: 30px; //=> A REFACTO
    }

    );

    .lg( {
        width: @container-lg;
    }

    );
}

.clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

.desktop-hide {
    display: block;

    .md ( {
        display: none;
    }

    );
}

.chevroon,
.chevroon-container {
    cursor: pointer;
}

@media(orientation: landscape) {
    .hidden-landscape {
        display: none;
    }
}

.logo {
    height: 72px;
    background-size: auto 42px;
    background-repeat: no-repeat;
    background-position: center;

    &.register {
        background-size: auto 49px;
    }
}

.logo {
    background-image: url(./assets/logo-light.png);
}

colored-svg {
    width: 100%;
    height: 100%;
}

.hidden-mobile{
    .mobile({
        display: none;
    });
}

// HACK : les classes ci doussous commençant par 'hack' ne doivent pas étre utilisée.
// Elles sont définies juste pour charger les fonts ci dessous au démarrage.
// Ma conclusion été que les fonts personalisés sont téléchargés uniquement s'il sont définies dans ce fichier.
// Si vous supprimez leurs définitions vous verrez que tous les elements utilisant
// ces font perdent la forme,
// Je n'ai pas trop creusé pour résoudre le probléme de façon plus propre.
// c'est quelqu'un à une idée sur comment le faire qu'il n'hésite pas à changer.
.hack-font-pluto{
    font-family: @fontPluto;
}
.hack-font-dot-matrix{
    font-family: @fontDotMatrix;
}
.hack-font-avant-garde{
    font-family: @fontAvantGarde;
}
