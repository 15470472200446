.dt-payment-error {
  background: #F2F2F1;
  padding: 20px;
  margin: 15px;
}
.dt-payment-error .libelle {
  font-size: 20px;
  margin: 0 0 20px;
}
@media (min-width: 995px) {
  .dt-payment-error {
    margin: 0 auto;
    width: 970px;
  }
}
