dt-basket-article-portion {
  display: block;
}
dt-basket-article-portion + dt-basket-article-portion {
  margin: 10px 0 0;
}
.basket-article-portion {
  display: flex;
  align-items: center;
}
.basket-article-portion > * {
  display: inline-block;
}
.basket-article-portion .remove {
  display: inline-block;
  margin: 0 10px 0 0;
}
.basket-article-portion .remove img {
  width: 20px;
}
.basket-article-portion .type {
  flex: 1;
  text-transform: uppercase;
  font-weight: bold;
}
.basket-article-portion .quantity {
  background: #fff;
  border: 1px solid #E7E7E7;
  height: 30px;
  width: 40px;
  text-align: center;
}
.basket-article-portion .price {
  color: #67A537;
  margin: 0 0 0 10px;
  width: 60px;
  text-align: right;
}
@media (min-width: 995px) {
  .basket-article-portion .remove {
    margin: 0 20px 0 0;
  }
}
