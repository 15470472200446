.title .picto {
  /*width: 27px;*/
  height: 27px;
  margin-right: 8px;
  box-sizing: border-box;
  /*display: flex;
    align-items: center;
    justify-content: center;*/
  border-radius: 50%;
  background-color: white;
  padding: 5px;
  display: block;
  width: auto;
}
.title .picto.bgGreen {
  background: #67A537;
}
.tastytime .title .picto.bgGreen {
  background: #9A8C7D;
}
.roomservicebyelior .title .picto.bgGreen {
  background: #E50051;
}
.title .picto.bgGray {
  background: #1A1A1A;
}
.title .picto svg {
  width: 1em;
  height: 1em;
}
