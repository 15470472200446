dt-header {
  display: block;
  height: 295px;
}
@media (max-width: 1400px) {
  dt-header {
    height: 295px;
  }
}
.dt-header {
  height: 100%;
}
@media (min-width: 995px) {
  .dt-header {
    margin-top: 0 !important;
  }
}
.dt-header .bonplan-logo {
  background: url('assets/dinnertakeaway/bonplan.png') no-repeat center;
  background-size: auto 40%;
  height: 100%;
  width: 100%;
}
@media (min-width: 995px) {
  .dt-header .bonplan-logo {
    margin-top: -30px;
  }
}
.dt-header .basket-logo {
  background: url(assets/dinnertakeaway/Panier.png) no-repeat 50%;
  background-size: contain;
  height: 45px;
  width: 45px;
  position: absolute;
  right: 15px;
  top: 15px;
}
