.dt-day {
  box-sizing: border-box;
  display: block;
  padding: 10px;
  min-height: 94px;
}
.dt-day.disabled {
  opacity: 0.6;
}
@media (min-width: 995px) {
  .dt-day {
    padding: 15px;
  }
}
.dt-day .day-infos {
  display: flex;
}
.dt-day .day,
.dt-day .message,
.dt-day .noLockerAvailable {
  align-self: center;
}
.dt-day .day {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}
.dt-day .date {
  font-size: 20px;
}
.dt-day .order-inprogress {
  color: #67A537;
  font-style: italic;
  font-weight: bold;
  margin: 3px 0 0;
}
.dt-day .noLockerAvailable {
  color: #DE2B2B;
}
.dt-day .day-details {
  display: flex;
  padding: 10px 0 0 10px;
}
@media (min-width: 995px) {
  .dt-day .day-details {
    padding-left: 20px;
  }
}
.dt-day .cannotOrder {
  color: #B40000;
}
.dt-day .day-articles {
  flex-grow: 1;
  color: #949494;
  list-style: none;
  margin: 0;
  padding: 0;
}
.dt-day .day-articles li + li {
  margin: 3px 0 0;
}
.dt-day .more {
  color: #67A537;
  align-content: flex-end;
  align-self: flex-end;
  white-space: nowrap;
}
.dt-day .locker,
.dt-day .fridge {
  color: #67A537;
  display: flex;
  font-size: 20px;
}
.dt-day .locker span,
.dt-day .fridge span,
.dt-day .locker i,
.dt-day .fridge i {
  align-self: center;
}
.dt-day .locker i,
.dt-day .fridge i {
  background: no-repeat center / contain;
  margin-left: 5px;
  width: 25px;
  display: block;
}
.dt-day .locker i {
  height: 25px;
  background-image: url('assets/dinnertakeaway/locker.svg');
}
.dt-day .fridge i {
  height: 35px;
  background-image: url('assets/dinnertakeaway/frigo.svg');
}
