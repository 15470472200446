﻿@import (reference) "_variables.less";

.dropdown-container {
    overflow-y: hidden;

    .chevroon.active {
        transform: rotate(135deg);
    }

    &.active {
        .dropdown-content {
            transform: translateY(0) translateZ(0);
        }
    }

    .title {
        z-index: 1;
        background-color: #e7e7e7;
        height: 70px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 300;
        padding: 0 5%;

        .title-block {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .progressbar {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            background-color: #67a538;
            height: 7px;
        }

        .staticbar {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background-color: #b9b9b9;
            width: 100%;
            height: 7px;
        }

        &.active {
            .chevroon {
                transform: rotate(135deg) translateZ(0);
                transition: all 0.7s ease;
            }
        }
    }

    .dropdown-content {
        z-index: 2;
        overflow-y: hidden;
        transition: all 0.5s ease-in-out;
        width: 100%;
        transform: translateY(-100%) translateZ(0);

        .dropdown-item {
            height: 55px;
            border-bottom: 1px solid #e7e7e7;
            display: flex;
            align-items: center;
            padding: 0 10%;
            font-size: 18px;
            font-weight: 300;
            text-transform: uppercase;
            margin-left: auto;
        }
    }
}
