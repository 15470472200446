.dt-portion {
  background-color: #f9f9f9;
  border-top: 1px solid #E7E7E7;
  display: flex;
  padding: 10px 15px;
}
.dt-portion .type,
.dt-portion .amount,
.dt-portion .more-less {
  align-self: center;
}
.dt-portion .type {
  font-weight: bold;
  text-transform: uppercase;
}
.dt-portion .type:after {
  content: '';
  display: inline-block;
  background: 0 50% no-repeat;
  background-size: auto 100%;
  margin-left: 8px;
  height: 19.5px;
  width: 55px;
}
.dt-portion .type.portion-solo:after {
  background-image: url('assets/dinnertakeaway/portion_solo.svg');
}
.dt-portion .type.portion-family:after {
  background-image: url('assets/dinnertakeaway/portion_family.png');
}
.dt-portion .type.portion-duo:after {
  background-image: url('assets/dinnertakeaway/portion_duo.svg');
}
.dt-portion .amount {
  flex-grow: 1;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 0 15px;
  text-align: right;
}
.dt-portion .amount .price {
  color: #67A537;
}
.dt-portion more-less {
  margin-left: 15px;
}
