﻿@import (reference) './_variables.less';
@import (reference) './_mixins.less';

.convive-moyen-paiements {
    @conviveMoyenPaiementHeight: 40px;
    margin-bottom: 15px;

    .convive-moyen-paiement {
        background-color: white;
        /*border-bottom: 1px solid #eeeeee;*/
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: @conviveMoyenPaiementHeight;
        // <-- Forme de Bouton
        border: 1px solid #eeeeee;
        border-radius: 4px;
        margin: 2px 5px;
        background-color: @lightGray;
        // Forme de Bouton -->
        .convive-moyen-paiement-logo {
            margin: 5px 10px;
            background-size: contain;
            background-repeat: no-repeat;
            width: 37px;
            height: 25px;
            background-color: #fff;
            border: 1px solid #808080;
            border-radius: 3px;


            &.convive-moyen-paiement-logo-cb {
                background-image: url('assets/moyen-paiement/CB.png');
            }

            &.convive-moyen-paiement-logo-visa {
                background-image: url('assets/moyen-paiement/Visa.png');
            }

            &.convive-moyen-paiement-logo-visa-debit {
                background-image: url('assets/moyen-paiement/VisaDebit.png');
            }

            &.convive-moyen-paiement-logo-visa-electron {
                background-image: url('assets/moyen-paiement/VisaElectron.png');
            }

            &.convive-moyen-paiement-logo-mastercard, &.convive-moyen-paiement-logo-mastercard-debit {
                background-image: url('assets/moyen-paiement/Mastercard.png');
            }

            &.convive-moyen-paiement-logo-american-express {
                background-color: #006FCF;
                background-image: url('assets/moyen-paiement/AmericanExpress.png');
            }
        }

        .convive-moyen-paiement-type {
            margin: 5px 10px;
            font-weight: bold;
            width: 135px;
        }

        .convive-moyen-paiement-numero {
            margin: 5px 10px;
            flex-grow: 1;
            flex-shrink: 1;
        }

        .convive-moyen-paiement-date-expiration {
            margin: 5px 10px;
        }

        .convive-moyen-paiement-other {
        }

        &.active {
            background-color: #b0b0b0;
            border-color: @lightGreen;
            color: white;
        }
    }
}
