﻿@import (reference) '_variables.less';

.min(@width, @rules) {
    @media (min-width: @width) {
        @rules();
    }
}

.max(@width, @rules) {
    @media (max-width: @width) {
        @rules();
    }
}

.mobile(@rules) {
    .max(@screen-sm-max, @rules);
}

.lg(@rules) {
    .min(@screen-lg-min, @rules);
}

.md(@rules) {
    .min(@screen-md-min, @rules);
}

.sm(@rules) {
    .min(@screen-sm-min, @rules);
}

.xs(@rules) {
    .max(@screen-xs-max, @rules);
}

.TcProGreenToTastyTimeBeige {
    filter: hue-rotate(300deg) saturate(20%) brightness(99%); //Green TcPro to Beige Clair TastyTime
}
.TcProGreenToEliorRed {
    filter: hue-rotate(611deg) saturate(500%) brightness(100%); //Green TcPro to Red RoomServiceByElior
}