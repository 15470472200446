vote-count-down {
  display: flex;
  display: -webkit-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: pluto;
}
@media (min-width: 995px) {
  vote-count-down {
    display: block;
    position: relative;
  }
}
vote-count-down .vote-count-down-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}
@media (min-width: 995px) {
  vote-count-down .vote-count-down-container {
    background: #E7E7E7;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
vote-count-down .vote-count-down-container .title-count-down {
  font-size: 1.1em;
  line-height: 40px;
  color: white;
}
@media (min-width: 995px) {
  vote-count-down .vote-count-down-container .title-count-down {
    color: #1A1A1A;
  }
}
vote-count-down .vote-count-down-container .informations {
  text-transform: uppercase;
  color: #E7E7E7;
  margin-top: 5px;
  display: table;
  width: 80vw;
  max-width: 300px;
}
@media (min-width: 995px) {
  vote-count-down .vote-count-down-container .informations {
    color: #1A1A1A;
  }
}
vote-count-down .vote-count-down-container .informations .col {
  display: table-cell;
  text-align: center;
}
vote-count-down .vote-count-down-container .informations .col .value {
  font-size: 1.8em;
  width: 100%;
  font-family: dotmatrix;
  line-height: 30px;
}
vote-count-down .vote-count-down-container .informations .col .label {
  display: block;
  font-size: 10px;
  text-align: center;
}
vote-count-down .vote-count-down-container .informations .separator {
  font-size: 40px;
}
vote-count-down .vote-count-down-container .white {
  color: white;
}
vote-count-down .vote-count-down-container .gray {
  color: #ACACAC;
}
