@media (min-width: 995px) {
  .moyen-paiements-parametres-page {
    min-height: calc(100vh - 315px);
    background: lightgray;
    position: absolute;
    width: 100%;
  }
}
.moyen-paiements-parametres-page .scrollable-content {
  height: calc(100vh - 170px);
}
@media (min-width: 995px) {
  .moyen-paiements-parametres-page .scrollable-content {
    height: auto !important;
  }
}
.moyen-paiements-parametres-page .credit-container {
  background-color: white;
}
@media (min-width: 995px) {
  .moyen-paiements-parametres-page .credit-container {
    margin: 50px auto;
    width: 970px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 50px;
  }
}
.moyen-paiements-parametres-page .credit-container .convive-moyen-paiements-empty {
  padding: 7px 18px;
}
.moyen-paiements-parametres-page .credit-container .convive-moyen-paiements-delete-button {
  margin-top: 15px;
}
