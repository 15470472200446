﻿@import (reference) '_variables.less';

@allergenePictoSize: 45px;

.allergen-page {

    .scrollable-content{
        height: calc(~'100vh - 70px');
    }

	.md( {
		background: @desktopGrayBackground;
		position: absolute;
		min-height: 100%;
		width: 100%;
	}

	);

	.scrollable-content {
		.md( {
			margin: 50px 10%;
			background: #fff;
			box-shadow: 0 0 5px fade(#000,50);
            height: auto;
		}

		);
	}

	.allergene-mention-one {
		display: block;
		color: #949494;
		font-size: 18px;
		font-style: italic;
		padding: 18px 18px 9px 18px;
	}

	.allergene-mention-two {
		display: block;
		color: #949494;
		font-size: 18px;
		font-style: italic;
		padding: 9px 18px 9px 18px;
	}

	.allergene-mention-three {
		display: block;
		color: #949494;
		font-size: 18px;
		font-style: italic;
		padding: 9px 18px 18px 18px;
	}

	.allergene {
		border-bottom: 1px solid #eeeeee;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 20px;
	}

	.allergene-picto {
		margin: 15px;
	}
}

.allergene-picto {
	display: flex;
	justify-content: center;
	align-items: center;
	width: @allergenePictoSize;
	height: @allergenePictoSize;
	border-radius: 50%;
	background: #B0ACAD;
	transition: background-color .6s;

	svg{
		transform: scale(1.3);
		.st0{
			fill:white;
			&.st1{
				fill:transparent;
			}
		}
	}

	&.nonallergic {
	}

	&.enabled {
		background: @green;
	}

	&.allergic {
		background-color: @errorColor;
	}

	img {
		height: @allergenePictoSize + 25px;		
	}
}
