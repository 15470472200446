@media (min-width: 995px) {
  .click-and-collect-innovorder-page {
    background: lightgray;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .click-and-collect-innovorder-page .scrollable-content {
    height: 100% !important;
  }
}
.click-and-collect-innovorder-page .innovorder-container {
  display: block;
  border: 0;
  width: 100%;
}
.click-and-collect-innovorder-page .scrollable-content {
  height: calc(100vh - 70px);
}
