@media (min-width: 995px) {
  .notifications-page {
    background: lightgray;
    position: absolute;
    min-height: calc(100% - 315px);
    height: auto;
    width: 100%;
  }
}
.notifications-page .message-empty {
  text-align: center;
  margin-top: 20px;
}
.notifications-page .loading-message {
  height: 50px;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
}
.notifications-page.has-loader .scrollable-content {
  top: 50px;
}
.notifications-page .scrollable-content {
  padding: 0;
  margin: 0;
  height: calc(100vh - 70px);
}
@media (min-width: 995px) {
  .notifications-page .scrollable-content {
    margin: 50px auto;
    width: 970px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    height: auto;
  }
}
@media (min-width: 995px) {
  .notifications-page .scrollable-content ul.menu-list {
    margin: 0;
    display: inline-block;
  }
}
.notifications-page .scrollable-content .separator {
  color: black;
  font-weight: bold;
}
.notifications-page .scrollable-content .notification {
  position: relative;
  height: auto !important;
  list-style: none;
}
.notifications-page .scrollable-content .notification.unread {
  background: #fafafa;
}
.notifications-page .scrollable-content .notification.unread .picto circle {
  fill: #67A537;
}
.notifications-page .scrollable-content .notification.unread .picto path {
  fill: white;
}
.notifications-page .scrollable-content .notification.unread .time {
  font-weight: bold;
}
.notifications-page .scrollable-content .notification.unread .title {
  font-weight: bold;
}
.notifications-page .scrollable-content .notification .picto {
  height: 60px !important;
  width: 60px !important;
}
.notifications-page .scrollable-content .notification .picto svg {
  height: 100%;
  width: 100%;
}
.notifications-page .scrollable-content .notification .time {
  color: #67A537;
}
.notifications-page .scrollable-content .notification .subtext {
  float: left !important;
  max-width: 75%;
  font-style: italic;
  line-height: 1.2em;
  white-space: pre-wrap;
}
.notifications-page .scrollable-content .notification .subtext::after {
  position: absolute;
  top: 50%;
  right: 18px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
