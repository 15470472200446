solde-component .alert {
  background-color: red!important;
}
solde-component .dirty {
  background-color: gray!important;
}
solde-component .solde {
  display: inline-block;
  background-color: #67A537;
  color: #FDFDFD !important;
  padding: 5px 4px;
  font-size: 18px;
  line-height: 18px;
  margin-right: 5px;
  font-weight: bold;
  border-radius: 5px;
}
solde-component .refresh {
  background-color: #FDFDFD;
  width: 28px;
  height: 28px;
  display: inline-block;
  vertical-align: bottom;
  border-radius: 5px;
  padding: 0;
  margin: 0 0 0 2px;
  cursor: pointer;
}
solde-component .refresh .refresh-icon {
  background: url(assets/refresh.png) no-repeat 50%;
  padding: 4px;
  height: 20px;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
solde-component .rotate .refresh-icon {
  -webkit-animation: rotating 0.8s linear infinite;
          animation: rotating 0.8s linear infinite;
}
solde-component .popin-solde {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
