.ng-animate .ticket-offset,
.ng-animate .semi-pic-container,
.ng-animate .semi-pic-container-left {
  opacity: 0;
}
.ticket-offset,
.semi-pic-container,
.semi-pic-container-left {
  transition: opacity 0.3s;
}
#navigation-container {
  width: 100%;
  overflow-x: hidden;
  top: 185px;
}
#navigation-container button {
  margin-bottom: 0;
}
#navigation-container #navigation-slider {
  width: 200%;
  -webkit-transform: translateZ(0) translateX(0);
          transform: translateZ(0) translateX(0);
  transition: all ease-in-out 0.4s;
}
#navigation-container #navigation-slider .tickets-view,
#navigation-container #navigation-slider .refill-view {
  float: left;
  width: 50%;
}
#navigation-container #navigation-slider.is-not-refill {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
#navigation-container .title {
  text-transform: uppercase;
  color: #949494;
  font-weight: 700;
  font-size: 14px;
  margin: 6px 18px;
  display: block;
}
#ticket-detail {
  background-color: #e7e7e7;
  position: relative;
  min-height: calc(100% - 30px);
  margin-top: 0;
  padding-bottom: 30px;
}
#ticket-detail .ticket-offset {
  background-color: white;
  width: 3.6vw;
  float: left;
  height: 1px;
  margin-top: 15px;
  height: calc(100% - 3.6vw - 15px);
  position: absolute;
  top: 0;
  bottom: 0;
}
#ticket-detail .actual-ticket {
  background-color: white;
  width: 80%;
  max-width: 600px;
  margin: auto;
  margin-top: 15px;
}
#ticket-detail .actual-ticket .container {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 0;
}
#ticket-detail .actual-ticket .chevroon-container {
  padding: 10px 10px 10px 5px;
}
#ticket-detail .actual-ticket .chevroon-container.left {
  padding: 10px 5px 10px 10px;
}
#ticket-detail .actual-ticket label {
  color: black;
  width: 100%;
}
#ticket-detail .actual-ticket .line-libelle {
  float: left;
  max-width: 80%;
}
#ticket-detail .actual-ticket .line-price {
  float: right;
}
#ticket-detail .actual-ticket h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#ticket-detail .actual-ticket h3 .title {
  text-align: center;
  max-width: 80%;
}
#ticket-detail .actual-ticket h3.thanks {
  display: block;
  text-align: center;
}
#ticket-detail .actual-ticket .prices-line {
  border-top: 1px black dashed;
  border-bottom: 1px black dashed;
  margin-top: 10px;
}
#ticket-detail .actual-ticket .prices-line .libelle {
  float: left;
  width: 80%;
}
#ticket-detail .actual-ticket .prices-line .price {
  float: right;
}
#ticket-detail .actual-ticket .prices-line table {
  width: 100%;
}
#ticket-detail .actual-ticket .prices-line table .td-price {
  text-align: right;
}
#ticket-detail .actual-ticket .total-line h3 {
  margin: 10px 0;
}
#ticket-detail .actual-ticket .total-line h3 .libelle {
  float: left;
}
#ticket-detail .actual-ticket .total-line h3 .price {
  float: right;
}
#ticket-detail .actual-ticket .account-status {
  width: 100%;
}
#ticket-detail .actual-ticket .account-status .right {
  text-align: right;
}
#ticket-detail .actual-ticket hr {
  background-color: #94949414;
}
#ticket-detail .pic-container {
  width: 80%;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
}
#ticket-detail .pic-container .translate-container {
  width: 110%;
  display: flex;
  -webkit-transform: translateX(-4.5%);
          transform: translateX(-4.5%);
}
#ticket-detail .pic-container .translate-container .pic {
  margin: 0 auto;
  width: 4.5%;
  height: 0;
  padding-left: 4.5%;
  padding-top: 4.5%;
  overflow: hidden;
  box-sizing: content-box;
  float: left;
}
#ticket-detail .pic-container .translate-container .pic div {
  width: 0;
  height: 0;
  margin-left: -500px;
  margin-top: -500px;
  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  border-top: 500px solid white;
}
.ticket-container {
  position: relative;
  overflow: hidden;
}
.semi-pic-container {
  width: 3.6vw;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  overflow: hidden;
}
.semi-pic-container .semi-pic {
  margin: 0 auto;
  width: 3.6vw;
  height: 0;
  padding-left: 4.5vw;
  padding-top: 4.5vw;
  overflow: hidden;
  box-sizing: content-box;
  float: left;
  position: absolute;
  right: 0;
  bottom: 0;
}
.semi-pic-container .semi-pic div {
  width: 0;
  height: 0;
  margin-left: -500px;
  margin-top: -500px;
  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  border-top: 500px solid white;
}
.semi-pic-container-left {
  width: 3.6vw;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  overflow: hidden;
}
.semi-pic-container-left .semi-pic {
  -webkit-transform: translateX(3.6vw);
          transform: translateX(3.6vw);
  margin: 0 auto;
  width: 3.6vw;
  height: 0;
  padding-left: 4.5vw;
  padding-top: 4.5vw;
  overflow: hidden;
  box-sizing: content-box;
  float: left;
  position: absolute;
  right: 0;
  bottom: 0;
}
.semi-pic-container-left .semi-pic div {
  width: 0;
  height: 0;
  margin-left: -500px;
  margin-top: -500px;
  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  border-top: 500px solid white;
}
#ticket-print {
  background-color: black;
  font-weight: 900;
  color: white;
  width: 500px;
  height: 100px;
}
.ticket-print-btn {
  width: 80%;
  max-width: 600px;
  margin: 10px auto;
}
