.main .cac .gradient-container {
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  position: relative;
  cursor: pointer;
}
.product-menu {
  background-color: #f5f5f5;
}
.product-menu.scrollable-content {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
@media (min-width: 995px) {
  .product-menu.scrollable-content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: static;
  }
}
@media (min-width: 995px) {
  .product-menu {
    padding-top: 65px;
  }
}
.product-menu .categorie-title {
  text-transform: uppercase;
  color: #949494;
  font-weight: 700;
  font-size: 14px;
  margin: 6px 18px;
  display: block;
}
.panier-container .panier {
  padding: 15px;
  display: inline-block;
  background: #80c34c;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 20px;
  background: #1A1A1A url('assets/pictos/panier.svg');
  background-repeat: no-repeat;
  background-size: 25px;
  padding-left: 45px;
  background-position: 10px 50%;
  left: 0;
  top: -100px;
  display: none;
  cursor: pointer;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 100px;
}
@media (min-width: 995px) {
  .panier-container .panier {
    display: block;
  }
}
.categories-container {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative !important;
  background: #80c34c;
}
.categories-container .leave-search {
  background: white;
  padding: 3px;
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  float: right;
}
.categories-container .leave-search img {
  height: 13px;
  width: 13px;
  padding-left: 3px;
}
.categories-container .categorie {
  padding: 15px;
  display: inline-block;
  background: #80c34c;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}
.categories-container .categorie.active {
  background: #67A537;
  font-weight: 600;
}
.categories-container .categorie input[type=text] {
  background-color: transparent;
  height: 15px;
  width: 85%;
  margin-left: 5px;
  font-size: 18px;
  margin: 0;
  background: transparent;
  border: none;
  color: white;
  height: 20px;
}
.categories-container .categorie input[type=text]::-webkit-input-placeholder {
  color: white;
}
.categories-container .categorie input[type=text]:focus {
  outline: none;
}
.categories-container .search-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 30px);
}
@media (min-width: 995px) {
  .categories-container .search-panel {
    left: 160px;
    width: calc(100% - 190px);
  }
}
.categories-container.disabledoverflow {
  overflow-x: hidden;
}
.product {
  margin: 18px;
  background-color: white;
  position: relative;
  margin-top: 5px;
}
.product .product-image {
  height: 130px;
  width: 100%;
  background-size: cover;
}
.product .product-information {
  padding: 17px;
  min-height: 43px;
  margin-right: 130px;
  white-space: nowrap;
}
.product .product-information .libelle {
  font-size: 18px;
  padding-bottom: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product .product-information .price {
  color: #67A537;
  font-size: 20px;
}
.product .action,
.plat-page .action {
  padding: 17px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.product .action button,
.plat-page .action button {
  color: #67A537;
  background: #E7E7E7;
  padding: 12px;
  font-size: 16px;
  min-width: 110px;
  margin: 0;
}
.plat-page .scrollable-content .action {
  display: inline-block;
  float: right;
  margin-right: 20px;
  position: relative;
  padding: 0;
}
.compose-formule .formule-details {
  height: 130px;
  box-sizing: border-box;
  background-size: cover;
  background-position: 50%;
}
@media (min-width: 995px) {
  .restaurant.main.cac {
    width: 66.66666667%;
    position: absolute;
    left: 50%;
    top: 295px;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }
  .header.active.restaurant {
    z-index: 0;
  }
  .restaurant-slide {
    z-index: 10000;
  }
  .product.clear {
    z-index: 3000;
  }
  .cac #fixed-container {
    background: white;
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    z-index: 200;
  }
  .categories-containerTopFix {
    top: -100px;
    padding-left: 160px;
  }
  .product {
    width: calc(50% - 36px);
    float: left;
  }
}
@media (min-width: 995px) and (max-width: 1400px) {
  menu > ul li {
    font-size: 11px;
  }
}
@media (min-width: 995px) and (max-width: 1400px) {
  .header {
    height: 295px;
  }
}
