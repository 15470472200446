﻿@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-bold-webfont.eot');
    src: url('./fonts/josefinsans-bold-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-bold-webfont.woff2') format('woff2'), url('./fonts/josefinsans-bold-webfont.woff') format('woff'), url('./fonts/josefinsans-bold-webfont.ttf') format('truetype'), url('./fonts/josefinsans-bold-webfont.svg#josefin_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-bolditalic-webfont.eot');
    src: url('./fonts/josefinsans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-bolditalic-webfont.woff2') format('woff2'), url('./fonts/josefinsans-bolditalic-webfont.woff') format('woff'), url('./fonts/josefinsans-bolditalic-webfont.ttf') format('truetype'), url('./fonts/josefinsans-bolditalic-webfont.svg#josefin_sansbold_italic') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-italic-webfont.eot');
    src: url('./fonts/josefinsans-italic-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-italic-webfont.woff2') format('woff2'), url('./fonts/josefinsans-italic-webfont.woff') format('woff'), url('./fonts/josefinsans-italic-webfont.ttf') format('truetype'), url('./fonts/josefinsans-italic-webfont.svg#josefin_sansregular') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-light-webfont.eot');
    src: url('./fonts/josefinsans-light-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-light-webfont.woff2') format('woff2'), url('./fonts/josefinsans-light-webfont.woff') format('woff'), url('./fonts/josefinsans-light-webfont.ttf') format('truetype'), url('./fonts/josefinsans-light-webfont.svg#josefin_sanslight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-lightitalic-webfont.eot');
    src: url('./fonts/josefinsans-lightitalic-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-lightitalic-webfont.woff2') format('woff2'), url('./fonts/josefinsans-lightitalic-webfont.woff') format('woff'), url('./fonts/josefinsans-lightitalic-webfont.ttf') format('truetype'), url('./fonts/josefinsans-lightitalic-webfont.svg#josefin_sanslight_italic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-regular-webfont.eot');
    src: url('./fonts/josefinsans-regular-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-regular-webfont.woff2') format('woff2'), url('./fonts/josefinsans-regular-webfont.woff') format('woff'), url('./fonts/josefinsans-regular-webfont.ttf') format('truetype'), url('./fonts/josefinsans-regular-webfont.svg#josefin_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-semibold-webfont.eot');
    src: url('./fonts/josefinsans-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-semibold-webfont.woff2') format('woff2'), url('./fonts/josefinsans-semibold-webfont.woff') format('woff'), url('./fonts/josefinsans-semibold-webfont.ttf') format('truetype'), url('./fonts/josefinsans-semibold-webfont.svg#josefin_sanssemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-semibolditalic-webfont.eot');
    src: url('./fonts/josefinsans-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-semibolditalic-webfont.woff2') format('woff2'), url('./fonts/josefinsans-semibolditalic-webfont.woff') format('woff'), url('./fonts/josefinsans-semibolditalic-webfont.ttf') format('truetype'), url('./fonts/josefinsans-semibolditalic-webfont.svg#josefin_sanssemibold') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-thin-webfont.eot');
    src: url('./fonts/josefinsans-thin-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-thin-webfont.woff2') format('woff2'), url('./fonts/josefinsans-thin-webfont.woff') format('woff'), url('./fonts/josefinsans-thin-webfont.ttf') format('truetype'), url('./fonts/josefinsans-thin-webfont.svg#josefin_sansthin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'josefin';
    src: url('./fonts/josefinsans-thinitalic-webfont.eot');
    src: url('./fonts/josefinsans-thinitalic-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/josefinsans-thinitalic-webfont.woff2') format('woff2'), url('./fonts/josefinsans-thinitalic-webfont.woff') format('woff'), url('./fonts/josefinsans-thinitalic-webfont.ttf') format('truetype'), url('./fonts/josefinsans-thinitalic-webfont.svg#josefin_sansthin') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'pluto';
    src: url('./fonts/pluto-light.eot');
    src: url('./fonts/pluto-light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/pluto-light.woff2') format('woff2'),
        url('./fonts/pluto-light.woff') format('woff'),
        url('./fonts/pluto-light.ttf') format('truetype'),
        url('./fonts/pluto-light.svg#pluto-light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'pluto';
    src: url('./fonts/pluto-bold.eot');
    src: url('./fonts/pluto-bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/pluto-bold.woff2') format('woff2'),
        url('./fonts/pluto-bold.woff') format('woff'),
        url('./fonts/pluto-bold.ttf') format('truetype'),
        url('./fonts/pluto-bold.svg#pluto-bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'dotmatrix';
    src: url('./fonts/DotMatrixRegular.eot');
    src: url('./fonts/DotMatrixRegular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/DotMatrixRegular.woff2') format('woff2'),
        url('./fonts/DotMatrixRegular.woff') format('woff'),
        url('./fonts/DotMatrixRegular.ttf') format('truetype'),
        url('./fonts/DotMatrixRegular.svg#DotMatrixRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avantgarde-book';
    src: url('./fonts/AvantGarde-Book.eot');
    src: url('./fonts/AvantGarde-Book.eot?#iefix') format('embedded-opentype'),
        url('./fonts/AvantGarde-Book.woff2') format('woff2'),
        url('./fonts/AvantGarde-Book.woff') format('woff'),
        url('./fonts/AvantGarde-Book.ttf') format('truetype'),
        url('./fonts/AvantGarde-Book.svg#AvantGarde-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}


