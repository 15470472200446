.tc-commandes-page {
  background: #E7E7E7;
}
@media (min-width: 995px) {
  .tc-commandes-page {
    min-height: calc(100vh -  295px);
  }
}
@media (min-width: 995px) {
  .tc-commandes-page.list-page {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.tc-commandes-page .scrollable-content {
  height: calc(100vh - 70px);
}
.tc-commandes-page .innovorder-container {
  display: block;
  border: 0;
  width: 100%;
}
.tc-commandes-list {
  background: #fff;
}
.tc-commandes-list .commandes-header {
  border-bottom: 1px solid #E7E7E7;
  color: #949494;
  display: block;
  font-weight: bold;
  padding: 10px;
  text-transform: uppercase;
}
.tc-commandes-list .commande-vide {
  text-align: center;
}
.tc-commandes-list .commandes-wrapper {
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .tc-commandes-list .commandes-wrapper {
    box-sizing: border-box;
    height: calc(100vh -  70px);
  }
}
.tc-commandes-list .commandes-table {
  width: 100%;
  max-height: 100%;
  flex: 1;
  overflow: auto;
}
.tc-commandes-list .commandes-table .commande-line {
  cursor: pointer;
  padding: 8px 10px;
  border-bottom: 1px solid #E7E7E7;
  position: relative;
  display: block;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}
.tc-commandes-list .commandes-table .commande-date {
  margin: 0 8px 0 0;
}
.tc-commandes-list .commandes-table .commande-restaurant {
  color: #949494;
  flex: 1;
  word-break: break-word;
}
.tc-commandes-list .commandes-table .commande-prix {
  color: #67A537;
  font-weight: bold;
  margin: 0 0 0 5px;
  text-align: right;
  width: 60px;
}
.tc-commandes-list .commandes-table .chevroon-container {
  margin: 5px 0 0 5px;
}
@media (min-width: 995px) {
  .tc-commandes-list {
    background: #E7E7E7;
    padding: 50px 0;
    height: calc(100vh - 365px);
  }
  .tc-commandes-list .commandes-header {
    padding: 15px 20px;
  }
  .tc-commandes-list .commandes-wrapper {
    background: #fff;
    margin: 0 auto;
    width: 970px;
    height: 100%;
  }
  .tc-commandes-list .commandes-table {
    height: 100%;
  }
  .tc-commandes-list .commandes-table .commande-line {
    padding: 15px 20px;
  }
  .tc-commandes-list .commandes-table .commande-date {
    margin: 0 20px 0 0;
  }
  .tc-commandes-list .commandes-table .commande-prix {
    margin: 0 0 0 10px;
  }
  .tc-commandes-list .commandes-table .chevroon-container {
    margin: 5px 0 0 10px;
  }
}
