.fluidite-picto {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  width: 25px;
  height: 25px;
  margin-right: 8px;
}
.fluidite-picto.noFluiditeNoClickAndCollect {
  border-radius: 50%;
  box-sizing: border-box;
  padding: 5px;
}
.fluidite-picto.noFluiditeNoClickAndCollect.openSpecial {
  background: #67A537;
}
.fluidite-picto.noFluiditeNoClickAndCollect.closeSpecial {
  background: #1A1A1A;
}
.fluidite-picto.click-and-collect {
  border-radius: 50%;
  background: white;
  box-sizing: border-box;
  padding: 5px;
}
.fluidite-picto.click-and-collect.green {
  background: #67A537;
}
.fluidite-picto.click-and-collect.open {
  background: #67A537;
}
.fluidite-picto.click-and-collect.close {
  background: #949494;
}
.fluidite-picto.click-and-collect img {
  width: auto;
  max-height: 100%;
  margin-top: -2px;
}
.fluidite-picto.picto-loading {
  display: block !important;
  border-radius: 50%;
  background-color: gray;
}
.fluidite-picto.small {
  width: 25px;
  height: 25px;
}
.fluidite-picto.fluidite-occupation-eleve {
  background-image: url('assets/pictos/fluidite_occupation_eleve.svg');
}
.fluidite-picto.fluidite-occupation-moyen {
  background-image: url('assets/pictos/fluidite_occupation_moyen.svg');
}
.fluidite-picto.fluidite-occupation-faible {
  background-image: url('assets/pictos/fluidite_occupation_faible.svg');
}
.fluidite-picto.fluidite-tendance-augmente {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.fluidite-picto.fluidite-tendance-diminue {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.fluidite-picto.fluidite-tendance-stable {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.fluidite-picto.fluidite-occupation-only {
  background: url('assets/pictos/fluidite-tendance.svg') no-repeat;
  width: 100%;
  height: 100%;
  background-size: 75%;
  border-radius: 50%;
  background-position: 50%;
  min-width: 19px;
  min-height: 27px;
}
.fluidite-picto.fluidite-occupation-only.fluidite-tendance-augmente {
  -webkit-transform: none;
          transform: none;
}
.fluidite-picto.fluidite-occupation-only.fluidite-tendance-stable {
  -webkit-transform: none;
          transform: none;
}
.fluidite-picto.fluidite-occupation-only.fluidite-occupation-na {
  background-color: #949494;
}
.fluidite-picto.fluidite-occupation-only.fluidite-occupation-faible {
  background-color: #69A336;
  -webkit-transform: none;
          transform: none;
}
.fluidite-picto.fluidite-occupation-only.fluidite-occupation-moyen {
  background-color: #FD9C01;
  -webkit-transform: none;
          transform: none;
}
.fluidite-picto.fluidite-occupation-only.fluidite-occupation-eleve {
  background-color: #D30000;
  -webkit-transform: none;
          transform: none;
}
