﻿@import (reference) '_variables.less';

.email-page {
    @alertHeight: 30px;
    .md({
        min-height: calc(~"100% - 295px");
        height: auto;
        position: absolute;
        width: 100%;
        background: @desktopGrayBackground;

        .scrollable-content{
            width: 50%;
            background: white;
            margin: auto;
            height: auto !important;
            margin-top: 50px;
            box-shadow: 0 0 5px rgba(0,0,0,.5);
        }
    });

    [role=alert], [role=success] {
        height: @alertHeight;
        padding-top: 5px;
        padding-bottom: 5px;
        box-sizing: border-box;
    }

    .scrollable-content{
        height: calc(~'100vh - 70px');
    }
}
