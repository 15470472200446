vote-clos {
  display: flex;
  display: -webkit-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: pluto;
}
@media (min-width: 995px) {
  vote-clos {
    display: block;
    position: relative;
  }
}
vote-clos .vote-clos-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  font-size: 24px;
}
@media (min-width: 995px) {
  vote-clos .vote-clos-container {
    text-align: center;
    background-color: #FDFDFD;
  }
}
vote-clos .vote-clos-container .title {
  background-color: #67A537;
  padding: 0px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
}
@media (min-width: 995px) {
  vote-clos .vote-clos-container .title {
    background: transparent!important;
    font-weight: 600;
    color: #1A1A1A !important;
    font-size: 34px;
  }
}
vote-clos .vote-clos-container .informations {
  color: #E7E7E7;
  font-size: 18px;
}
@media (min-width: 995px) {
  vote-clos .vote-clos-container .informations {
    font-weight: 600;
    color: #ACACAC;
    font-size: 20px;
  }
}
vote-clos .vote-clos-container .informations .day-or-hours {
  text-transform: lowercase;
}
@media (max-width: 767px) {
  vote-clos .vote-clos-container .white {
    color: white;
  }
}
vote-clos .vote-clos-container .gray {
  color: #ACACAC;
}
