﻿@import (reference) "_variables.less";

.header {
    h1 {
        position: fixed;
        height: @headerXsHeight;
        top: 0;
        line-height: @headerXsHeight;

        > .pass {
            //font-weight: normal;
        }
    }

    .md( {
        position: relative;
        height: @headerMdHeight;
        background: url('./assets/bg-header.jpg') no-repeat center / cover;

        h1 {
            display: none;
        }
    }

    );
}

header {
    z-index: 1001;
    text-align: center;
    color: white;
    transition: top ease-in 0.5s;

    h1 {
        width: 100%;
        margin: 0;
        box-sizing: border-box;

        &.easypass-title {
            font-size: 42px;
            font-weight: normal;
            padding: 10px 70px;

            div.logo {
                height: 55px;
                background: url(assets/logo.png);
                background-size: 137px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &:not(.easypass-title) {
            padding: 0;
            line-height: @headerXsHeight;
            font-size: 30px;
            font-weight: normal;
            text-align: left;
            overflow-y: auto;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: visible;
            text-align: center;

            &.with-back {
                /*padding: 0 20px 0 130px;
                overflow: hidden;
                text-align: left;*/
            }
        }
    }
}

.easy {
    color: @lightGray;
}

.pass {
    color: @green;
    margin-left: -9px;
}

@lineHeigth: 3px;
@lineSpace: 5px;

.hamburger {
    z-index: 2000;
    background-color: transparent !important;
    display: block;
    position: relative;
    width: @hamburgerSize;
    height: @hamburgerSize;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
    position: absolute;
    margin: 0;
    top: (@headerXsHeight / 2) - (@hamburgerSize / 2);
    left: 14px;

    .md( {
        display: none;
    }

    );

    &:focus {
        outline: none;
    }

    &.active {
        span:nth-child(1) {
            margin-top: @lineSpace + @lineHeigth;
            transform: rotate(180deg);
        }

        span:nth-child(3) {
            margin-top: -@lineSpace - @lineHeigth;
            transform: rotate(-180deg);
        }
    }

    span {
        display: block;
        position: absolute;
        top: @hamburgerSize /2 - @lineHeigth;
        left: 4px;
        right: 4px;
        height: @lineHeigth;
        background: white;
        transition: transform .6s, margin .3s, opacity .6s;

        &:nth-child(1) {
            margin-top: -@lineSpace - @lineHeigth;
        }

        &:nth-child(3) {
            margin-top: @lineSpace + @lineHeigth;
        }
    }
}

menu.menu-open + .hamburger {
    span {
        &:nth-child(1) {
            margin-top: @lineSpace + @lineHeigth;
        }

        &:nth-child(3) {
            margin-top: -@lineSpace - @lineHeigth;
        }
    }
}

.back-button {
    @backButtonSize: 18px;
    padding: 26px;
    background-color: transparent !important;
    width: auto;
    color: white;
    z-index: 1002;
    position: absolute;
    top: 0;
    font-size: @backButtonSize;
    text-transform: uppercase;
    margin: 0;

    &:before {
        content: '';
        display: inline-block;
        width: @arrowSize;
        height: @arrowSize;
        border-right: 1px solid white;
        border-top: 1px solid white;
        transform: rotate(-135deg);
    }
}

.notifications {
    @badgeSize: 50px;
    z-index: 2000;
    position: absolute;
    top: (@headerXsHeight / 2) - (@badgeSize / 2);
    background: @darkGray url('assets/bell.png');
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center;
    width: @badgeSize;
    height: @badgeSize;
    border-radius: 50%;
    right: 12px;

    .md( {
        display: none;
    }

    );

    .number {
        border-radius: 50%;
        background-color: @green;
        min-width: 20px;
        font-size: 15px;
        margin-top: -25px;
        color: black;
        font-weight: 600;
        position: absolute;
        line-height: 1.2;
        margin-left: 24px;
        text-align: center;
    }

    &.panier {
        background: @darkGray url('assets/pictos/panier.svg');
        background-repeat: no-repeat;
        background-size: 25px;
        background-position: center;
    }
}

#fixed-container {
    background: white;
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: 200;
}
