@import (reference) '_variables.less';

@inputHeight: 45px;

label {
    color: @gray;
    font-size: 15px;
    margin-bottom: 5px;
    text-transform: uppercase;
    display: inline-block;

    &.normalcase {
        text-transform: none;
    }
}

input {
    -webkit-appearance: none;
    border-radius: 0;
}

[role=alert] {
    color: @errorColor;
    text-align: center;
    font-style: italic;
    opacity: 0;
    transition: opacity linear 0.2s;

    &.left {
        text-align: left;
    }

    &.error {
        opacity: 1;

        &.error-bottom {
            padding-bottom: 20px;
        }
    }

    &.success {
        opacity: 1;
        color: @green;

        .tastytime & {
            color: @beige;
        }
        .roomservicebyelior & {
            color: @eliorRed;
        }
    }

    &.hidden {
        margin-top: -13px;

        span {
            display: block;
        }

        &.second {
            transform: translateY(-10px);
        }
    }
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel] {
    width: 100%;
    height: @inputHeight;
    margin-bottom: 15px;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 18px;
    font-family: @font;
    color: black;
    border: 1px solid @lightBgGray;

    &::placeholder {
        color: @grayControl;
        font-style: italic;
    }

    &.ng-invalid:not(.ng-pristine),
    &[class*="ng-invalid-"]:not(.ng-pristine) {
        /*border-color: @errorColor;*/
    }

    &:focus {
        outline: none;
    }
}

button,
input[type=button],
a.button,
input[type=submit] {
    cursor: pointer;
    font-family: @font;
    font-size: 20px;
    line-height: 20px;
    box-sizing: border-box;
    padding: 10px;
    border: none;
    color: white;
    background-color: @green;
    width: 100%;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;

    &:hover:not([disabled]),
    &:hover:not(.disabled) {
        background-color: @lightGreen;
    }

    .tastytime & {
        background-color: @beige;

        &:hover:not([disabled]),
        &:hover:not(.disabled) {
            background-color: @lightBeige;
        }
    }
    .roomservicebyelior & {
        background-color: @eliorRed;

        &:hover:not([disabled]),
        &:hover:not(.disabled) {
            background-color: @eliorLightRed;
        }
    }

    &.secondary {
        background-color: black;

        .roomservicebyelior & {
            background-color: @eliorGray;
        }

        &:hover {
            background-color: lighten(#000, 20%);

            .roomservicebyelior & {
                background-color: @eliorLightGray;
            }
        }
    }

    &.tertiary {
        background-color: white;
        color: @green;
    }

    &.inherit {
        background-color: inherit;
        padding-left: 0px;
        text-align: left;

        span {
            color: black;
            font-weight: bold;
        }

        &:hover {
            background-color: inherit;
        }
    }

    &.touchable:active {
        transform: scale(0.98);
    }

    &:focus {
        outline: none;
    }

    &.filiaire {
        background-color: transparent;
        color: @green;
        border: 2px solid @green;

        &:hover {
            background-color: transparent;
        }

        .tastytime & {
            color: @beige;
            border-color: @beige;
        }
        .roomservicebyelior & {
            color: @eliorRed;
            border-color: @eliorRed;
        }
    }


}

.loading-button {
    position: relative;

    .loader {
        display: none;
        position: absolute;
        top: 3px;
        left: 5px;
        height: 34px;
        animation: rotate 0.7s linear infinite;
    }

    [type=submit].loading+.loader,
    [type=button].loading+.loader {
        display: block;
    }
}

@keyframes rotate {
    100% {
        transform:rotate(360deg) ;
    }
}

.lines {
    display: flex;
    align-items: center;
    padding: 20px 0;
    font-size: 20px;

    .md( {
        padding: 0 0 30px;
        height: 40px;
    }

    );

    &:before,
    &:after {
        content: '';
        display: block;
        width: 100%;
        border-top: 2px solid #000;
    }

    span {
        margin: 0 10px;
        white-space: nowrap;
    }
}

.lines-pro {
    display: flex;
    align-items: center;
    padding: 20px 0;
    font-size: 20px;

    .md( {
        padding: 0 0 5px;
        height: 40px;
    }

    );

    &:before,
    &:after {
        content: '';
        display: block;
        width: 100%;
        border-top: 2px solid white;

        .roomservicebyelior & {
            border-top-color: @eliorGray;
        }
    }

    span {
        margin: 0 10px;
        white-space: nowrap;
        color: white;

        .roomservicebyelior & {
            color: @eliorGray;
        }
    }
}

progress-bar {
    width: 100%;
    height: 5px;
    background: @grayControl;
    display: block;

    div {
        height: 100%;
        background: @green;
    }
}

.backfacable-button {
    position: relative;
    transform-origin: 50% 50% -22px;
    transform-style: preserve-3d;
    transition: transform 0.3s;

    &.active {
        opacity: 1;
        transform: rotateX(90deg) translateY(50%);
    }

    &.refused {
        opacity: 1;
        transform: rotateX(-90deg) translateY(-50%);
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 12px 0;
        background: @green;
        color: #fff;
        content: attr(data-hover);
        text-transform: none;
        backface-visibility: hidden;
        transition: background 0.3s;
        transform: rotateX(-90deg) translateY(100%);
        transform-origin: bottom;

        .tastytime & {
            background-color: @beige;
        }
        .roomservicebyelior & {
            background-color: @eliorRed;
        }
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 12px 0;
        background: @errorColor;
        color: #fff;
        content: attr(data-product-full);
        text-transform: none;
        backface-visibility: hidden;
        transition: background 0.3s;
        transform: rotateX(90deg) translateY(-100%);
        transform-origin: top;
    }
}