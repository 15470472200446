﻿@import (reference) '_variables.less';

.legal-page {
    height: calc(~'100vh - 90px');
    width: 100%;

    .md( {
        min-height: 100%;
        height: 100% !important;
        position: absolute;
        width: ~'calc(100% - 20px)';
        background: @desktopGrayBackground;
    }
    );

    p {
        .underline {
            text-decoration: underline;
        }

        .important {
            color: red;
            text-transform: uppercase;
        }

        .header {
            display: block;
            margin-bottom: 10px;
            font-weight: bold;
        }
    }

    >div {
        padding: 0px 20px 10px 20px;
        box-sizing: border-box;
        background: white;
        width: 100%;
        height: 100%;

        .md( {
            margin: 50px auto;
            width: 970px;
            box-shadow: 0 0 5px rgba(0, 0, 0, .5);
        }
        );
    }
}