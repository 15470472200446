﻿@import (reference) '_variables.less';
@import (reference) '_mixins.less';

@menuButtonBannerWidth: 60px;
@menuButtonRight: 50px;

survey {
    position: absolute;
    width: 100%;
    display: block;
    height: calc(~"100vh -" 70px);
    overflow-x: hidden;

    .md( {
        overflow-y: auto;
        top: 320px;
        height: calc(~"100vh -" 320px);
    }

    );
}

.enquete-header {
    width: 100%;
    height: 46vh;
    background: lightgray;
    background-size: cover;
    background-position: top right;

    &.survey {
        height: 20vh;
        transition: height 0.3s ease-in-out;

        .md({
            height: 295px;
        });

        .min(1400px, {
            height: @headerMdHeight;
        });
    }
}

.restaurant-slide.enquete {
    margin-top: 26px;

    .md({
        margin-top: 0;
    });

    .slide-restaurant-arrow {
        top: 0px;
        padding: 20px;
    }

    .restaurant-informations {
        /*margin-left: 0;
        padding-top: 0;
        text-align: center;*/
        margin: 15px 40px 0 40px;
        box-sizing: border-box;

        .md( {
            //margin-top: 0;
            position: absolute;
            margin: 0;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }

        );
    }

    .libelle-container {
        margin: auto;
        padding-right: 0;

        .libelle {
            width: 100%;
            font-size: 22px;
            margin-left: 12.5px;
            margin-right: 12.5px;
            margin-bottom: 5px;
            margin-top: -20px;
            word-wrap: break-word;

            .md( {
                font-size: 24px;
            }

            );
        }
    }

    .info {
        font-style: normal;
        font-size: 14px;

        .md( {
            font-style: italic;
            font-size: 24px;
        }

        );
    }
}

.link-to-menu-header {
    height: 30px;
    position: absolute;
    margin-top: -30px;
    width: 100%;

    .md( {
        display: none !important;
        position: inherit;
        margin-top: 0;

        .banner-1, .banner-2, .ellipse {
            display: none;
        }

        .link-to-menu-button {
            .button {
                position: inherit !important;
                background-color: transparent !important;
                margin-top: 30px !important;

                img {
                    position: inherit;
                }
            }
        }
    }

    );

    & > * {
        float: right;
        height: 100%;
    }

    .banner-1, .banner-2 {
        background: white;
    }

    .banner-1 {
        width: @menuButtonRight;
    }

    .banner-2 {
        width: calc(~"100% -"@menuButtonBannerWidth + @menuButtonRight);
    }

    .link-to-menu-button {
        position: relative;
        z-index: 999;

        .button {
            &:hover {
                cursor: pointer;
            }

            &.link-to-menu-container {
                display: flex;
                align-items: center;

                a {
                    width: 100%;
                    height: 100%;
                }
            }

            backface-visibility: hidden;
            transition: .6s transform;
            position: absolute;
            width: 52px;
            height: 52px;
            border-radius: 100%;
            top: -30px;
            background: white;
            margin: 4px;

            img {
                position: absolute;
                width: 50px;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }

        & > img {
            width: @menuButtonBannerWidth;
        }
    }
}

.enquete-page {
    overflow-y: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-overflow-scrolling: touch;

    .md( {
        background-image: url('./assets/bg-login-big3.jpg');
        overflow-y: initial;

        .picto-animation {
            display: none;
        }

        .restaurant-slide {
            &.enquete {
                margin-top: 0;
                position: absolute;
                //top: 85px;
                top: 30px;
                width: 100%;
            }
        }
    }

    );

    @padding: 20px;
    @margin: 30px;

    .survey-content {
        .md( {
        }

        );
    }

    &.scrollable-content {
        .md( {
            overflow: hidden;
        }

        );
    }

    .progress {
        top: 50px + 60px;
        left: @margin;
        right: @margin;
        height: 6px;
        position: absolute;
        background-color: #d1d1d1;
    }

    .question {
        background-color: #f4f4f4;
        padding-top: 10px;
        margin-bottom: 20px;
        top: 20px;
        left: @margin;
        right: @margin;
        position: absolute;
        transition: transform .6s;
        display: none;

        .md( {
            padding: 30px;
            //position: relative;
            top: 0;
            left: 0;
            right: auto;
            margin-top: 25px;
            margin-bottom: 25px;
            width: 600px;

            //max-height: 350px;
            overflow: auto;
        }

        );

        &::after {
            clear: both;
            content: '';
            display: block;
        }

        &.current {
            transform: translateX(0);
            display: block;

            .md( {
                display: inline-block;
                left: 50%;
                margin-top: 25px;
                margin-bottom: 25px;
                margin-left: auto;
                margin-right: auto;
                transform: translateX(-50%);
            }

            );
        }

        &.previous {
            transform: translateX(-150%);
            display: block;

            .md( {
                display: inline-block;
                margin-top: 100px;
                transform: translateX(-125vw);
            }

            );
        }

        &.next {
            transform: translateX(150%);
            display: block;

            .md( {
                display: inline-block;
                margin-top: 100px;
                transform: translateX(125vw);
            }

            );
        }

        &.futur {
            transform: translateX(150%);
            display: block;

            .md( {
                display: inline-block;
                transform: translateX(125vw);
            }

            );
        }

        .title {
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            position: relative;
            text-align: center;
            width: 100%;

            .chevroon {
                margin: 0 20px;
                position: absolute;
                right: 0;
                top: 0;

                &.left {
                    left: 0;
                }
            }
        }

        .answer-content {
            .md( {
                padding: 5px 10px 15px 10px;
            }

            );
        }

        .answer-content2 {
            .md( {
                padding: 5px 10px 15px 10px;
                text-align: center;
            }

            );
        }

        .libelle {
            font-size: 22px;
            margin-left: 12.5px;
            margin-right: 12.5px;
            margin-bottom: 5px;
            word-wrap: break-word;
        }

        stars {
            margin: 0 9px 0 24px;
            padding-top: 5px;
            padding-bottom: 5px;

            + span {
                font-size: 16px;
                margin-right: 25px;
                margin-bottom: 25px;
            }
        }

        .chevroon {
            &.left {
            }
        }

        .textRating {
            display: inline-block;
            margin-left: 24px;
            padding-bottom: 10px;
            width: 100%;
        }

        progress-bar {
            margin: 9px 0;
        }

        textarea {
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 15px;
        }

        .response {
            margin: 25px;
            margin-top: 0px;
        }

        .touchable {
            .md( {
                width: auto;
                padding: 6.5px 20px;
            }

            );
        }

        .touchable2 {
            .md( {
                margin: auto;
            }

            );
        }
    }

    .margin-top {
        height: 30px;

        .md( {
            height: 60px;
        }

        );
    }
}
