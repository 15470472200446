﻿@import (reference) '_variables.less';

.aide-page{
    &.scrollable-content{
        height: calc(~'100vh - 100px');
        .md({
            min-height: calc(~"100% - 325px");
            height: auto;
        })
    }

    padding: 20px;
    padding-top: 10px;
    background: url(assets/bg-aide.jpg) no-repeat 0px;
    background-size: cover;
    .aide-picto{
        padding-top: 40px;
        padding-bottom: 10px;
        text-align: center;
        img{
            width: 126px;
            border-bottom: 5px solid @green;
            padding-bottom: 20px
        }
    }
    h4{
        text-transform: uppercase;
        text-align: center;
        font-weight: normal;
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 34px;
    }
    p{
        text-align: center;
    }

    body[app="timechefpro"] & {
        //surcharge TimechefPro pour supprimer le fond vert
        background-image: none;

        #p2{
            display: none; //masquer le 2eme paragraphe qui mentionne l'historique de tickets
        }
    }
}