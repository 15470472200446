header dt-basket-icon div.basketIconDisplayer {
  position: absolute;
  right: 15px;
  top: 15px;
}
dt-basket-icon div.basketIconDisplayer {
  background: url(assets/dinnertakeaway/PanierDisabled.png) no-repeat 50%;
  background-color: #E7E7E7;
  background-size: contain;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  cursor: pointer;
}
dt-basket-icon div.basketIconDisplayer.hasItems {
  color: black;
  background: url(assets/dinnertakeaway/Panier.png) no-repeat 50%;
  background-size: contain;
}
dt-basket-icon div.basketIconDisplayer .badge {
  color: white;
  font-weight: bold;
  background-color: #67A537;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: block;
  -webkit-transform: translate(-8px, -4px);
          transform: translate(-8px, -4px);
}
