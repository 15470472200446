.presence-page {
  position: absolute;
}
@media (min-width: 995px) {
  .presence-page {
    background: lightgray;
    width: 100%;
    height: 100%;
  }
}
.presence-page .scrollable-content {
  height: calc(100vh - 70px);
}
.presence-page .presence-content {
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 995px) {
  .presence-page .presence-content {
    margin: 50px auto;
    width: 970px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 50px;
    overflow: hidden;
  }
}
.presence-page .presence-content .planning-calendar {
  margin-top: 10px;
}
.presence-page .presence-content .planning-calendar .planning-month-selector {
  text-align: center;
  font-weight: 600;
}
.presence-page .presence-content .planning-calendar .planning-month-selector .prev-month {
  float: left;
  margin-left: 10px;
}
.presence-page .presence-content .planning-calendar .planning-month-selector .prev-month.hidden {
  visibility: hidden;
}
.presence-page .presence-content .planning-calendar .planning-month-selector .next-month {
  float: right;
  margin-right: 10px;
}
.presence-page .presence-content .planning-calendar .planning-days {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;
  max-height: 100%;
  margin: 10px -5px -5px 0px;
}
.presence-page .presence-content .planning-calendar .planning-days .planning-weekday {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: calc(14.28571429%);
  padding: 0px 5px 5px 0px;
  font-size: 0.7em;
  color: #7EC14E;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
}
.presence-page .presence-content .planning-calendar .planning-days .planning-day {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: calc(14.28571429%);
  padding: 0px 5px 5px 0px;
  cursor: pointer;
}
.presence-page .presence-content .planning-calendar .planning-days .planning-day .planning-day-content {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center space-around;
  align-items: center;
  padding: 5px;
  height: 60px;
  border-radius: 8px;
  color: #525257;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  font-size: 1em;
}
.presence-page .presence-content .planning-calendar .planning-days .planning-day .planning-day-content.presence-absent {
  background-color: #6f6f6f;
  color: white;
  font-weight: bold;
}
.presence-page .presence-content .planning-calendar .planning-days .planning-day .planning-day-content.presence-on-site {
  background-color: rgba(131, 191, 88, 0.55);
  color: white;
  font-weight: bold;
}
.presence-page .presence-content .planning-calendar .planning-days .planning-day .planning-day-content.presence-lunch {
  background-color: #7EC14E;
  color: white;
  font-weight: bold;
}
.presence-page .presence-content .planning-calendar .planning-days .planning-day .planning-day-content.presence-disbaled {
  background-color: white;
  color: #525257;
  cursor: default;
}
.presence-page .presence-content .planning-calendar .planning-days .planning-day .planning-day-content.presence-other-month {
  background-color: #fafafa;
  color: #525257;
}
.presence-page .presence-content .legend {
  padding: 0 5px 5px 0;
  flex: 1 1 100%;
  box-sizing: border-box;
  margin-top: 10px;
}
.presence-page .presence-content .legend .legend-content {
  flex-direction: row;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  align-items: center;
}
.presence-page .presence-content .legend .legend-content .legend-case {
  padding: 3px;
  margin-right: 5px;
  height: 5px;
  width: 5px;
  border-radius: 8px;
  color: #525257;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  font-size: 1em;
}
.presence-page .presence-content .legend .legend-content .legend-absent {
  background-color: #6f6f6f;
}
.presence-page .presence-content .legend .legend-content .legend-on-site {
  background-color: rgba(131, 191, 88, 0.55);
}
.presence-page .presence-content .legend .legend-content .legend-lunch {
  background-color: #7EC14E;
}
.presence-page .presence-content .quota-warning {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 14px;
}
.presence-page input[type="radio"] {
  appearance: radio;
  -webkit-appearance: radio;
  -moz-appearance: radio;
}
.presence-page .button-container {
  padding-top: 10px;
}
.presence-page .form-presence-title {
  padding: 8px;
  font-size: 20px;
  font-weight: 700;
  height: 25px;
}
.presence-page .calendar-presence-title {
  padding: 8px;
  font-size: 20px;
  font-weight: 700;
  height: 25px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.presence-page .calendar-presence-lieu-title {
  padding: 8px;
  font-size: 16px;
  font-weight: 700;
  height: 25px;
  margin-bottom: 8px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.presence-page .calendar-presence-event-title {
  padding: 8px;
  font-size: 16px;
  font-weight: 700;
  height: 25px;
  margin-bottom: 8px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.presence-page .form-presence {
  padding: 5px;
  text-align: left;
}
.presence-page .button-new-presence {
  font-size: 14px;
  line-height: 10px;
  width: auto;
}
.presence-page .pop-in-container .pop-in-buttons {
  flex-direction: column-reverse !important;
}
.presence-page .icon_collapse {
  font-size: 16px;
}
.presence-page .presence-message-title {
  padding: 8px;
  font-size: 20px;
  font-weight: 700;
  height: 25px;
}
.presence-page .presence-message-content-warning {
  padding: 8px;
  font-size: 16px;
  font-weight: 700;
  color: orangered;
}
.presence-page .presence-message-content-error {
  padding: 8px;
  font-size: 16px;
  font-weight: 700;
  color: red;
}
.presence-page .presence-message-content-success {
  padding: 8px;
  font-size: 16px;
  font-weight: 700;
  color: green;
}
.time-container {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.time-container .select-hours {
  width: 170px;
  text-align: center;
  border-color: #67A537;
  height: 30px;
}
.input-date {
  flex-direction: row;
  display: flex;
  place-content: center space-around;
  align-items: center;
  vertical-align: center;
}
.input-date .input-date-trigger {
  color: #67A537;
  padding: 15px;
  margin-bottom: 15px;
}
.select-lieux {
  border-color: #67A537;
  height: 25px;
  text-align: center;
  width: 170px;
  height: 30px;
  margin-bottom: 8px;
}
.select-events {
  border-color: #67A537;
  height: 25px;
  text-align: center;
  width: 170px;
  height: 30px;
  margin-bottom: 8px;
}
.event-book {
  width: 24px;
  height: 24px;
}
.presence-reservation {
  justify-content: center;
  align-items: center;
}
.presence-reservation .title {
  justify-content: left;
  align-items: left;
}
