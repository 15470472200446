.plat-page {
  height: auto;
}
@media (min-width: 995px) {
  .plat-page {
    margin-top: -20px;
  }
}
.plat-page .scrollable-content {
  height: auto;
}
@media (min-width: 995px) {
  .plat-page .scrollable-content {
    height: 67vh;
  }
}
@media (min-width: 995px) {
  .plat-page {
    min-height: 100%;
    width: 100%;
    position: absolute;
    background: lightgray;
  }
  .plat-page .scrollable-content {
    height: 100%;
  }
}
@media (min-width: 995px) {
  .plat-page .scrollable-content {
    margin: 50px auto;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    width: 970px;
  }
}
.plat-page .scrollable-content .actionDetail {
  padding: 17px;
  /*position: absolute;
            bottom: 0;
            right: 0;*/
  float: right;
}
.plat-page .scrollable-content .actionDetail button {
  color: #67A537;
  background: #E7E7E7;
  padding: 12px;
  font-size: 16px;
  min-width: 110px;
  margin: 0;
}
@media (min-width: 995px) {
  .plat-page #restaurantImage {
    display: block !important;
  }
}
@media (min-width: 995px) {
  .plat-page #platImage {
    display: none !important;
  }
}
.plat-page .restaurantImage {
  background-size: cover;
  background-position: center;
  height: 33vh;
  width: 100%;
}
@media (min-width: 995px) {
  .plat-page .restaurantImage {
    height: 285px;
  }
}
.plat-page .plat-image {
  display: none;
}
@media (min-width: 995px) {
  .plat-page .plat-image {
    display: block;
    margin: 0 auto;
  }
}
.plat-page .informations {
  padding: 20px 20px 0px 20px;
  font-size: 24px;
  display: flex;
  flex-direction: row;
}
@media (min-width: 995px) {
  .plat-page .informations {
    margin-top: 0;
  }
}
.plat-page .informations .libelle {
  flex-grow: 1;
  margin-right: 10px;
}
.plat-page .informations .price {
  flex-shrink: 0;
  white-space: nowrap;
  color: #67A537;
}
.plat-page .plat-infos {
  margin: 10px 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
}
.plat-page .plat-infos .plat-infos-title {
  font-size: 20px;
  color: #67A537;
  font-weight: bold;
}
.plat-page .plat-infos .plat-infos-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5px;
}
.plat-page .plat-infos .plat-infos-container .plat-infos-description {
  flex-grow: 1;
  font-size: 14px;
}
@media (min-width: 995px) {
  .plat-page .plat-infos .plat-infos-container .plat-infos-description {
    font-size: 16px;
  }
}
.plat-page .plat-infos .plat-infos-container .plat-infos-pictos {
  padding: 5px;
  box-sizing: border-box;
}
.plat-page .plat-infos .plat-infos-link {
  flex-grow: 0;
  align-self: center;
  margin-left: 5px;
}
.plat-page .plat-infos.plat-infos-nutriscore .plat-infos-pictos {
  padding: 0px !important;
}
.plat-page .plat-infos.plat-infos-nutriscore .plat-infos-pictos .nutriscore-logo {
  height: 40px;
  width: 70px;
  vertical-align: middle;
}
.plat-page .plat-infos.plat-infos-nutrition .plat-infos-pictos {
  padding: 0px 15px 0px 5px;
}
.plat-page .plat-infos.plat-infos-nutrition .plat-infos-pictos .plat-infos-nutrition-picto {
  text-align: right;
}
.plat-page .plat-infos.plat-infos-nutrition .plat-infos-pictos .plat-infos-nutrition-picto ui-knob #text {
  font-weight: bold !important;
  -webkit-transform: translate(35px, 42px) !important;
          transform: translate(35px, 42px) !important;
}
.plat-page .plat-infos.plat-infos-nutrition .plat-infos-pictos .plat-infos-nutrition-picto-legend {
  text-align: center;
  font-size: 12px !important;
}
.plat-page .plat-infos.plat-infos-nutrition .plat-infos-nutrition-apport {
  flex: 50%;
  font-size: 14px;
  text-align: left;
}
@media (min-width: 995px) {
  .plat-page .plat-infos.plat-infos-nutrition .plat-infos-nutrition-apport {
    font-size: 16px;
  }
}
.plat-page .plat-infos.plat-infos-allergenes .plat-infos-container {
  display: flex;
  flex-direction: row;
}
.plat-page .plat-infos.plat-infos-allergenes .plat-infos-description ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
}
.plat-page .plat-infos.plat-infos-allergenes .plat-infos-description ul li {
  font-size: 14px;
}
@media (min-width: 995px) {
  .plat-page .plat-infos.plat-infos-allergenes .plat-infos-description ul li {
    font-size: 16px;
  }
}
.plat-page .plat-infos.plat-infos-allergenes .plat-infos-pictos {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  max-width: 250px;
  padding: 0px !important;
}
.plat-page .plat-infos.plat-infos-allergenes .plat-infos-pictos .pictogramme {
  box-sizing: border-box;
}
.plat-page .plat-infos.plat-infos-allergenes .plat-infos-pictos .pictogramme img {
  max-width: 100%;
}
.plat-page .plat-infos.plat-infos-allergenes .plat-infos-pictos .pictogramme + .pictogramme {
  margin-left: 5px;
}
stars {
  margin-bottom: 8px;
}
stars .score colored-svg {
  margin-right: 5px;
}
fieldset {
  margin: 24px 19px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
fieldset legend {
  text-transform: uppercase;
  font-size: 14px;
  color: #949494;
}
.like-container {
  margin-right: 20px;
  text-align: right;
  font-size: 18px;
}
.like-container .fa-heart.far {
  color: #949494;
}
.like-container .fa-heart.fa {
  color: #67A537;
}
.like-container span {
  padding-left: 4px;
  color: #949494;
}
.like-container .loader {
  -webkit-animation: rotate 0.7s linear infinite;
          animation: rotate 0.7s linear infinite;
  height: 18px;
  width: 18px;
}
