﻿@import (reference) '../_variables.less';

.dark-theme {
	background-color: @darkHeaderColor;

    .header {
        background-color: @darkHeaderColor;
        color: @darkHeaderFontColor;

        h1 {
            > .easy {
                color: @lightGray;
            }
        }
    }

    .back-button {
        color: @darkHeaderFontColor;
        text-shadow: 2px 1px 2px rgba(0,0,0,0.5);

        &:before {
            border-right: 1px solid @darkHeaderFontColor;
            border-top: 1px solid @darkHeaderFontColor;
        }
    }

	.back-button-qrcode {
        color: @lightHeaderFontColor;
        text-shadow: 2px 1px 2px rgba(255,255,255,0.5);

        &:before {
            border-right: 1px solid @lightHeaderFontColor;
            border-top: 1px solid @lightHeaderFontColor;
        }
	}

    .hamburger {
        span {
            background: @darkHeaderFontColor;    
            box-shadow: 0 0 10px rgba(0,0,0,0.5);
        }
    }

    menu {
        color: @darkMenuFontColor;
        /*background-color: fade(#0b0b0b,95);*/
        background-color:@darkMenuColor;

        > .account {
            background-color: @darkMenuColor;
            border-top-color: fade(@darkMenuFontColor,20);

            .qrcode {
                background: @darkMenuFontColor;

                span {
                    color: #999;
                }
            }

            .account-info {
                .name {
                    color: @darkMenuFontColor;
                }

                .recharger {
                    border: 1px solid @darkMenuFontColor;
                    color: @darkMenuFontColor;
                }
            }
        }

    }
}