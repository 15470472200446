﻿.custom-checkbox {
    border-radius: 2px;
    border-style: solid;
    border-width: 2px;
    border-color: #b9b9b9;
    box-sizing: border-box;
    background-color: #ffffff;
    width: 25px;
    height: 25px;
    position: relative;
    margin: 0;
    padding: 0;

    img {
        display: block;
        position: absolute;
        height: 25px;
        top: -5px;
    }
}