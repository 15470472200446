restaurant-tile {
  display: flex;
  height: 100%;
  width: 100%;
}
restaurant-tile .tile-container {
  display: flex;
  flex: 1;
  color: #fdfdfd;
  flex-direction: column;
  justify-content: flex-end;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.8), transparent);
  padding: 8px;
  align-items: flex-start;
  overflow: hidden;
}
restaurant-tile .tile-container .title {
  white-space: nowrap;
  overflow: hidden;
  align-self: flex-start;
  display: flex;
  align-items: center;
  max-width: 100%;
}
restaurant-tile .tile-container .title .label {
  font-size: 34px;
  text-overflow: ellipsis;
  overflow: hidden;
}
restaurant-tile .tile-container .title .picto,
restaurant-tile .tile-container .title fluidite-picto {
  margin-right: 8px;
}
restaurant-tile .tile-container .badges {
  margin: 3px 0;
}
restaurant-tile .tile-container .informations {
  font-style: italic;
  text-align: left;
}
restaurant-tile .tile-container .vote-thematique-svg {
  display: inline-table;
  width: 20px;
}
