@media (min-width: 995px) {
  .vote-page.main.restaurant {
    margin: auto 25%;
    width: 50%;
  }
}
.vote-page .thematique {
  width: 100%;
}
@media (min-width: 995px) {
}
@media (min-width: 995px) {
  .vote-page .thematique.thematique-header {
    margin-top: -100px;
  }
}
.vote-page .thematique.gray-back {
  background: #fafafa;
}
.vote-page .thematique .merci-message {
  font-family: pluto;
  text-align: center;
  background-color: #FDFDFD;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .vote-page .thematique .merci-message {
    display: none;
  }
}
.vote-page .thematique .merci-message .part1 {
  text-transform: capitalize;
  font-weight: 600;
  color: #1A1A1A;
  font-size: 34px;
}
.vote-page .thematique .merci-message .part2 {
  text-transform: lowercase;
  font-weight: 600;
  color: #ACACAC;
  margin-top: 10px;
  font-size: 20px;
}
.vote-page .thematique .thematique-title {
  background-color: #fafafa;
  padding: 15px 10px 5px;
}
.vote-page .thematique .thematique-title span {
  display: block;
}
.vote-page .thematique .thematique-title span.big {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}
.vote-page .thematique .thematique-title span.small {
  margin-top: 3px;
  font-size: 17px;
  font-style: italic;
}
.vote-page .thematique .option-group {
  font-family: avantgarde-book;
  padding-top: 40px;
}
@media (min-width: 995px) {
  .vote-page .thematique .option-group {
    padding-top: 110px;
  }
}
.vote-page .thematique .option-group .option {
  margin: 5px 10px;
  padding: 10px 10px;
  border: 1px solid #e6e6e6;
  background: white;
  border-radius: 3px;
}
.vote-page .thematique .option-group .option p {
  margin: 0px;
  display: inline-block;
}
.vote-page .thematique .option-group .option p.chevroon-container {
  float: right;
  padding-top: 5px;
}
.vote-page .thematique .option-group .option label {
  width: 100%;
  height: 100;
  cursor: pointer;
}
.vote-page .thematique .option-group .option:last-child {
  border-bottom: 1px solid #E7E7E7;
}
.vote-page .thematique .option-group .option.selected {
  border: 1px solid #d5d5d5;
  background-color: #eeeeee;
}
@media (min-width: 995px) {
  .vote-page .thematique .result-blocs {
    padding-top: 50px;
  }
}
.vote-page .thematique .result-group {
  font-family: avantgarde-book;
  padding-bottom: 5px;
}
.vote-page .thematique .result-group .result {
  margin: 5px 10px;
  padding: 10px 10px 0 10px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  background: white;
}
.vote-page .thematique .result-group .result .result-inside {
  margin: 0px;
}
.vote-page .thematique .result-group .result .result-inside .name-value {
  padding-bottom: 10px;
}
.vote-page .thematique .result-group .result .result-inside .name-value .name {
  text-transform: uppercase;
}
.vote-page .thematique .result-group .result .result-inside .name-value .value {
  float: right;
  font-size: 1.0em;
}
.vote-page .thematique .result-group .result:last-child {
  border-bottom: 1px solid #E7E7E7;
}
.vote-page .thematique .result-group .result.selected {
  background-color: #d5d5d5;
}
.vote-page .thematique .button-container {
  margin: 10px;
}
.vote-page .thematique .button-container button.bg-gray {
  background-color: #ACACAC;
}
.vote-page .thematique input[type="radio"] {
  appearance: radio;
  -webkit-appearance: radio;
  -moz-appearance: radio;
}
.vote-page .thematique [type="radio"]:checked,
.vote-page .thematique [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.vote-page .thematique [type="radio"]:checked + label,
.vote-page .thematique [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #333333;
}
.vote-page .thematique [type="radio"]:checked + label:before,
.vote-page .thematique [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
.vote-page .thematique [type="radio"]:checked + label:before {
  border: 1px solid #222;
  background: #000;
}
.vote-page .thematique [type="radio"]:not(:checked) + label:before {
  border: 1px solid #949494;
  background: #F2F2F1;
}
.vote-page .thematique [type="radio"]:checked + label:after,
.vote-page .thematique [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: url(assets/check.png) 100%/100% no-repeat;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-filter: invert(1);
  filter: invert(1);
}
.vote-page .thematique [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.vote-page .thematique [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.vote-page .thematique-detail {
  padding-top: 10px;
  font-size: 16px;
  line-height: 20px;
}
.vote-page .thematique-detail.thematique-description-content {
  padding-top: 0;
  margin-top: -20px;
}
@media (min-width: 995px) {
  .vote-page .thematique-detail.thematique-description-content {
    padding-top: 150px;
    margin-top: initial;
  }
}
.vote-page .thematique-detail .thematique-description {
  color: #333333;
}
.vote-page .thematique-detail .sous-thematique-name {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
}
.vote-page .thematique-detail .sous-thematique-description {
  color: #949494;
}
.vote-page .thematique-detail .with-padding {
  padding-left: 20px;
  padding-right: 20px;
}
.vote-page .thematique-detail .thematique-image {
  width: 100%;
  height: 200px;
}
@media (min-width: 995px) {
  .vote-page .thematique-detail.thematique-header:before {
    content: attr(data-before-content);
    background: #0000004d;
    position: absolute;
    width: 100%;
    height: 200px;
    line-height: 200px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-family: pluto;
    font-size: 45px;
    z-index: 3000;
  }
}
.vote-page .rz-pointer {
  display: none;
}
.vote-page .rzslider .rz-bar-wrapper .rz-bar {
  height: 5px;
  background-color: #f6f6f6;
}
.vote-page .rzslider .rz-bar-wrapper .rz-bar.rz-selection {
  background-color: #67A537;
}
@media (max-width: 767px) {
  .vote-page .escale-title {
    display: none;
  }
}
.vote-page .btn-votez {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}
.vote-page .description-concept iframe {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 90vw;
  max-width: 420px;
  max-height: 345px;
}
@media (min-width: 995px) {
  .vote-page .description-concept iframe {
    width: 420px;
    height: 345px;
  }
}
vote-picto .picto,
menu-picto .picto {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  width: 25px;
  height: 25px;
  margin-right: 8px;
}
vote-picto .picto.vote-open,
menu-picto .picto.vote-open {
  background: url('assets/pictos/votez.png') no-repeat;
  -webkit-transform: none;
  transform: none;
  width: 100%;
  height: 100%;
  background-size: 60%;
  border-radius: 50%;
  background-position: 50%;
  min-width: 19px;
  min-height: 27px;
}
vote-picto .picto.vote-close,
menu-picto .picto.vote-close {
  -webkit-transform: none;
  transform: none;
  background: url('assets/pictos/votez.png') no-repeat;
  width: 100%;
  height: 100%;
  background-size: 75%;
  border-radius: 50%;
  background-position: 50%;
  min-width: 19px;
  min-height: 27px;
}
vote-picto .picto.menu-open,
menu-picto .picto.menu-open {
  background: url('assets/pictos/picto-restaurants.png') no-repeat;
  -webkit-transform: none;
  transform: none;
  width: 100%;
  height: 100%;
  background-size: 95%;
  border-radius: 50%;
  background-position: 50%;
  min-width: 19px;
  min-height: 27px;
}
.plus-infos {
  background: #67A537;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 5px;
  justify-content: center;
  align-items: center;
  float: left;
  width: 27px;
  height: 27px;
  margin-right: 8px;
}
.uppercase {
  text-transform: uppercase;
}
vote-picto.desktop {
  display: none;
}
@media (min-width: 995px) {
  vote-picto.desktop {
    display: initial;
  }
}
@-webkit-keyframes votez-anim {
  0% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  25% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  75% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
}
@keyframes votez-anim {
  0% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  25% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  75% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
}
.vote-button:before {
  content: '';
  position: absolute;
  top: -55px;
  height: 50px;
  width: 120%;
  background-size: contain;
  animation: votez-anim 1.5s linear infinite;
  -webkit-animation: votez-anim 1.5s linear infinite;
}
.vote-button.lang-fr:before {
  content: '';
  position: absolute;
  top: -55px;
  height: 50px;
  width: 120%;
  background: url('assets/pictos/votez-anim.png') no-repeat;
  background-size: contain;
  animation: votez-anim 1.5s linear infinite;
  -webkit-animation: votez-anim 1.5s linear infinite;
}
.vote-button.lang-en:before {
  content: '';
  position: absolute;
  top: -55px;
  height: 50px;
  width: 120%;
  background: url('assets/pictos/votez-anim_en.png') no-repeat;
  background-size: contain;
  animation: votez-anim 1.5s linear infinite;
  -webkit-animation: votez-anim 1.5s linear infinite;
}
.vote-thematique-svg {
  display: inline-table;
  width: 20px;
}
