﻿@import (reference) '_variables.less';


.infos-site-page.scrollable-content{
    height: calc(~'100vh - 70px');
    
    .md({
        min-height: calc(~'100vh - 295px');
        height: auto !important;
    });
}

.infos-site-page:extend(.infos-page) {
    .favorites {

        & > div {
            margin-top: 15px;
        }

        padding: 15px 18px;

        .title {
            font-size: 18px;
        }

        .subtext {
            color: #acacac;
            font-size: 1.1em;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                border-bottom: 1px solid #e7e7e7;
                cursor: pointer;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            margin-bottom: -1px;
        }
    }
}

.favList {
    li > div {
        height: 100%;
        padding: 15px 0;
        background: white;
        position: relative;
    }

    .title {
        font-size: 18px;
    }
}

.as-sortable-dragging {
    svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        margin-bottom: -1px;
    }
}

@media (min-width: 995px) {
    .infos-site-page {
        min-height: calc(~"100vh - 300px") !important;

        .favorites {
            margin: 50px auto;
            width: 934px;
            background: #fff;
            box-shadow: 0 0 5px rgba(0,0,0,.5);
        }
    }
}

.infos-page {
    .md( {
        min-height: calc(~"100% - 295px");
        position: absolute;
        width: 100%;
        background: @desktopGrayBackground;
    }

    );

    .menu-list.scrollable-content {
        .swipable {
            position: relative;
            overflow: hidden;
            height: 2.3em !important;

            .original-info {
                width: 100%;
                height: 100%;
                border: none;
                box-sizing: border-box;
                margin: 0px;
                background-color: white;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 1;
                transition: .5s ease all;
                display: flex;
                vertical-align: middle;
                align-items: center;

                .subtext {
                    width: calc(~"100% - 55px");
                    margin-left: 10px;
                    word-break: break-all;
                    text-align: end;

                    &:after {
                        content: "";
                        height: 1.3em;
                        width: 1em;
                        float: right;
                        background: url(assets/arrow-right.png) ~"50%/50%" no-repeat;
                        margin-left: 5px;
                        font-size: 18px;
                    }
                }

                &.translate-swipe {
                    transform: translateX(-146px);
                }

                &.un-translate-swipe {
                    transform: translateX(0);
                }
            }

            .hidden-button {
                padding-top: 0;
                padding-bottom: 0;
                height: 100%;
                display: flex;
                width: 110px;
                border: none;
                position: absolute;
                top: 0;
                left: 100%;
                transform: translateX(-100%);
                background-color: @errorColor;
                color: white;
                z-index: 0;
                cursor: pointer;

                .subtext {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    color: white;
                }
            }
        }
        .deleteAccount{
            &:hover{
                cursor: pointer;
            }
        }
    }
}
