﻿@import (reference) '_variables.less';

.acces-entreprise-page {
    .md( {
        min-height: calc(~"100% - 295px");
        height: auto;
        position: absolute;
        width: 100%;
        background: @desktopGrayBackground;

        .scrollable-content {
            width: 50%;
            background: white;
            margin: auto;
            height: auto !important;
            margin-top: 50px;
            box-shadow: 0 0 5px rgba(0,0,0,.5);
        }
    }

    );


    .scrollable-content {
        height: calc(~'100vh - 70px');
    }

    input[type=checkbox] {
        width: 24px;
        height: 24px;
        display: table-cell;
        vertical-align: middle;
        margin-right: 10px;
        -webkit-appearance: checkbox;
    }

    .scrollable-content {
        height: calc(~'100vh - 70px');
    }

    hr {
        border-color:#e7e7e7;
        height: 1px;
    }

    ul.menu-list-param {
        padding: 0;
        margin: 0;
        list-style: none;

        .md( {
            margin: 50px auto;
            width: 970px;
            background: #fff;
            box-shadow: 0 0 5px fade(#000,50);
        }

        );

        li {
            padding-left: 18px;
            padding-right: 18px;

            &:not(.separator) {
                height: 1.3em;
                padding-top: 15px;
                padding-bottom: 15px;
                border-top: 1px solid @lightGray;
                border-bottom: 1px solid @lightGray;

                + li:not(.separator) {
                    border-top: none;
                }

                .picto {
                    width: 1.3em;
                    margin-right: 12px;
                    float: left;
                }

                .title {
                    font-size: 18px;
                }

                .subtext {
                    color: @grayControl;
                    float: right;
                    font-size: 1.1em;

                    .md( {
                        /*text-align: center;
                    float: none;*/
                    }

                    );
                }

                &.has-next {
                    > :last-child::after {
                        content: '';
                        height: 1.3em;
                        width: 1em;
                        float: right;
                        background: url(./assets/arrow-right.png) center / 50% no-repeat;
                        margin-left: 5px;
                        font-size: 18px;
                    }
                }

                &.selected {
                    // ":last-child::after" ne fonctionne pas sous Chrome lorsque la classe "selected" est changée dynamiquement avec Angular
                    &::after {
                        content: '';
                        height: 1.3em;
                        width: 1em;
                        float: right;
                        background: url(./assets/check.png) center / contain no-repeat;
                        font-size: 18px;
                    }
                }
            }

            &.separator {
                background: @lightGray;
                color: @gray;
                text-transform: uppercase;
                font-size: 13px;
                padding-top: 7px;
                padding-bottom: 7px;

                .md( {
                    text-align: center;
                });
            }

            .custom-help-picto {
                float: left;
                color: #77af4b;
                ;
                font-size: 16px;
                width: 13px;
                height: 19px;
                font-weight: 700;
                border: 2px solid;
                border-radius: 12px;
                padding-left: 6px;
                margin-right: 11px;
            }
        }
    }
}
