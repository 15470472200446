vote-thematique-title {
  font-family: pluto;
  display: flex;
  display: -webkit-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
vote-thematique-title .vote-thematique-title-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  font-size: 34px;
}
vote-thematique-title .vote-thematique-title-container .title {
  text-transform: capitalize;
}
vote-thematique-title .vote-thematique-title-container .informations {
  text-transform: uppercase;
  color: white;
  width: 100%;
  text-align: center;
}
vote-thematique-title .vote-thematique-title-container .white {
  color: white;
}
vote-thematique-title .vote-thematique-title-container .gray {
  color: #ACACAC;
}
