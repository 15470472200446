restaurant-fermeture {
  height: 100%;
  width: 100%;
}
restaurant-fermeture .bold {
  font-weight: bold;
}
restaurant-fermeture .red {
  color: red;
}
restaurant-fermeture .closure-picto {
  width: 12px;
  height: 12px;
  display: inline-block;
}
