﻿@import (reference) '_variables.less';
@import (reference) '_controls.less';

button.simule-checkbox {
    width: 24px;
    height: 24px;
    display: block;
    margin-right: 10px;
    margin-bottom: 0;
    background-position-y: 2px;
    border-radius: 4px;
    border: 1px solid #A0A0A0;
    background-color: rgb(250, 250, 250);
    background-size: contain;
    background-repeat: no-repeat;

    &.actif {
        background-image: url(./assets/pictos/green-arrow.png);
    }
}

button.simule-radiobutton {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    border-radius: 12px;
    border: 1px solid #A0A0A0;
    background-color: rgb(250, 250, 250);
    background-position: center;
    background-repeat: no-repeat;

    &.actif {
        background-image: url(./assets/pictos/green-point.png);
    }
}

.picto-info {
    background: url(assets/info.svg);
    width: 20px;
    background-size: contain;
    float: right;
    height: 20px;
}

.required-field {
    &:after {
        content: " *";
        color: @colorRed;
    }
}

.register-page {
    background-color: @lightBgColor;
    height: 100%;
    min-height: 100%;
    position: relative;



    .md( {
        background-image: url('./assets/bg-header.jpg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        padding: 35px 20px; //dirty fix en attendant refonte css
        margin-top: -20px;
    }

    );

    .register-title {
        display: none;

        .md( {
            display: block;
            background-color: transparent;
        }

        );

        h1 {
            margin: 0 0 11px 0;
            font-size: 42px;
            line-height: 42px;
            box-sizing: border-box;
            overflow: hidden;

            > .easy {
                color: black;
            }
        }
    }

    .register-container {
        padding: 20px;

        .md( {
            background-color: #E7E7E7;
            padding: 25px 45px;
            width: 60%;
            max-width: 600px;
            margin: 0 auto;
            position: relative;
        }

        );
    }

    &.scrollable-content {
        .md( {
            overflow-y: auto;
            padding-left: 40px;
        }

        );
        color: black;
        height: calc(~'100vh - 70px');

        .register-button {
            .md( {
                width: 250px;
                float: left;
                height: 40px;
            }

            );

            &.register-tcpro {
                width: 290px;

                button {
                    padding-left: 40px;
                }

                .loader {
                    left: 10px;
                }
            }
        }       

        form {
            position: relative;
        }

        .autocomplete-container {
            position: relative;
            margin-bottom: 10px;

            input {
                margin-bottom: 0;
            }

            .cross {
                position: absolute;
                right: 10px;
                width: 26px;
                height: 26px;
                top: 50%;
                transform: translateY(-50%);
                border: 1px solid black;
                border-radius: 50%;

                &:before,
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: ' ';
                    height: 15px;
                    width: 1px;
                    background-color: #333;
                }

                &:before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: block;

                a {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    box-sizing: border-box;
                    color: black;
                    height: @inputHeight;
                    line-height: @inputHeight;
                    padding-left: 15px;
                    width: 100%;
                    display: block;
                    background-color: @lightGray;
                    margin-bottom: 1px;

                    &:hover,
                    &:active {
                        background-color: lighten(@green, 50%);
                        font-weight: bold;
                        cursor: pointer;

                        .tastytime & {
                            color: white;
                            background-color: lighten(@beige, 20%);
                        }
                        .roomservicebyelior & {
                            color: white;
                            background-color: @eliorLightRed;
                        }
                    }
                }
            }

            &.autocomplete-results {
                position: relative;
                width: 100%;
            }
        }

        .buttons {
            .md( {
                display: flex;
                justify-content: space-between;
            }

            );
        }
    }

    label {
        .md( {
            color: @darkGray;
        }

        );
    }

    .custom-select {
        select {
            width: 100%;
            height: 45px;
            border: none;
            margin-bottom: 20px;
            outline: none;
            padding: 0px 15px;
        }
    }

    .custom-date {
        width: 100%;
        height: 45px;
        margin-bottom: 15px;
        padding: 0 15px;
        box-sizing: border-box;
        font-size: 18px;
        font-family: josefin;
        color: #000;
        border: 1px solid #f2f2f1;
        outline: none;
    }

    .sans-caisse-registration {
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .toogle-button {
            margin-right: 0px;
            flex-shrink: 0;
        }
    }

    .register-type-contrat {
        .type-contrat {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
        }
    }

    .recevoirOffresEliorAgreement,
    .recevoirOffresPartenairesAgreement,
    .cguAgreement {
        margin-bottom: 15px;
        display: table;

        input[type=checkbox] {
            width: 24px;
            height: 24px;
            display: table-cell;
            vertical-align: middle;
            margin-right: 10px;
            -webkit-appearance: checkbox;
        }

        label {
            display: table-cell;
            vertical-align: middle;

            a {
                text-decoration: underline;
            }
        }
    }

    .cgu-container {
        display: table;

        .cgu-title {
            display: table-cell;
            padding-right: 5px;
            font-weight: 600;
        }

        .chevroon {
            display: inline-block;
        }

        .cgu {
            color: #A1A1A1;
            font-style: italic;

            .md( {
                color: @darkGray;
                padding-top: 20px;
            }

            );
        }
    }

    input.ng-invalid-date {
        color: #b40000 !important;
        font-weight: bold;
    }
}

.help-link {
    float: left;
    margin-right: 10px;
    cursor: pointer;

    a {
        font-weight: 600;
        margin-right: 10px;
    }

    &.top-help-link {
        text-align: center;
        background-color: #1a1a1a;
        padding-top: 4px;
        width: 50px;
        height: 48px;
        border-radius: 25px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        // z-index: 10;
        top: -63px;
        right: 12px;
        margin-right: 0;

        .md( {
            top: 25px;
            right: 20px;
        }

        );

        a {
            font-weight: 900;
            margin-left: 1px;
            color: #fff;
            font-size: 36px;
            margin-right: 0px;
        }
    }
}
