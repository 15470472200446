﻿@import (reference) '_variables.less';
@import (reference) '_controls.less';

.resetpassword-page {
    background-color: @lightBgGray;
    height: 100%;
    min-height: 100%;
    position: relative;
    padding: 0 20px;
    min-height: calc(~'100vh');
    height: auto;

    .md({
        position: absolute !important;
        background-image: url('./assets/bg-login-big3.jpg');
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;
        width: 100%;
        margin: 0;
        padding: 0;
    });

    header h1 {
        margin: 11px 0;
        font-size: 42px;
        line-height: 42px;
    }

    .scrollable-content {
        background-size: cover;
        height: 100%;
        color: black;
        position: relative;
        -webkit-overflow-scrolling: touch;
        padding: 0 20px;

        form {
            position: relative;
        }
    }

    .resetPassword-button {
            margin-top: 30px;
            .md( {
                width: 250px;
                height: 40px;
            }
            );
        }

    .resetpassword-container {
        .md({
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -40%);
            background-color: rgba(255, 255, 255, 0.7);
            padding: 0 20px 35px 20px;
            text-align: center;
        });
    }
}
