﻿@import (reference) "_variables.less";

.footer {
    display: none;
    color: @fontWhite;
    background-color: black;
    margin-top: 50px;

    .roomservicebyelior & {
        color: @eliorGray;
        background-color: white;
    }

    &:extend(.clearfix all);

    .md( {
        display: block;
    }
    );

    h2 {
        margin: 25px 0;
        text-align: center;
    }

    .services {
        margin-top: 10px;
        margin-bottom: 60px;

        img {
            display: block;
            width: 75px;
            margin: 0 auto;

            // .tastytime & {
            //     .TcProGreenToTastyTimeBeige;
            // }
            // .roomservicebyelior & {
            //     .TcProGreenToEliorRed;
            // }
        }

        h3 {
            margin-top: 20px;
            margin-bottom: 30px;
            text-transform: uppercase;
        }

        .service {
            float: left;
            width: 100% / 3;
            padding: 0 15px;
            text-align: center;
        }
    }

    .links {
        color: @lightGray;
        list-style: none;
        text-align: center;

        li {
            display: inline-block;
            padding: 0 5px;

            &[ui-sref] {
                cursor: pointer;
            }

            +li {
                &::before {
                    content: "|";
                    padding-right: 10px;
                }
            }
        }
    }
}