﻿@import (reference) '_variables.less';
@import '_header.less';

@menuWidth: 300px;

.light-theme {
    .xs( {
        menu {
            background-color: #fafafa !important;

            .account {
                background-color: #f3f3f3 !important;

                .qrcode {
                    background: #fafafa !important;
                }

                .account-info {
                    .name {
                        color: #1a1a1a !important;
                    }
                }
            }

            ul {
                li {
                    color: #1a1a1a !important;
                }
            }
        }
    }

    );
}


menu {
    color: white;
    top: @headerXsHeight;
    position: fixed;
    width: @menuWidth;
    height: ~'calc(100% - @{headerXsHeight})';
    z-index: 1000; //transform: translate(-100%);
    //transform: none;
    left: -300px;
    margin-top: -@headerXsHeight;
    padding-top: @headerXsHeight;
    padding-left: 0; //transition: transform 0.3s ease;
    transition: left 0.3s ease;
    overflow: visible;
    background-color: fade(#0b0b0b, 95);
    @menuInnerHeight: 70px;

    .timechefpro & {
        margin-top: 0;
        padding-top: 0;
    }

    .tastytime & {
        margin-top: 0;
        padding-top: 0;
    }

    .roomservicebyelior & {
        margin-top: 0;
        padding-top: 0;
    }

    .md( {
        left: 0;
    }

    );

    .site-marque {
        position: absolute;
        left: 70px;
        max-width: 230px; //top: 15px;
        top: 7px;
        height: 55px;
        width: 100%;
        background-size: contain;

        .md( {
            top: -15px;
            display: none;
        }

        );
    }

    .md( {
        display: none;
        height: auto;
        position: absolute;
        width: 100%;
        transition: none; //transform: translateY(-100%);
        //top: 300px;
        transform: none;
        top: 212px;
        margin: 0;
        padding: 0;
        text-align: center;
        background: none !important; //overflow: visible;
        z-index: 9998;

        &.active {
            display: block;
        }
    }

    );

    &.menu-open {
        //transform: translate(0);
        //transform: none;
        left: 0;

        .md( {
            //transform: translateY(-100%);
        }

        );
    }

    .menu-inner {
        position: relative;
        box-sizing: border-box;

        .timechefpro & {
            position: absolute;
            right: 0px;
            top: 90px;
        }

        .tastytime & {
            position: absolute;
            right: 0px;
            top: 90px;
        }

        .roomservicebyelior & {
            position: absolute;
            right: 0px;
            top: 90px;
        }

        .md( {
            padding: 5px 20px;
            height: @menuInnerHeight;
            text-shadow: 0 0 5px fade(#000, 50);
        }

        );
    }



    .account {
        background-color: black;
        border-top: solid 1px fade(white, 20);
        border-bottom: solid 1px fade(white, 20);
        display: flex;
        flex-shrink: 1;

        .md( {
            margin-top: 6px;
            transform: none; //top: 50%;
            //transform: translateY(-50%);
            position: absolute;
            right: 20px;
            background: none !important;
            border: none !important;
            padding: 0;

            .qrcode {
                display: none;
            }

            .account-info {
                position: relative; //transform: translate(0);
                transform: none;
                margin: 0;
                flex-direction: row;
                align-items: center;

                .timechefpro & {
                    position: absolute;
                    top: 100px;
                    right: 30px;
                }

                .tastytime & {
                    position: absolute;
                    top: 100px;
                    right: 30px;
                }

                .roomservicebyelior & {
                    position: absolute;
                    top: 100px;
                    right: 30px;
                }

                .name,
                .solde {
                    display: inline-block;
                    padding-right: 5px;
                }
            }
        }

        );
    }

    .easypass-title {
        display: none;
        margin: 0;
        margin-top: -10px;

        div.logo {
            background-image: url('./assets/logo.png');
            height: 70px;
            width: 250px;
            background-size: 164px;
            background-repeat: no-repeat;
            background-position: center;
        }

        div.logotcpro2 {
            background-image: url('assets/logo_timechefpro_blanc.png');
            height: 70px;
            width: 250px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
        }
        //surcharge pour TastyTime (Product Backlog Item #32722)
        .tastytime & {
            margin-top: -210px; //On recale le logo plus haut
            div.logotcpro2 {
                background-image: url('assets/logotcpro.TastyTime.png'); //logo Tastytime plein avec texte noir
                height: 280px; // taille du logo tasty time
                width: 280px;
            }
        }

        .md( {
            display: inline-block;
        }

        );
    }

    ul {
        padding: 0;
        margin: 0; //TODO virer le calc et celui du haut parent puis réaligner ( display inline ? )
        height: calc(~'100% - 102px');

        .md( {
            display: flex;
            text-align: center; //padding: 10px 0;
            background-color: black;
            justify-content: space-around;

            .espacement {
                display: none;
            }
        }

        );

        .loop(@counter) when (@counter > 0) {
            &:first-child:nth-last-child( @{counter}) {
                height: (100% / @counter);

                & ~ li {
                    height: (100% / @counter);
                }
            }

            .loop(@counter - 1);
        }

        li {
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            .loop(12);
            border: #242424;
            list-style: none;
            border-width: 1px 0 0 0;
            height: 45px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 22px;
            text-decoration: none;
            color: white;
            max-height: 100px;

            .md( {
                display: flex;
                padding: 10px;
                height: auto;
                max-height: none;
                /*width: 100%;*/
                border-left: none !important;
                text-transform: uppercase;
                font-size: 15px;
                justify-content: center;

                .number {
                    margin: 0 5px !important;
                }
            }

            );

            img {
                width: 30px;
                padding: 0 25px;

                .md( {
                    display: none;
                }

                );
            }

            .number {
                margin: 0 25px;
                background: @green;
                padding: 0 10px;
                border-radius: 50px;
                line-height: 1.2;
                color: black;
            }
        }
    }
}
