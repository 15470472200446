dt-offers {
  display: block;
  background: #F2F2F1;
  height: calc(100vh - 70px);
}
@media (min-width: 995px) {
  dt-offers {
    background: lightgray;
    height: auto;
    min-height: calc(100vh - 295px);
  }
  dt-offers .scrollable-content {
    padding: 50px 0 20px;
  }
}
.dt-offers {
  background: #F2F2F1;
  padding: 0 0 20px;
}
@media (min-width: 995px) {
  .dt-offers {
    margin: 0 auto;
    width: 970px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}
.dt-offers .offers-infos {
  font-size: 14px;
  display: flex;
  background: #fff;
  padding: 10px;
}
.dt-offers .offers-infos .date {
  color: #949494;
  font-weight: bold;
  flex: 1;
  text-transform: uppercase;
}
.dt-offers .offers-infos .nb-lockers {
  font-style: italic;
  color: #67A537;
  align-self: flex-end;
}
@media (min-width: 995px) {
  .dt-offers .offers-infos {
    font-size: 16px;
    padding: 10px 20px;
  }
}
.dt-offers .offers-infos .basket-error {
  font-weight: bold;
  color: #DE2B2B;
  text-align: right;
}
.dt-offers .offers-list {
  padding: 0 10px;
}
@media (min-width: 995px) {
  .dt-offers .offers-list {
    padding: 0 20px;
  }
}
.dt-offers .offers-category {
  padding: 15px 0 5px;
}
.dt-offers .offers-category .plat {
  margin: 10px 0 0;
}
.dt-offers .category-title {
  color: #67A537;
  font-size: 16px;
  margin: 0 0 5px;
  text-transform: uppercase;
}
.dt-offers .btn-basket {
  cursor: pointer;
  font-family: josefin;
  font-size: 20px;
  line-height: 20px;
  box-sizing: border-box;
  padding: 10px;
  border: none;
  color: white;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  background-color: #67A537;
  justify-content: center;
  align-content: center;
  display: flex;
  margin: 20px 0 0;
}
.dt-offers .btn-basket .icon {
  background: url('assets/dinnertakeaway/panier_isolated.png') no-repeat center center;
  background-size: contain;
  height: 18.66666667px;
  width: 22.66666667px;
  margin: 0 10px 0 0;
  vertical-align: middle;
}
