.cc-page {
  height: calc(100vh - 70px);
  background: lightgray;
}
@media (min-width: 995px) {
  .cc-page {
    height: auto;
  }
  .cc-page .scrollable-content {
    padding: 50px 0;
  }
}
.cc-pageTermansAndConditions .scrollable-content {
  height: calc(100vh - 70px);
}
@media (min-width: 995px) {
  .cc-pageTermansAndConditions .scrollable-content {
    height: auto;
  }
}
@media (min-width: 768px) {
  .cc-pageTermansAndConditions {
    min-height: calc(100vh -  295px);
    background: lightgray;
  }
}
.cc-TermsAndConditions {
  background: #F2F2F1;
  padding: 20px;
  min-height: calc(100vh - 40px -  70px);
}
@media (min-width: 995px) {
  .cc-TermsAndConditions {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 110px -  295px);
  }
}
.cc-TermsAndConditions .libelle {
  font-size: 20px;
  margin: 0 0 20px;
}
.cc-TermsAndConditions .loading-message {
  height: 80%;
}
@media (min-width: 995px) {
  .cc-TermsAndConditions {
    margin: 0 auto;
    width: 970px;
  }
}
