.dt-article-detail.plat-page .informations {
  margin-top: 0;
}
.dt-article-detail.plat-page .scrollable-content {
  height: calc(100vh - 70px);
}
@media (min-width: 995px) {
  .dt-article-detail.plat-page .scrollable-content {
    height: auto;
  }
}
.dt-article-detail .article-portions {
  border: 1px solid #E7E7E7;
  border-top: 0;
  margin: 20px 20px 40px;
}
.dt-article-detail .article-using {
  color: #949494;
  margin: 20px 0 0;
  font-style: italic;
  text-align: center;
}
.dt-article-detail .article-using span {
  display: block;
}
