.hide-opacity {
  opacity: 0;
}
.graphic-view .graphic-container {
  height: 400px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.fluidite-page {
  height: calc(100vh - 70px);
  overflow: hidden;
}
@media (min-width: 995px) {
  .fluidite-page {
    min-height: calc(100vh - 295px);
    height: auto;
  }
}
.fluidite-page .scrollable-content {
  height: calc(100vh - 189px);
}
@media (min-width: 995px) {
  .fluidite-page .scrollable-content {
    min-height: calc(100vh - 295px);
    height: auto;
  }
}
.fluidite-page .loading-message {
  height: 50px;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (min-width: 995px) {
  .fluidite-page .loading-message {
    position: absolute;
    top: 350px;
    width: 100%;
  }
}
.fluidite-page .restaurant-slide {
  position: relative;
  margin-top: 0;
  background: white;
  padding-bottom: 0;
}
@media (min-width: 995px) {
  .fluidite-page .restaurant-slide {
    background: transparent !important;
    top: 35px;
    position: absolute;
    width: 100%;
    padding-top: 0;
  }
  .fluidite-page .restaurant-slide .loading-message {
    color: black !important;
  }
  .fluidite-page .restaurant-slide .link-to-menu-button {
    right: 0 !important;
    position: absolute;
  }
  .fluidite-page .restaurant-slide .link-to-menu-button .button img {
    width: 30px !important;
  }
  .fluidite-page .restaurant-slide .link-to-menu-button .ellipse {
    display: none;
  }
  .fluidite-page .restaurant-slide .occupation-info {
    margin: 0;
    margin-top: 260px;
  }
}
@media (min-width: 995px) and (min-width: 995px) {
  .fluidite-page .restaurant-slide .occupation-info.seats {
    margin-top: 285px;
    margin-bottom: 30px;
  }
}
@media (min-width: 995px) and (min-width: 995px) {
  .fluidite-page .restaurant-slide .occupation-info.emptyData {
    color: #000;
    text-shadow: none;
    width: 66.66666667%;
    position: absolute;
    left: 50%;
    top: 179px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    text-align: center;
  }
}
.fluidite-page .restaurant-slide .slide-restaurant-arrow {
  position: absolute;
  padding: 12px;
  top: 30px;
}
.fluidite-page .restaurant-slide .slide-restaurant-arrow.right {
  right: 0;
}
.fluidite-page .restaurant-slide .slide-restaurant-arrow .chevroon {
  padding: 2px;
}
.fluidite-page .restaurant-slide .slide-restaurant-arrow.left {
  left: 0;
}
.fluidite-page .restaurant-slide .restaurant-informations {
  box-sizing: border-box;
  padding: 18px 0;
  margin: 0 34px;
  white-space: nowrap;
  text-align: center;
}
@media (min-width: 995px) {
  .fluidite-page .restaurant-slide .restaurant-informations {
    margin: 0;
    padding: 0;
  }
}
.fluidite-page .restaurant-slide .restaurant-informations .libelle-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 58px;
}
.fluidite-page .restaurant-slide .restaurant-informations .libelle-container.container-fluidite {
  height: 40px;
  min-width: 50%;
}
.fluidite-page .restaurant-slide .restaurant-informations .libelle-container .libelle {
  font-size: 34px;
  display: block;
}
.fluidite-page .restaurant-slide .restaurant-informations .libelle-container .libelle img {
  height: 22px;
  margin-right: 6px;
  /*md ({
                    display: none;
                });*/
}
.fluidite-page .restaurant-slide .restaurant-informations .libelle-container .link-to-menu-button:hover {
  cursor: pointer;
}
.fluidite-page .restaurant-slide .restaurant-informations .libelle-container .link-to-menu-button .link-to-menu-container img {
  position: absolute;
  width: 30px;
  right: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.fluidite-page .restaurant-slide .restaurant-informations .info {
  display: block;
  font-style: normal;
  color: #949494;
  white-space: normal;
}
@media (min-width: 995px) {
  .fluidite-page .restaurant-slide .restaurant-informations .info {
    color: white !important;
  }
}
.fluidite-page .restaurant-slide .restaurant-informations .info img {
  float: right;
  height: 12px;
  margin-top: 4px;
}
.fluidite-page .occupation-info {
  padding: 10px;
  margin: 0 10px;
}
.fluidite-page .occupation-info.fluidite-occupation-eleve {
  background: rgba(214, 0, 0, 0.2);
}
.fluidite-page .occupation-info.fluidite-occupation-moyen {
  background: rgba(255, 156, 0, 0.2);
}
.fluidite-page .occupation-info.fluidite-occupation-faible {
  background: rgba(103, 165, 56, 0.2);
}
.fluidite-page .occupation-info.fluidite-occupation-na {
  background: rgba(255, 156, 0, 0.2);
}
