.buttons-commande {
  padding: 10px;
}
.buttons-commande button {
  /*display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;*/
  white-space: nowrap;
  margin: 0;
}
.buttons-commande button + button {
  margin-top: 10px;
}
.buttons-commande button .icon {
  background-size: contain;
  height: 28px;
  width: 34px;
  margin: 0 10px 0 0;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: right;
  vertical-align: middle;
}
@media (min-width: 995px) {
  .buttons-commande .btn-clickandcollect,
  .buttons-commande .btn-bonplan {
    /* => mode desktop only */
    width: 49%;
  }
}
.buttons-commande .btn-clickandcollect .icon.clickandcollect {
  background-image: url(assets/clickcollect.png);
}
.buttons-commande .btn-clickandcollect .icon.clickandserve {
  background-image: url(assets/pictos/click&serve.png);
}
.buttons-commande .btn-bonplan .icon {
  background-image: url(assets/bon_ap.png);
}
@media (max-width: 767px) {
  .buttons-commande button {
    font-size: 14px;
  }
}
@media (min-width: 995px) {
  .buttons-commande {
    padding: 20px;
    display: flex;
    justify-content: space-around;
  }
  .buttons-commande button + button {
    margin-top: 0;
  }
}
.buttons-commande .button-txt {
  vertical-align: middle;
  white-space: normal;
}
