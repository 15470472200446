.toggle-button {
  margin: 2px;
  width: 60px;
  height: 30px;
  position: relative;
  border-radius: 2px;
  background: url('assets/toogle-button/cross.png') 8px / 16px no-repeat, url('assets/toogle-button/check.png') 34px / 18px no-repeat, #999;
}
.toggle-button:before {
  content: ' ';
  display: block;
  border-radius: 2px;
  transition: -webkit-transform .4s;
  transition: transform .4s;
  transition: transform .4s, -webkit-transform .4s;
  background: url('assets/toogle-button/burger.png') center / 10px #fff no-repeat;
  position: absolute;
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
  margin: 3px;
  width: 24px;
  height: 24px;
}
.toggle-button.active {
  background-color: #67A537;
}
.toggle-button.active:before {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
