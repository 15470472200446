.dt-pageTermansAndConditions .scrollable-content {
  height: calc(100vh - 70px);
}
@media (min-width: 995px) {
  .dt-pageTermansAndConditions .scrollable-content {
    height: auto;
  }
}
@media (min-width: 768px) {
  .dt-pageTermansAndConditions {
    min-height: calc(100vh -  295px);
    background: lightgray;
  }
}
.dt-TermsAndConditions {
  background: #F2F2F1;
  padding: 20px;
  min-height: calc(100vh - 40px -  70px);
}
@media (min-width: 995px) {
  .dt-TermsAndConditions {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 110px -  295px);
  }
}
.dt-TermsAndConditions .libelle {
  font-size: 20px;
  margin: 0 0 20px;
}
.dt-TermsAndConditions .loading-message {
  height: 80%;
}
@media (min-width: 995px) {
  .dt-TermsAndConditions {
    margin: 0 auto;
    width: 970px;
  }
}
