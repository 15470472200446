.new-line {
  display: block;
}
.tc-commande-detail {
  box-sizing: border-box;
  color: #1A1A1A;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}
.tc-commandes-page.scrollable-content {
  height: calc(100vh - 70px);
}
.tc-commande-nav,
.tc-commande-current {
  background: #fff;
  position: relative;
}
.tc-commande-nav:after,
.tc-commande-current:after {
  background: linear-gradient(-45deg, #E7E7E7 18px, transparent 0), linear-gradient(45deg, #E7E7E7 18px, transparent 0);
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: 36px 66px;
  content: " ";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 36px;
}
.tc-commande-nav {
  flex-basis: 15px;
  flex-shrink: 0;
  transition: opacity .3s;
}
.tc-commande-nav.disabled {
  opacity: 0;
}
.in-transition .tc-commande-nav {
  opacity: 0;
}
.tc-commande-prev:after {
  background-position: left bottom;
}
.tc-commande-next:after {
  background-position: right bottom;
}
.tc-commande-current {
  flex-basis: 615px;
  margin: 0 15px;
  padding: 0 15px 60px;
  overflow: hidden;
  display: flex;
}
.tc-commande-current .loading-error {
  padding: 20px 0;
}
.tc-commande-current .commande-content {
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  flex: 1;
}
.tc-commande-current .commande-content .new-line {
  margin-top: 5px;
}
.tc-commande-current header {
  color: #1A1A1A;
  padding: 15px 20px;
  position: relative;
  margin-top: 0 !important;
}
.tc-commande-current header .chevroon-container {
  top: 10px;
  position: absolute;
  display: inline-block;
  padding: 10px;
}
.tc-commande-current header .chevroon-container.left {
  left: 0;
  padding-right: 5px;
}
.tc-commande-current header .chevroon-container.right {
  right: 0;
  padding-left: 5px;
}
.tc-commande-current header h2 {
  font-weight: normal;
  margin: 0;
}
.tc-commande-current header h2 span {
  font-size: inherit;
  font-weight: normal;
}
.tc-commande-current header h2 span:before,
.tc-commande-current header h2 span:after {
  display: none;
}
.tc-commande-current header .numero-table {
  margin: 10px 0 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
.tc-commande-current header .locker-infos {
  margin: 10px 0 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
.tc-commande-current .commande-infos {
  border: 1px solid #E7E7E7;
  border-width: 1px 0;
  font-size: 14px;
  font-weight: bold;
  list-style: none;
  margin: 0;
  padding: 10px 0;
}
.tc-commande-current .commande-infos li + li {
  margin: 10px 0 0;
}
.tc-commande-current .commande-infos li span {
  font-weight: normal;
  text-transform: uppercase;
}
.tc-commande-current .commande-infos .new-line {
  margin-top: 10px;
}
.tc-commande-current .commande-articles {
  padding: 15px 0;
}
.tc-commande-current .commande-articles > * {
  display: block;
}
.tc-commande-current .commande-articles > * + * {
  margin: 10px 0 0;
}
.tc-commande-current .commande-articles .article-item {
  display: flex;
}
.tc-commande-current .commande-articles .article-item + .article-item {
  margin: 10px 0 0;
}
.tc-commande-current .commande-articles .article-name {
  flex: 1;
}
.tc-commande-current .commande-articles .portion-type,
.tc-commande-current .commande-articles .composition {
  font-size: 14px;
  display: block;
  word-break: break-word;
}
.tc-commande-current .commande-articles .portion-type {
  text-transform: uppercase;
}
.tc-commande-current .commande-articles .composition {
  font-style: italic;
}
.tc-commande-current .commande-articles .quantity,
.tc-commande-current .commande-articles .price {
  text-align: right;
  margin-left: 5px;
}
.tc-commande-current .commande-articles .quantity {
  width: 30px;
}
.tc-commande-current .commande-articles .price {
  width: 60px;
}
.tc-commande-current .commande-price {
  font-weight: bold;
  text-transform: uppercase;
}
.tc-commande-current .commande-price .price-detail {
  border: 1px dashed;
  border-width: 1px 0;
  display: flex;
  padding: 10px 0;
  text-align: center;
  justify-content: flex-end;
}
.tc-commande-current .commande-price .price-detail .price + .price {
  margin-left: 30px;
}
.tc-commande-current .commande-price .price-detail .price span {
  display: block;
}
.tc-commande-current .commande-price .sous-total,
.tc-commande-current .commande-price .total {
  padding: 10px 0 0;
  display: flex;
}
.tc-commande-current .commande-price .sous-total .price,
.tc-commande-current .commande-price .total .price {
  flex: 1;
  text-align: right;
  white-space: nowrap;
  margin-left: 5px;
}
.tc-commande-current .commande-price .sous-total {
  font-size: 16px;
}
.tc-commande-current .commande-price .total {
  font-size: 18px;
}
.tc-commande-current footer {
  margin: 30px 0 0;
}
.tc-commande-current footer .restaurant-name {
  font-weight: bold;
}
.tc-commande-current footer .commande-num {
  display: flex;
}
.tc-commande-current footer .commande-num .num {
  flex: 1;
  text-align: right;
  word-break: break-all;
}
.tc-commande-current footer .greeting {
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 995px) {
  .tc-commande-detail {
    padding: 20px 0;
  }
  .tc-commande-nav {
    flex-basis: 55px;
  }
}
