#display-chevroon {
  display: none;
}
#display-chevroon img {
  -webkit-transform: translateZ(0) rotate(180deg);
          transform: translateZ(0) rotate(180deg);
}
.panier-line {
  height: auto !important;
  position: relative;
}
.panier-line .panier-item .libelle {
  white-space: nowrap;
  margin-left: 25px;
  width: calc(100% - 145px);
  float: left;
}
.panier-line .panier-item .libelle .article-libelle {
  font-size: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.panier-line .panier-item .libelle .composition-libelle {
  color: #949494;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: italic;
  font-size: 16px;
}
.panier-line .panier-item .libelle .composition-libelle.active {
  white-space: pre-line !important;
}
.panier-line .panier-item .dropdown-chevroon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(100% - 137px);
}
.panier-line .panier-item .dropdown-chevroon > div {
  height: 100%;
  display: flex;
  align-items: center;
}
.panier-line .panier-item .dropdown-chevroon > div img {
  width: 65%;
  -webkit-transform: translateZ(0) rotate(180deg);
          transform: translateZ(0) rotate(180deg);
  transition: all 0.5s ease;
}
.panier-line .panier-item .dropdown-chevroon.active img {
  -webkit-transform: rotate(0deg) translateZ(0);
          transform: rotate(0deg) translateZ(0);
  transition: all 0.5s ease;
}
.panier-line .panier-item .remove {
  top: 50%;
  position: absolute;
  left: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.panier-line .panier-item .prix-quantite {
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin-right: 20px;
  width: 95px;
}
.panier-line .panier-item .prix-quantite select {
  padding: 5px 2px;
  margin-right: 10px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 3px;
  width: 30px;
  font-family: josefin;
  font-size: 20px;
  font-weight: bold;
}
.panier-line .panier-item .prix-quantite select::-ms-expand {
  display: none;
}
.panier-line .panier-item .prix-quantite .flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
}
.panier-line .panier-item .prix {
  color: #67A537;
}
.total-panier {
  margin: 20px 5px;
  float: right;
  font-weight: 700;
}
.total-panier .total-panier-soustotal,
.total-panier .total-panier-admission {
  text-align: right;
  font-size: 16px;
  margin-bottom: 5px;
}
.total-panier .total-panier-soustotal .prix,
.total-panier .total-panier-admission .prix {
  color: #67A537;
  white-space: nowrap;
  display: inline-block;
  min-width: 75px;
}
.total-panier .total-panier-total {
  margin-top: 10px;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
}
.total-panier .total-panier-total .prix {
  color: #67A537;
  white-space: nowrap;
  display: inline-block;
  min-width: 75px;
}
.use-last-order {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  display: block;
  padding: 20px;
}
.panier-page {
  height: calc(100vh - 70px);
  position: relative;
  overflow-x: hidden;
}
@media (min-width: 995px) {
  .panier-page {
    min-height: calc(100vh - 295px);
    height: auto;
  }
}
.panier-page scrollable-content {
  height: calc(100vh - 276px) !important;
}
.panier-page .menu-list {
  margin-bottom: 20px;
}
@media (min-width: 995px) {
  .panier-page .menu-list {
    margin-bottom: 10px;
  }
}
@media (min-width: 995px) {
  .panier-page .menu-list.scrollable-content {
    height: auto !important;
    margin-bottom: 2px;
  }
}
.panier-page .fix-ios-scroll {
  min-height: calc(100vh - 70px);
}
.panier-page .price-message {
  color: red;
  margin-bottom: 10px;
  position: relative;
}
.panier-page .price-message span {
  text-align: left;
  color: black !important;
}
.panier-page .price-message div {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #67A537 !important;
}
.tastytime .panier-page .price-message div {
  color: #9A8C7D !important;
}
.roomservicebyelior .panier-page .price-message div {
  color: #E50051 !important;
}
.panier-page .refill-message {
  color: red;
  margin-bottom: 10px;
}
.panier-page .error-message {
  color: red;
  padding: 5px;
}
.panier-page .panier-select2 .select2-results .select2-highlighted {
  background: #67A537;
  color: #fff;
}
.panier-page .panier-select2 .select2-drop-active {
  border: 1px solid #67A537;
}
.panier-page .panier-select2 .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #67A537;
}
.panier-page .panier-select2 .select2-container-active .select2-choices {
  border: 1px solid #67A537;
}
.panier-page .panier-select2 .select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #67A537;
}
.panier-page .panier-select2 .select2-container[disabled] {
  opacity: initial;
}
.panier-page .panier-select2 .select2-container[disabled] .select2-arrow {
  display: none;
}
.panier-page .numero-table {
  display: flex;
  border: 1px solid #67A537;
  font-size: 20px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  padding: 10px;
}
@media (min-width: 995px) {
  .panier-page .numero-table {
    width: 50%;
    margin: auto;
  }
}
.panier-page .numero-table .retrait-title {
  flex: 1;
  text-align: left;
  align-self: center;
}
.panier-page .numero-table input {
  float: right;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  width: 100px;
  margin: 8px;
  height: 30px;
}
.panier-page .date-lieu-retrait {
  font-size: 20px;
  text-align: center;
  padding: 10px 0px;
}
.panier-page .date-lieu-retrait .select2-results .select2-highlighted {
  background: #67A537;
  color: #fff;
}
.panier-page .date-lieu-retrait .select2-drop-active {
  border: 1px solid #67A537;
}
.panier-page .date-lieu-retrait .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #67A537;
}
.panier-page .date-lieu-retrait .select2-container-active .select2-choices {
  border: 1px solid #67A537;
}
.panier-page .date-lieu-retrait .select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #67A537;
}
.panier-page .date-lieu-retrait .select2-container[disabled] {
  opacity: initial;
}
.panier-page .date-lieu-retrait .select2-container[disabled] .select2-arrow {
  display: none;
}
.panier-page .date-lieu-retrait .retrait-icon {
  float: left;
  background-size: cover;
  width: 24px;
  height: 24px;
  margin: 13px 10px;
}
.panier-page .date-lieu-retrait select {
  padding: 5px 2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: 10px;
}
.panier-page .date-lieu-retrait .ui-select {
  width: 100%;
  border-color: #67A537;
}
@media (min-width: 995px) {
  .panier-page .date-lieu-retrait .ui-select {
    width: 50%;
  }
}
.panier-page .date-lieu-retrait .ui-select-match {
  z-index: 3000;
  height: 50px;
  border: 1px solid #67A537;
}
.panier-page .date-lieu-retrait span.retrait-selected {
  display: block;
  height: 25px;
  font-weight: bold;
}
.panier-page .date-lieu-retrait span.retrait-title {
  display: block;
  height: 25px;
  font-size: 16px;
  font-style: italic;
  color: #949494;
}
.panier-page .date-retrait {
  font-size: 20px;
  text-align: center;
  padding: 10px 0px;
}
.panier-page .date-retrait .select2-results .select2-highlighted {
  background: #67A537;
  color: #fff;
}
.panier-page .date-retrait .select2-drop-active {
  border: 1px solid #67A537;
}
.panier-page .date-retrait .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #67A537;
}
.panier-page .date-retrait .select2-container-active .select2-choices {
  border: 1px solid #67A537;
}
.panier-page .date-retrait .select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #67A537;
}
.panier-page .date-retrait .select2-container[disabled] {
  opacity: initial;
}
.panier-page .date-retrait .select2-container[disabled] .select2-arrow {
  display: none;
}
.panier-page .date-retrait .retrait-icon {
  float: left;
  background-size: cover;
  width: 24px;
  height: 24px;
  margin: 13px 10px;
}
.panier-page .date-retrait select {
  padding: 5px 2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: 10px;
}
.panier-page .date-retrait .ui-select {
  width: 100%;
  border-color: #67A537;
}
@media (min-width: 995px) {
  .panier-page .date-retrait .ui-select {
    width: 50%;
  }
}
.panier-page .date-retrait .ui-select-match {
  z-index: 3000;
  height: 50px;
  border: 1px solid #67A537;
}
.panier-page .date-retrait span.retrait-selected {
  display: block;
  height: 25px;
  font-weight: bold;
}
.panier-page .date-retrait span.retrait-title {
  display: block;
  height: 25px;
  font-size: 16px;
  font-style: italic;
  color: #949494;
}
.panier-page .date-retrait .retrait-icon {
  background-image: url('assets/clock.png');
}
.panier-page .lieu-retrait {
  font-size: 20px;
  text-align: center;
  padding: 10px 0px;
}
.panier-page .lieu-retrait .select2-results .select2-highlighted {
  background: #67A537;
  color: #fff;
}
.panier-page .lieu-retrait .select2-drop-active {
  border: 1px solid #67A537;
}
.panier-page .lieu-retrait .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #67A537;
}
.panier-page .lieu-retrait .select2-container-active .select2-choices {
  border: 1px solid #67A537;
}
.panier-page .lieu-retrait .select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #67A537;
}
.panier-page .lieu-retrait .select2-container[disabled] {
  opacity: initial;
}
.panier-page .lieu-retrait .select2-container[disabled] .select2-arrow {
  display: none;
}
.panier-page .lieu-retrait .retrait-icon {
  float: left;
  background-size: cover;
  width: 24px;
  height: 24px;
  margin: 13px 10px;
}
.panier-page .lieu-retrait select {
  padding: 5px 2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: 10px;
}
.panier-page .lieu-retrait .ui-select {
  width: 100%;
  border-color: #67A537;
}
@media (min-width: 995px) {
  .panier-page .lieu-retrait .ui-select {
    width: 50%;
  }
}
.panier-page .lieu-retrait .ui-select-match {
  z-index: 3000;
  height: 50px;
  border: 1px solid #67A537;
}
.panier-page .lieu-retrait span.retrait-selected {
  display: block;
  height: 25px;
  font-weight: bold;
}
.panier-page .lieu-retrait span.retrait-title {
  display: block;
  height: 25px;
  font-size: 16px;
  font-style: italic;
  color: #949494;
}
.panier-page .lieu-retrait .retrait-icon {
  background-image: url('assets/place.png');
}
.panier-page .bottom-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding: 0px 18px 18px;
  background: white;
  margin: auto;
  width: auto;
  text-align: center;
}
@media (min-width: 995px) {
  .panier-page .bottom-button {
    height: auto;
  }
}
.panier-page .bottom-button .align-buttons button.secondary {
  display: none;
}
@media (min-width: 995px) {
  .panier-page .bottom-button .align-buttons button {
    display: inline-block;
    width: 350px;
    margin: 0 15px;
  }
  .panier-page .bottom-button .align-buttons button.secondary {
    display: inline-block;
  }
  .panier-page .bottom-button .align-buttons .loader {
    left: 515px;
  }
}
@media (min-width: 995px) {
  .panier-page .bottom-button {
    width: 970px;
  }
}
.panier-page .bottom-button::before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  padding: 18px;
  background: #fff;
  width: 100%;
  top: -36px;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
}
@media (min-width: 995px) {
  .panier-page .bottom-button::before {
    display: none;
  }
}
.panier-page .bottom-button button {
  margin: 0;
}
.suggestion {
  display: none;
  background: white;
  border: 1px solid #e7e7e7;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 50%;
  bottom: 50px;
  height: auto;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  min-width: 220px;
  max-width: 390px;
  padding: 20px;
  z-index: 4000;
}
.suggestion.show-suggestion {
  opacity: 0;
  display: block;
  -webkit-animation: appear-suggestion 0.6s forwards;
          animation: appear-suggestion 0.6s forwards;
}
.suggestion button {
  background: #e7e7e7;
  color: #949494;
  font-size: 16px;
}
.suggestion span.no-thanks {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  display: block;
}
.suggestion div {
  margin-top: 15px;
}
.panier-header.back-button {
  margin-top: -70px;
}
.retrait-page {
  background-image: url('assets/default-restaurant.jpg');
  background-size: cover;
  background-position: 50%;
  min-height: calc(100vh - 70px);
  height: auto;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 995px) {
  .retrait-page {
    width: 100%;
    height: calc(100% - 295px);
    min-height: initial;
    top: 295px;
    position: absolute;
  }
}
.retrait-page button {
  background-color: #000;
  margin: 0;
}
.retrait-page .panier-picto {
  background-image: url('assets/pictos/panier.png');
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.retrait-page .panier-picto .picto-fill {
  background-color: #67A537;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.retrait-page .panier-picto img {
  width: 45%;
}
.retrait-page .message {
  margin: 0 25px !important;
  background: white;
}
.retrait-page .new-price {
  margin-bottom: 10px;
  font-size: 80px;
  color: #67A537;
  text-align: center;
  width: 100%;
}
.retrait-page .takeout-day {
  margin-bottom: 10px;
  font-size: 25px;
  color: #67A537;
  text-align: center;
  width: 100%;
}
.retrait-page .takeout-range {
  margin-bottom: 10px;
  font-size: 35px;
  color: #67A537;
  text-align: center;
  width: 100%;
}
.retrait-page .takeout-lieu {
  margin-bottom: 10px;
  font-size: 25px;
  color: #67A537;
  text-align: center;
  width: 100%;
}
.retrait-page .numero-table {
  margin: 40px 0px;
  font-size: 25px;
  color: #67A537;
  text-align: center;
  width: 100%;
  font-weight: 700;
}
.retrait-page .libelle {
  font-size: 25px;
  width: 100%;
  text-align: center;
}
.basket-cgv {
  display: flex;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  margin: -10px;
  padding: 20px;
  background-color: #F2F2F1;
}
.basket-cgv-checkbox {
  display: flex;
  background: #fff;
  border: 1px solid #949494;
  margin: 0 10px 0 0;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
}
.basket-cgv-checkbox:before {
  content: '';
  display: none;
  height: 10px;
  width: 10px;
}
.basket-cgv-checkbox.checked:before {
  display: block;
  background: #67A537;
}
.basket-cgv .text {
  flex: 1;
}
.basket-cgv a {
  text-decoration: underline;
}
.basket-payment {
  text-align: center;
}
.basket-payment-cb {
  color: #949494;
  font-style: italic;
  margin-top: 20px;
}
@media (min-width: 995px) {
  .basket-payment-cb {
    margin-top: 10px;
    margin: 0 0 10px;
  }
}
.basket-purchase-requirement {
  font-size: 14px;
}
@media (min-width: 995px) {
  .basket-payment {
    padding: 20px;
    margin: 20px -20px;
  }
  .basket-cgv-checkbox {
    margin: 0 20px 0 0;
  }
  .basket-payment-cb {
    margin: 0 0 20px;
  }
  .basket-purchase-requirement {
    font-size: 14px;
  }
}
@-webkit-keyframes appear-suggestion {
  from {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    opacity: 1;
  }
}
@keyframes appear-suggestion {
  from {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    opacity: 1;
  }
}
