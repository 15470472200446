.pop-in {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(3, 3, 3, 0.6);
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;
  transition: all linear 0.5s;
}
.pop-in.ng-hide-remove,
.pop-in.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  display: flex !important;
}
.pop-in.ng-hide-add,
.pop-in.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
  display: flex !important;
}
.pop-in .close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
  width: 24px;
  height: 24px;
}
.pop-in .close:before,
.pop-in .close:after {
  position: absolute;
  right: 21px;
  content: ' ';
  height: 23px;
  width: 2px;
  background-color: #E7E7E7;
}
.pop-in .close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.pop-in .close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.pop-in-container {
  width: 80%;
  min-height: 150px;
  background-color: white;
  margin: 10px;
  padding: 20px;
  position: relative;
}
.pop-in-container .pop-in-buttons {
  position: relative;
  bottom: 0;
  left: 25%;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pop-in-container .pop-in-buttons .pop-in-button.accept {
  background-color: #67A537;
}
.tastytime .pop-in-container .pop-in-buttons .pop-in-button.accept {
  background-color: #9A8C7D;
}
.roomservicebyelior .pop-in-container .pop-in-buttons .pop-in-button.accept {
  background-color: #E50051;
}
.pop-in-container .pop-in-buttons .pop-in-button.accept:hover {
  background-color: #7EC14E;
}
.tastytime .pop-in-container .pop-in-buttons .pop-in-button.accept:hover {
  background-color: #BDB1A5;
}
.roomservicebyelior .pop-in-container .pop-in-buttons .pop-in-button.accept:hover {
  background-color: #E9496D;
}
.pop-in-container .pop-in-buttons .pop-in-button.cancel {
  background-color: black;
}
.pop-in-container .pop-in-buttons .pop-in-button.cancel:hover {
  background-color: #333333;
}
@media (min-width: 768px) {
  .pop-in-container {
    width: 600px;
  }
  .pop-in-container .pop-in-buttons {
    flex-direction: initial !important;
  }
  .pop-in-container .pop-in-buttons .pop-in-button {
    width: 200px;
    margin-right: 25px;
    margin-left: 25px;
  }
}
.pop-in-content {
  text-align: center;
  color: #1A1A1A;
  text-shadow: none;
}
.pop-in-content h2 {
  font-size: 26px;
}
.pop-in-content p {
  font-size: 20px;
}
.pop-in-content p.popin-error {
  color: red;
}
@media (max-width: 767px) {
  .pop-in-content h2 {
    font-size: 20px;
  }
  .pop-in-content p {
    font-size: 16px;
  }
}
