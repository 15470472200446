﻿@import (reference) '_variables.less';

.help-page {
    padding: 0px;
    background: url(assets/register-help-bg.png) no-repeat 0px;
    background-size: cover;
    width: 100%;
    height: 100%;

    .md( {
        background: #eee;
        min-height: calc(~"100% - 300px");

    }

    );

    &.scrollable-content{
        height: calc(~'100vh - 70px');
        width: 100% !important;
    }

    &.globalhelp-page {
        background: url(assets/affluence-help-bg.png) no-repeat 0px;
        background-size: cover;

        .md( {
            background: #eee;
            min-height: calc(~"100% - 295px");    
            height: auto;
            position: absolute;
            margin-top: 300px;

        //dirty fix en attendant refonte css
        overflow: auto;
        }

        );

        .help-picto {
            width: 85px;
            height: 136px;
            margin: auto;

            img {
            }
        }
    }


    .fix-ios-scroll {
        height: 100%;
    }

    .help-carousel {
        min-height: 60%;
        width: 87vw;
        background-color: white;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
        text-align: center;
        margin: 25px auto;

        .md( {
            min-height: 301px;
            display: block;
            width: 800px;
            margin: auto;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        );

        .slider {
            .help {
                min-height: auto;
                overflow-y: auto;
                height: 100%;
                width: 87vw;

                .md( {
                    width: 800px;
                }

                );

                .help-picto {
                    img {
                        width: 78px;
                        padding-bottom: 22px;
                        margin-top: 40px;
                    }
                }



                h4 {
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: normal;
                    font-size: 26px;
                    margin: 0px;
                    margin-bottom: 20px;
                }

                .hr {
                    width: 70px;
                    height: 4px;
                    background-color: @green;
                    margin: auto;
                    margin-bottom: 22px;
                }


                p {
                    text-align: center;
                    margin: 0;
                    margin-left: 30px;
                    margin-right: 30px;
                    line-height: 20px;
                }

                ul {
                    margin: 0;
                    text-align: left;
                    margin-right: 30px;
                    line-height: 20px;
                    padding-left: 26%;
                }
            }
        }

        &.slider-container {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            top: 0;
            height: initial !important;
            margin: 25px auto;

            .md( {
                min-height: 350px;
                height: auto !important;
                bottom: initial;
                padding-bottom: 5vh;
                position: relative;
                transform: none;
                left: 0;
                
                //dirty fix en attendant refonte css
                margin-top: 50px;
            }

            );



            .slider {
                height: 90%;
            }
        }

        .dots {
            bottom: 7%;

            .dot {
                background-color: #cecece;

                &.active {
                    background-color: #3b3b3b;
                }
            }
        }
    }
}
