.main {
  height: 100vh;
}
.main.restaurant {
  height: auto;
}
.main.restaurant .gradient-container {
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  position: relative;
  cursor: pointer;
}
.main.restaurant .gradient-container.black {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5));
}
@media (min-width: 995px) {
  .main.restaurant {
    background: #fff;
    min-height: calc(-295%);
    height: auto;
    padding: 50px 0;
    position: absolute;
    width: 100%;
  }
  .main #fixed-container {
    position: absolute !important;
  }
}
.header.active.restaurant {
  display: none;
}
@media (min-width: 995px) {
  .header.active.restaurant {
    display: block;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    overflow: hidden;
  }
}
#fixed-container {
  top: 0;
}
@media (min-width: 995px) {
  #fixed-container {
    background-color: inherit;
    z-index: 1002;
    width: 100%;
  }
}
.text-center {
  text-align: center;
}
.slider-container {
  position: relative;
  overflow: hidden;
  height: 35vh;
}
@media (min-width: 995px) {
  .slider-container {
    display: none;
  }
}
.slider-container .slider {
  width: 9000px;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  position: relative;
  height: 100%;
}
.slider-container .slider .slide {
  position: relative;
  float: left;
  width: 100vw;
  background-size: cover;
}
.slider-container .slider img {
  width: 100%;
}
.slider-container .dots {
  position: absolute;
  bottom: 42px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.slider-container .dots .dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: white;
  margin: 0 3px;
  float: left;
  transition: background .5s;
}
.slider-container .dots .dot.active {
  background: black;
}
.days-container {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
}
@media (min-width: 995px) {
  .days-container {
    margin: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    width: 970px;
    margin-top: -100px;
  }
}
.days-container .day-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px;
  height: calc(20vw - 12px);
  color: #949494;
  background-color: #E7E7E7;
  transition: background-color 0.3s;
  border-left: 1px solid white;
}
@media (min-width: 995px) {
  .days-container .day-button {
    height: 50px;
  }
}
.days-container .day-button.active {
  background-color: #67A537;
  color: black;
}
.days-container .day-button.closed {
  background-color: #e22b2b;
  color: black;
}
.days-container .day-button:first-child {
  border: none;
}
.days-container .day-button .day {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 3vw;
}
@media (min-width: 995px) {
  .days-container .day-button .day {
    font-size: 12px;
  }
}
.days-container .day-button .date {
  display: block;
  text-align: center;
  font-size: 10vw;
  line-height: 1;
}
.days-container .day-button .date .month {
  display: none;
}
@media (min-width: 995px) {
  .days-container .day-button .date .month {
    display: inline;
    text-transform: uppercase;
  }
}
@media (min-width: 995px) {
  .days-container .day-button .date {
    font-size: 30px;
  }
}
.restaurant-slide {
  position: relative;
  margin-top: -30px;
  padding: 10px 0;
}
@media (min-width: 995px) {
  .restaurant-slide {
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    top: -200px;
    margin-top: -80px;
    text-align: center;
    height: 200px;
  }
}
.restaurant-slide .slide-restaurant-arrow {
  position: absolute;
  padding: 12px;
  top: 30px;
}
@media (min-width: 995px) {
  .restaurant-slide .slide-restaurant-arrow {
    display: none;
  }
}
.restaurant-slide .slide-restaurant-arrow.right {
  right: 0;
}
.restaurant-slide .slide-restaurant-arrow .chevroon {
  padding: 2px;
}
.restaurant-slide .slide-restaurant-arrow.left {
  left: 0;
}
.restaurant-slide .restaurant-informations {
  box-sizing: border-box;
  /*margin-left: 34px;
        margin-top: 15px;*/
  margin: 18px 40px 0 40px;
}
@media (min-width: 995px) {
  .restaurant-slide .restaurant-informations {
    position: absolute;
    margin: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    min-width: 360px;
  }
}
.restaurant-slide .restaurant-informations .libelle-container {
  display: flex;
  align-items: center;
  padding-right: 40px;
  min-width: 100%;
}
.restaurant-slide .restaurant-informations .libelle-container .desktop-logo {
  display: none;
}
@media (min-width: 995px) {
  .restaurant-slide .restaurant-informations .libelle-container {
    justify-content: center;
  }
  .restaurant-slide .restaurant-informations .libelle-container .desktop-logo {
    margin: -20px 12.5px 5px;
    display: block !important;
  }
  .restaurant-slide .restaurant-informations .libelle-container .desktop-logo img {
    width: 30px;
  }
}
.restaurant-slide .restaurant-informations .libelle-container a {
  display: flex;
  align-items: center;
}
@media (min-width: 995px) {
  .restaurant-slide .restaurant-informations .libelle-container a {
    justify-content: center;
  }
}
.restaurant-slide .restaurant-informations .libelle-container .libelle {
  font-size: 32px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.restaurant-slide .restaurant-informations .libelle-container .libelle img {
  height: 22px;
  margin-right: 6px;
}
.restaurant-slide .restaurant-informations .info {
  display: block;
  font-style: italic;
  color: #949494;
}
@media (min-width: 995px) {
  .restaurant-slide .restaurant-informations .info {
    color: #fff;
    font-size: 24px;
  }
}
.restaurant-slide .restaurant-informations .info img {
  float: right;
  height: 12px;
  margin-top: 4px;
}
.restaurant-slide .restaurant-informations .info .type-label {
  display: block;
}
@media (min-width: 995px) {
  .restaurant-slide .restaurant-informations .info .type-label {
    display: inline-block;
  }
}
.restaurant-slide .restaurant-informations .info a .info-link {
  display: inline-block;
  position: absolute;
  margin-top: -2px;
  margin-left: 5px;
}
.restaurant-slide .restaurant-informations .info a .info-link img {
  width: auto;
  max-height: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
}
@media (min-width: 995px) {
  .restaurant-slide .restaurant-informations .info a .info-link {
    position: initial;
    float: right;
    margin-top: 0;
  }
}
.restaurant-menu.scrollable-content {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
@media (min-width: 995px) {
  .restaurant-menu.scrollable-content {
    -webkit-overflow-scrolling: touch;
    position: static;
  }
}
@media (min-width: 995px) {
  .restaurant-menu {
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 50px;
    margin-left: auto;
    padding-top: 50px !important;
    width: 970px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}
.restaurant-menu > div {
  padding-top: 15px;
}
.restaurant-menu .title {
  text-transform: uppercase;
  color: #949494;
  font-weight: 700;
  font-size: 14px;
  margin: 6px 18px;
  display: block;
}
.restaurant-menu .plat {
  padding: 18px;
  border-top: 1px solid #E7E7E7;
  padding-right: 80px;
  position: relative;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.restaurant-menu .plat:last-child {
  border-bottom: 1px solid #E7E7E7;
}
.restaurant-menu .plat .prix {
  position: absolute;
  right: 30px;
  color: #67A537;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.restaurant-menu .plat .chevroon {
  position: absolute;
  right: 10px;
  top: 50%;
  border-color: #949494;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.like-header {
  height: 30px;
  position: absolute;
  margin-top: -30px;
  width: 100%;
}
@media (min-width: 995px) {
  .like-header {
    display: none;
  }
}
.like-header > * {
  float: right;
  height: 100%;
}
.like-header .banner-1,
.like-header .banner-2 {
  background: white;
}
.like-header .banner-1 {
  width: 50px;
}
.like-header .banner-2 {
  width: calc(100% -  110px);
}
.like-header .banner-3 {
  width: 60px;
  float: right;
  height: 100%;
  background: #fff;
}
.like-header .like-button {
  position: relative;
  z-index: 999;
}
.like-header .like-button.like .button.like {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
}
.like-header .like-button.like .button.unlike {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.like-header .like-button .button {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: .6s transform;
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  top: -30px;
  background: white;
  margin: 4px;
}
.like-header .like-button .button.like {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.like-header .like-button .button.unlike {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
}
.like-header .like-button .button img {
  position: absolute;
  width: 30px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.like-header .like-button > img {
  width: 60px;
}
.fluidite-header {
  height: 30px;
  position: absolute;
  margin-top: -29px;
  width: 100%;
}
.fluidite-header.fluidite-second-position {
  right: calc(70px);
}
.fluidite-header.fluidite-second-position .fluidite-picto {
  border: 2px black solid;
  margin-top: -1px;
  margin-left: -1px;
}
.fluidite-header.fluidite-second-position .banner-1 {
  background: transparent;
}
@media (min-width: 995px) {
  .fluidite-header {
    display: none;
  }
}
.fluidite-header.fluidite-desktop {
  display: none;
}
@media (min-width: 995px) {
  .fluidite-header.fluidite-desktop {
    display: inline-block;
    margin: 0;
    width: 26px;
    height: 26px;
    margin-left: 5px;
    position: static;
  }
  .fluidite-header.fluidite-desktop .fluidite-container {
    display: block;
    width: 26px;
    position: static;
    height: 26px;
    margin-top: 7px;
  }
}
.fluidite-header > * {
  float: right;
  height: 100%;
}
.fluidite-header .banner-1,
.fluidite-header .banner-2 {
  background: white;
}
.fluidite-header .banner-1 {
  margin-left: -1px;
  width: 51px;
}
.fluidite-header .banner-2 {
  margin-right: -1px;
  width: calc(100% -  109px);
}
.fluidite-header .banner-3 {
  width: 60px;
  float: right;
  height: 100%;
  background: #fff;
}
.fluidite-header:not(.fluidite-desktop) .fluidite-button {
  position: relative;
  z-index: 999;
}
.fluidite-header:not(.fluidite-desktop) .fluidite-button.like .button.like {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
}
.fluidite-header:not(.fluidite-desktop) .fluidite-button.like .button.unlike {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.fluidite-header:not(.fluidite-desktop) .fluidite-button .button {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: .6s transform;
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  top: -30px;
  background: white;
  margin: 4px;
}
.fluidite-header:not(.fluidite-desktop) .fluidite-button .button.fluidite-container {
  display: flex;
  align-items: center;
}
.fluidite-header:not(.fluidite-desktop) .fluidite-button .button.fluidite-container a {
  width: 100%;
  height: 100%;
}
.fluidite-header:not(.fluidite-desktop) .fluidite-button .button img {
  position: absolute;
  width: 30px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.fluidite-header:not(.fluidite-desktop) .fluidite-button > img {
  width: 60px;
}
.padding20 {
  padding: 20px;
}
.allergenes-danger {
  color: #949494;
}
.allergenes-danger img {
  outline: none;
  width: 20px;
  float: left;
  padding-right: 12px;
}
.animation-picto {
  float: left;
}
.animation-picto svg {
  width: 18px;
  margin-right: 6px;
  height: 20px;
}
.closure-day {
  margin-bottom: 30px;
  margin-top: 30px;
}
.closure-day .closure-picto {
  border-bottom: 6px solid #67A537;
  width: 145px;
  margin: auto;
  text-align: center;
  padding-bottom: 30px;
}
.closure-day .closure-picto img {
  width: 25%;
}
.closure-day .closure-text {
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 25px;
}
.closure-day .closure-full-infos {
  background-color: #eee;
  margin: 0 20px;
  text-align: center;
  padding: 30px 25px;
}
.closure-day .closure-full-infos span {
  font-size: 32px;
}
.closure-day .closure-full-infos div span {
  color: #67A537;
  font-size: 26px;
}
.closure-header {
  display: none;
  position: absolute;
  top: 0;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  margin-top: 85px;
  text-align: center;
  height: 200px;
  width: 100%;
}
.closure-header .closure-header-text {
  text-transform: uppercase;
  font-size: 26px;
}
.closure-header .closure-header-text .closure-reopen {
  text-transform: initial;
  font-size: 23px;
}
stars {
  float: left;
  margin-right: 18px;
}
stars + span {
  font-size: 18px;
  margin-top: 4px;
  display: block;
}
.advices {
  float: right;
  font-size: 20px;
}
@-webkit-keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-inout {
  -webkit-animation: fadeInOut .5s linear;
          animation: fadeInOut .5s linear;
}
.description-restau {
  /*padding: 10px 20px 20px 20px;
    width: calc(~'100% - 40px');*/
  padding: 10px 20px 20px 20px;
  overflow: auto;
}
@media (min-width: 995px) {
  .description-restau {
    position: absolute;
    top: 45px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 50px;
    margin-left: auto;
    width: 970px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}
.description-restau img {
  max-width: 100%;
}
.description-restau > div {
  padding-top: 24px;
}
.description-restau .title {
  color: gray;
}
.description-restau #descriptionRestau {
  margin: 5px 0;
}
.vote-thematique-svg {
  display: inline-table;
  width: 20px;
}
