﻿@import (reference) '_variables.less';
@import (reference) '_mixins.less';

.parametres-page {
    @accountHeight: 130px;

    .md( {
        background: @desktopGrayBackground;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    );

    .account {
        height: @accountHeight;
        box-sizing: border-box;
        background-image: url("./assets/default-restaurant-blurred.jpg");
        background-size: cover;
        background-position: 50%;
        display: flex;
        align-items: center;

        .md( {
            display: none;
        }

        );
    }

    .scrollable-content {
        height: calc(~'100vh - 200px');

        .md( {
            margin: 50px auto;
            width: 970px;
            background: #fff;
            box-shadow: 0 0 5px fade(#000, 50);
            height: auto;
        }

        );
    }

    ul.menu-list-param {
        padding: 0;
        margin: 0;
        list-style: none;

        .md( {
            margin: 50px auto;
            width: 970px;
            background: #fff;
            box-shadow: 0 0 5px fade(#000, 50);
        }

        );

        li {
            padding-left: 18px;
            padding-right: 18px;

            &:not(.separator) {
                height: 1.3em;
                padding-top: 15px;
                padding-bottom: 15px;
                border-top: 1px solid @lightGray;
                border-bottom: 1px solid @lightGray;

                +li:not(.separator) {
                    border-top: none;
                }

                .picto {
                    width: 1.3em;
                    margin-right: 12px;
                    float: left;
                }

                .title {
                    font-size: 18px;
                }

                .subtext {
                    color: @grayControl;
                    float: right;
                    font-size: 1.1em;

                    .md( {
                        /*text-align: center;
                    float: none;*/
                    }

                    );
                }

                &.has-next {
                    > :last-child::after {
                        content: '';
                        height: 1.3em;
                        width: 1em;
                        float: right;
                        background: url(./assets/arrow-right.png) center / 50% no-repeat;
                        margin-left: 5px;
                        font-size: 18px;
                    }
                }

                &.selected {
                    // ":last-child::after" ne fonctionne pas sous Chrome lorsque la classe "selected" est changée dynamiquement avec Angular
                    &::after {
                        content: '';
                        height: 1.3em;
                        width: 1em;
                        float: right;
                        background: url(./assets/check.png) center / contain no-repeat;
                        font-size: 18px;
                    }
                }
            }

            &.separator {
                background: @lightGray;
                color: @gray;
                text-transform: uppercase;
                font-size: 13px;
                padding-top: 7px;
                padding-bottom: 7px;

                .md( {
                    text-align: center;
                }

                );
            }

            .custom-help-picto {
                float: left;
                color: #77af4b;
                ;
                font-size: 16px;
                width: 13px;
                height: 19px;
                font-weight: 700;
                border: 2px solid;
                border-radius: 12px;
                padding-left: 6px;
                margin-right: 11px;
            }
        }
    }
}

.notification-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid @lightGray;


    .toogle-button {
        margin: initial;
    }

    span {
        font-size: 22px;
    }
}

.notification-page {
    .md( {
        min-height: calc(~"100% - 295px");
        height: auto;
        position: absolute;
        width: 100%;
        background: @desktopGrayBackground;

        .scrollable-content {
            width: 50%;
            background: white;
            margin: auto;
            margin-top: 50px;
            box-shadow: 0 0 5px rgba(0, 0, 0, .5);
        }
    }
    );
}

.alerte-solde-page {

    .default-value {
        margin-bottom: 10px;
        color: @gray;
    }
    form {
        font-family: josefin;
        .value-label {
            font-size: 20px;
            float: left;
            margin: 10px 2px;
            height: 40px;
        }
        .value {
            font-size: 16px;
            float: right;
            border: 1px solid #ddd;

            input {
                text-align: center;
                border: none;
                margin: 0px;
                width: 35px;

                font-family: josefin;
                font-size: 18px;

                -webkit-appearance: none;
                appearance: none;
                margin: 0;

                margin: 0px;
                padding: 0px;

                outline: none;

            }

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            .value-button {
                display: inline-block;
                margin: 0px;
                width: 35px;
                height: 20px;
                text-align: center;
                vertical-align: middle;
                padding: 10px 0;
                background: #eee;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                cursor: pointer;
            }

            &:hover {
                cursor: pointer;
            }

            &#decrease {
                margin-right: -4px;
                border-radius: 8px 0 0 8px;
            }

            &#increase {
                margin-left: -4px;
                border-radius: 0 8px 8px 0;
            }

            form #input-wrap {
                margin: 0px;
                padding: 0px;
            }

        }
        .help {
            float: left;
            margin-bottom: 20px;
        }
    }

    [role="alert"] {
        opacity: 1;
    }
}