.typologies-page {
  height: auto;
}
@media (min-width: 995px) {
  .typologies-page {
    margin-top: -20px;
  }
}
.typologies-page .typologies-header {
  margin: 20px 20px 0px 20px;
}
@media (min-width: 995px) {
  .typologies-page .typologies-header {
    margin: 0px 20px;
  }
}
.typologies-page .typologies-header .typologies-title {
  display: none;
}
@media (min-width: 995px) {
  .typologies-page .typologies-header .typologies-title {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.typologies-page .scrollable-content {
  height: auto;
}
@media (min-width: 995px) {
  .typologies-page .scrollable-content {
    height: 67vh;
  }
}
@media (min-width: 995px) {
  .typologies-page {
    min-height: 100%;
    width: 100%;
    position: absolute;
    background: lightgray;
  }
  .typologies-page .scrollable-content {
    height: 100%;
  }
}
@media (min-width: 995px) {
  .typologies-page .scrollable-content {
    margin: 50px auto;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    width: 970px;
  }
}
.typologies-page .typologie-container {
  margin: 20px;
}
.typologies-page .typologie-container .typologie-pictogramme {
  display: inline-block;
  width: 60px;
  height: 60px;
  vertical-align: middle;
}
.typologies-page .typologie-container .typologie-pictogramme img {
  max-width: 100%;
  max-height: 100%;
}
.typologies-page .typologie-container .typologie-title {
  margin-left: 20px;
  display: inline-block;
  font-weight: bold;
  line-height: 60px;
}
.typologies-page .typologie-container .typologie-description {
  margin-top: 20px;
}
