.dt-offer {
  background-color: white;
  border: 1px solid #E7E7E7;
}
.dt-offer + .dt-offer {
  margin: 10px 0 0;
}
.dt-offer a {
  display: flex;
  padding: 10px 15px;
  font-size: 16px;
}
.dt-offer a .label {
  display: flex;
  flex-grow: 1;
}
.dt-offer a .label img {
  align-self: center;
  margin: 0 10px 0 0;
  vertical-align: middle;
  width: 20px;
}
.dt-offer a .label,
.dt-offer a .chevroon {
  align-self: center;
}
