.confirm-email-page {
  background-image: url("assets/bg-login.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #F2F2F1;
  position: relative;
  padding: 0 20px;
}
@media (min-width: 995px) {
  .confirm-email-page {
    background-image: url('assets/bg-login-big3.jpg');
    background-size: cover;
    margin-top: -20px;
  }
}
.confirm-email-page.scrollable-content {
  height: 100vh;
}
.confirm-email-page header {
  background-color: transparent;
  position: relative;
  width: 100%;
}
.confirm-email-page header h1 {
  line-height: 70px;
  font-size: 42px;
}
.confirm-email-page .confirm-email-container {
  text-align: center;
}
@media (min-width: 995px) {
  .confirm-email-page .confirm-email-container {
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -40%);
            transform: translate(-50%, -40%);
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0 20px 35px 20px;
    width: 500px;
  }
}
.confirm-email-page .confirm-email-container .confirm-email-title {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 25px;
}
.confirm-email-page .confirm-email-container .confirm-email-picto {
  color: #67A537;
  font-size: 36px;
  margin: 25px;
}
.confirm-email-page .confirm-email-container .confirm-email-subtitle {
  font-size: 16px;
  margin-top: 25px;
}
.confirm-email-page .confirm-email-container [role="alert"].error {
  font-weight: bold;
}
.confirm-email-page .confirm-email-container .footer {
  margin-top: 0px;
}
.confirm-email-page .confirm-email-container .footer .services {
  margin-bottom: 0px;
}
