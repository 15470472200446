﻿@import (reference) '../_variables.less';

.light-theme {
	background-color: @lightHeaderColor;

    .header {
        background-color: @lightHeaderColor;
        color: @lightHeaderFontColor;

        h1 {
           div.logo{
               background-image: url(./assets/logo-light.png);
           }
        }
    }

    .back-button {
        color: @lightHeaderFontColor;
        text-shadow: 2px 1px 2px rgba(255,255,255,0.5);

        &:before {
            border-right: 1px solid @lightHeaderFontColor;
            border-top: 1px solid @lightHeaderFontColor;
        }
    }
	
    .md( {
		.back-button {
			color: @darkHeaderFontColor;
			text-shadow: 2px 1px 2px rgba(0,0,0,0.5);

			&:before {
				border-right: 1px solid @darkHeaderFontColor;
				border-top: 1px solid @darkHeaderFontColor;
			}
		}
	} );

    .hamburger {
        span {
            background: @lightHeaderFontColor;
            box-shadow: 0 0 10px rgba(255,255,255,.5);
        }   
    }

    /*menu {
        color: @lightMenuFontColor;
        background-color: fade(@lightMenuColor,95);

        > .account {
            background-color: @lightMenuColor;
            border-top-color: fade(@lightMenuFontColor,20);

            .qrcode {
                background: #999;

                span {
                    color: @lightMenuColor;
                }
            }

            .account-info {
                .name {
                    color: @lightMenuFontColor;
                }

                .recharger {
                    border: 1px solid @lightMenuFontColor;
                    color: @lightMenuFontColor;
                }
            }
        }

        > ul {
            li {
                border-color: fade(@lightMenuFontColor,20);

                * {
                    color: @lightMenuFontColor;

                    .number {
                        background: @lightMenuColor;
                        color: @gray;
                    }
                }
            }
        }
    }*/
}