﻿@import (reference) "_variables.less";

.restaurants-page {
  position: relative;
  height: calc(~"100vh" - 70px);
  overflow: hidden;

  .scrollable-content {
    height: calc(~"100vh - 70px");
  }

  .md({
        height: 100%;

        .scrollable-content{
            height: 100%;
        }
    });

  h2 {
    display: none;
    margin: 45px 0 25px;
    text-align: center;

    .md( {
            display: block;
        }

        );
  }

  .pop-in-container .pop-in-buttons {
    flex-direction: column-reverse !important;

    .pop-in-button.cancel {
      background-color: white;
      color: @mediumGray;
      font-size: 14px;
    }
  }

  .separator-presence {
    height: 5px;
    background-color: @green;
  }
  .message_question {
    padding-left: 4px;
    text-align: left;
    font-size: 16px;
  }
  .message_question_small {
    padding-left: 4px;
    text-align: left;
    font-size: 12px;
  }
  .button-book {
    font-size: 14px;
    line-height: 10px;
    width: auto;
    align-self: center;
    text-align: left;
  }
  .scrollable-events {
    max-height: 300px;
    overflow-y: auto;
  }
  .button-book-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.slider-container {
  position: relative;
  overflow: hidden;
  height: 35vh;

  .md( {
        display: none;
    }

    );

  .slider {
    width: 9000px;
    transition: transform 0.5s;
    position: relative;
    height: 100%;

    .slide {
      float: left;
      width: 100vw;
      background-size: cover;
    }

    img {
      width: 100%;
    }
  }
  @likeButtonBannerHeight: 30px;

  .dots {
    position: absolute;
    bottom: 12px + @likeButtonBannerHeight;
    left: 50%;
    transform: translateX(-50%);

    .dot {
      @circleSize: 8px;
      border-radius: 50%;
      width: @circleSize;
      height: @circleSize;
      background: white;
      margin: 0 3px;
      float: left;
      transition: background 0.5s;

      &.active {
        background: black;
      }
    }
  }
}

.drag-handle {
  @size: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff url(assets/pictos/dragdrop.svg) no-repeat center / @size / 2;
  width: @size;
  height: @size;
  border-radius: @size / 2;
  box-shadow: 0 0 1px;
}

.restaurants {
  overflow: auto;
  text-align: center;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .md({
        overflow: hidden;
    });

  .restaurant {
    position: relative;
    display: block;
    height: 31%;
    width: 100%;
    border-top: 1px solid fade(white, 30);

    a.tile {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;

      &:after {
        content: "";
        display: block;
        position: absolute;
        border-top: 1px solid white;
        border-right: 1px solid white;
        transform: rotate(45deg);
        height: 8px;
        width: 8px;
        bottom: 10px;
        right: 8px;
        color: white;
      }
    }

    .md( {
            display: flex;
            // display: inline-block !important;
            // margin-right: -4px;
            height: 300px !important;
             width: 100% / 3;
            padding: 15px;
        }

        );

    &:first-child {
      border-top: none;
    }

    .restaurant-inner {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }

    .gradient-container {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to top, fade(black, 80), transparent);
      position: relative;
      cursor: pointer;

      .informations-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        color: @fontWhite;
        padding: 8px;
        text-align: left;

        .libelle-container {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          .libelle {
            font-size: 34px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
              height: 22px;
              margin-right: 6px;
            }
          }
        }

        .colored-svg {
          width: 1em;
          height: 1em;
          margin-bottom: 0;
        }

        .info {
          margin-top: 6px;
          display: block;
          font-style: italic;

          img {
            float: right;
            height: 12px;
            margin-top: 4px;
          }
        }
      }
    }
  }

  &.restaurant-1 {
    .gradient-container {
      width: 100%;
      height: 80%;
      background-image: linear-gradient(to bottom, fade(black, 90), transparent);
      position: relative;

      .informations-container {
        top: 10%;

        .libelle {
          font-size: 36px;

          img {
            height: 30px;
            margin-right: 8px;
          }
        }

        .info {
          margin-top: 8px;
          display: block;
          font-style: italic;
          font-size: 17px;

          img {
            float: right;
            height: 12px;
            margin-top: 4px;
          }
        }

        .square-button {
          font-size: 16px;
          margin: 0 5px;
        }
      }
    }
  }

  &.restaurant-1 .restaurant {
    height: 100%;
  }

  &.restaurant-2 .restaurant {
    height: 50%;
  }

  &.restaurant-3 .restaurant {
    height: 100/3%;
  }

  .open {
    svg {
      path {
        fill: @green;
      }
    }
  }

  .close {
    svg {
      path {
        fill: @gray;
      }
    }
  }
}
