﻿@import (reference) "_variables.less";

.nutriscore-page {
    .scrollable-content {
        height: calc(~"100vh - 70px");

        color: @eliorGray;

        .md( {
			margin: 50px 10%;
			background: #fff;
			box-shadow: 0 0 5px fade(#000,50);
            height: auto;
		}

		);
    }

    .nutriscore-content {
        padding: 18px;
    }

    .nutriscore-title {
        text-align: center;
        color: @darkGray;

        font-size: 1.5em;

        display: none;
        .md( {
            display: block;
        }
        );
    }

    .md( {
		background: @desktopGrayBackground;
		position: absolute;
		min-height: 100%;
		width: 100%;
	}
	);

    h2 {
        color: @darkGray;
    }

    ul {
        list-style-type: none;
    }

    .picto {
        height: 50px;
        margin: 0 20px;
        vertical-align: middle;
        margin-bottom: 10px;
    }

    footer {
        font-weight: bold;
        text-align: center;
        color: @darkGray;
    }
}
