.validationField {
  height: 5px;
  margin-top: -15px;
  background-color: lightgray;
  margin-bottom: 15px;
}
.validationField div {
  height: 100%;
  transition: all .4s;
}
