body {
  /** Default **/
  /** Android **/
}
body .smartbanner {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: 'Helvetica Neue', sans-serif;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
}
body .smartbanner-container {
  margin: 0 auto;
  white-space: nowrap;
}
body .smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 5px;
  font-family: 'ArialRoundedMTBold', Arial;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
}
body .smartbanner-icon {
  display: inline-block;
  vertical-align: middle;
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
}
body .smartbanner-info {
  display: inline-block;
  vertical-align: middle;
  width: 44%;
  font-size: 11px;
  line-height: 1.2em;
  font-weight: bold;
}
body .smartbanner-title {
  font-size: 13px;
  line-height: 18px;
}
body .smartbanner-button {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: #6a6a6a;
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}
body .smartbanner-android {
  background: #3d3d3d url(data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7);
  box-shadow: inset 0 4px 0 #88B131;
  line-height: 82px;
  position: absolute;
  z-index: 10000;
  width: 100%;
}
body .smartbanner-android .smartbanner-close {
  border: 0;
  width: 17px;
  height: 17px;
  line-height: 17px;
  margin-right: 7px;
  color: #b1b1b3;
  background: #1c1e21;
  text-shadow: 0 1px 1px #000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3);
}
body .smartbanner-android .smartbanner-icon {
  background-color: transparent;
  box-shadow: none;
}
body .smartbanner-android .smartbanner-info {
  color: #ccc;
  text-shadow: 0 1px 2px #000;
  overflow: hidden;
}
body .smartbanner-android .smartbanner-info span {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
body .smartbanner-android .smartbanner-title {
  color: #fff;
  font-weight: bold;
}
body .smartbanner-android .smartbanner-button {
  min-width: 12%;
  color: #d1d1d1;
  padding: 0;
  background: none;
  border-radius: 0;
  box-shadow: 0 0 0 1px #333, 0 0 0 2px #DDDCDC;
}
body .smartbanner-android .smartbanner-button-text {
  text-align: center;
  display: block;
  padding: 0 10px;
  background: #42B6C9;
  background: linear-gradient(to bottom, #42B6C9, #39A9BB);
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
}
