﻿@import (reference) '_variables.less';

.theme-page {
    .md({
        min-height: 100%;
        position: absolute;
        width: 100%;
        background: @desktopGrayBackground;
    });
}
