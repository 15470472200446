dt-basket .pop-in-content .sk-cube-grid {
  margin: 40px auto;
}
@media (min-width: 995px) {
  dt-payment-error .dt-page {
    min-height: calc(100vh - 315px);
  }
}
.dt-page {
  height: calc(100vh - 70px);
  background: lightgray;
}
@media (min-width: 995px) {
  .dt-page {
    height: auto;
  }
  .dt-page .scrollable-content {
    padding: 50px 0;
  }
}
.dt-basket {
  background: #F2F2F1;
  padding: 10px 10px 0;
}
@media (max-width: 767px) {
  .dt-basket {
    box-sizing: border-box;
    height: calc(100vh -  70px);
  }
}
.dt-basket .sk-cube-grid {
  margin: 0 auto;
  padding: 100px 0;
}
.dt-basket p {
  margin: 0;
}
.dt-basket .basket-intro {
  color: #949494;
  font-size: 14px;
  text-transform: uppercase;
}
.dt-basket .basket-empty,
.dt-basket .basket-articles,
.dt-basket .basket-payment {
  background: #fff;
  padding: 10px;
  margin: 10px -10px;
}
.dt-basket .basket-empty p {
  color: #DE2B2B;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
  text-transform: uppercase;
}
.dt-basket .basket-articles .basket-infos {
  display: flex;
  font-size: 13px;
  margin: 0 0 10px;
}
.dt-basket .basket-articles .basket-infos .date {
  flex: 1;
  text-transform: uppercase;
}
.dt-basket .basket-articles .basket-infos .basket-capacity {
  align-self: flex-end;
  font-weight: bold;
  color: #67A537;
}
.dt-basket .basket-articles .basket-infos .basket-capacity.overload {
  color: #DE2B2B;
}
.dt-basket .basket-articles .basket-infos .basket-error {
  align-self: flex-end;
  font-weight: bold;
  color: #DE2B2B;
  text-align: right;
}
.dt-basket .basket-articles .basket-article {
  border-top: 1px solid #E7E7E7;
  margin: 0 -10px;
  padding: 10px;
}
.dt-basket .basket-articles .basket-article .libelle {
  font-size: 20px;
  display: block;
  margin: 0 0 10px;
  padding: 0 0 0 30px;
}
.dt-basket .basket-articles .basket-amount {
  border-top: 1px solid #E7E7E7;
  border-bottom: 1px solid #E7E7E7;
  font-size: 20px;
  margin: 0 -10px;
  padding: 10px;
  text-align: right;
  text-transform: uppercase;
}
.dt-basket .basket-articles .basket-amount .total {
  margin: 10px 0 0;
}
.dt-basket .basket-articles .basket-amount .price {
  display: inline-block;
  font-weight: bold;
  color: #67A537;
  width: 70px;
  text-align: right;
}
.dt-basket .basket-articles .basket-order-availability {
  margin: 10px 0 0;
}
.dt-basket .basket-cgv {
  display: flex;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  margin: -10px;
  padding: 20px;
  background-color: #F2F2F1;
}
.dt-basket .basket-cgv-checkbox {
  display: flex;
  background: #fff;
  border: 1px solid #949494;
  margin: 0 10px 0 0;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
}
.dt-basket .basket-cgv-checkbox:before {
  content: '';
  display: none;
  height: 10px;
  width: 10px;
}
.dt-basket .basket-cgv-checkbox.checked:before {
  display: block;
  background: #67A537;
}
.dt-basket .basket-cgv .text {
  flex: 1;
}
.dt-basket .basket-cgv a {
  text-decoration: underline;
}
.dt-basket .basket-payment {
  text-align: center;
}
.dt-basket .basket-payment-cb {
  color: #949494;
  font-style: italic;
  margin: 0 0 10px;
}
.dt-basket .basket-purchase-requirement {
  font-size: 14px;
}
@media (min-width: 995px) {
  .dt-basket {
    margin: 0 auto;
    width: 970px;
    padding: 20px 20px 0;
  }
  .dt-basket .basket-intro {
    font-size: 14px;
  }
  .dt-basket .basket-empty,
  .dt-basket .basket-articles,
  .dt-basket .basket-payment {
    padding: 20px;
    margin: 20px -20px;
  }
  .dt-basket .basket-articles .basket-infos {
    font-size: 14px;
    margin: 0 0 20px;
  }
  .dt-basket .basket-articles .basket-article {
    margin: 0 -20px;
    padding: 20px;
  }
  .dt-basket .basket-articles .basket-article .libelle {
    padding: 0 96px 0 40px;
  }
  .dt-basket .basket-articles .basket-amount {
    margin: 0 -20px;
    padding: 20px;
  }
  .dt-basket .basket-articles .basket-amount .total {
    margin: 20px 0 0;
  }
  .dt-basket .basket-articles .basket-order-availability {
    margin: 20px 0 0;
  }
  .dt-basket .basket-cgv-checkbox {
    margin: 0 20px 0 0;
  }
  .dt-basket .basket-payment-cb {
    margin: 0 0 20px;
  }
  .dt-basket .basket-purchase-requirement {
    font-size: 14px;
  }
}
