﻿@import (reference) '_variables.less';

@menuButtonBannerWidth: 60px;
@menuButtonRight: 50px;

.animation-header {
    width: 100%;
    height: 46vh;
    //background: lightgray;
    background-size: cover;
    background-position: top center;

    &.loadimg {
        background-color: @lightGray !important;
        display: flex;
        justify-content: center;
        align-items: center;

        .md({
            display: none;
        });
    }

    &.loadimghead {
        display: none;

        .md ({
            display: block;
        });
    }

    &.survey {
        height: 20vh;
    }

    .md( {
        display: none;
    });
}


.link-to-menu-header {
    height: 30px;
    position: absolute;
    margin-top: -30px;
    width: 100%;

    & > * {
        float: right;
        height: 100%;
    }
    .banner-1, .banner-2 {
        background: white;
    }

    .banner-1 {
        width: @menuButtonRight;
    }

    .banner-2 {
        width: calc(~"100% - "@menuButtonBannerWidth + @menuButtonRight);
    }
    .link-to-menu-button {
        position: relative;
        z-index: 999;
        .button {
            &:hover{
                cursor: pointer;
            }
            &.link-to-menu-container {
                display: flex;
                align-items: center;
                a{
                    width: 100%;
                    height: 100%;
                }
            }
            backface-visibility: hidden;
            transition: .6s transform;
            position: absolute;
            width: 52px;
            height: 52px;
            border-radius: 100%;
            top: -30px;
            background: white;
            margin: 4px;

            img {
                position: absolute;
                width: 30px;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }

        & > img {
            width: @menuButtonBannerWidth;
        }
    }
}
.animation-page {
    .md( {
        min-height: calc(~"100% - 295px");
        position: absolute;
        width: 100%;
        background: @desktopGrayBackground;
    }

    );

    .scrollable-content {
        height: 100vh;
        .md( {
            margin: 50px auto;
            width: @container-md;
            background: #fff;
            box-shadow: 0 0 5px fade(#000,50);
            height: auto;
        }

        );
    }
}

.anim-content {
    .md( {
        margin-top: 80px;
        font-size: 20px;
        padding: 0 50px;
    }

    );
}

.animation-title {
    font-size: 25px;
    width: 100%;
    float: left;

    .md( {
        font-size: 30px;
    }

    );
}

.picto-animation {
    margin: auto;
    position: absolute;
    width: 52px;
    height: 52px;
    background: #fff;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;

    .md( {
        box-shadow: 0 0 3px rgba(0,0,0,.5);
    }

    );

    .colored-svg, img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
    }
}

.general-information {
    margin-top: 30px;
    display: flex;
    align-items: center;

    .md( {
        padding-top: 20px;
    }

    );

    .chevroon-container {
        padding: 10px;

        &.left {
            margin-left: auto;

            .md( {
                margin-left: inherit;
            }

            );
        }
    }

    .chevroon {
        float: left;
    }

    .horaire {
        color: @darkGray;
    }
}

.titles {
    .md({
        text-align: center;
        margin: auto;
    });

     .horaire {
         .date {
             margin: 0 3px;
         }
     }
}

.description {
    padding: 20px;
    font-size: 18px;

    .md( {
        font-size: 22px;
        padding: 30px;
        text-align: center;
    }

    );

    .touchable-anim {
        .md( {
            margin-top: 35px;
        }

        );
    }
}
