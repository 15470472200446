.dt-more-less button {
  width: 30px;
  height: 30px;
  background: #E7E7E7 50% 50% no-repeat;
  border: 1px solid #DBDBDB;
  color: black;
  display: inline-block;
  font-size: 25px;
  line-height: 0;
  padding: 0;
  margin: 0;
  position: relative;
}
.dt-more-less button[disabled] {
  opacity: .3;
  cursor: not-allowed;
}
.dt-more-less .btn-more {
  background-image: url('assets/dinnertakeaway/btn_plus.png');
}
.dt-more-less .btn-less {
  background-image: url('assets/dinnertakeaway/btn_minus.png');
}
