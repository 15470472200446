restaurant-ferme {
  display: flex;
  display: -webkit-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
restaurant-ferme .close-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}
restaurant-ferme .close-container .title {
  text-transform: uppercase;
  font-size: 20px;
}
restaurant-ferme .close-container .informations {
  margin-top: 10px;
}
restaurant-ferme .close-container .white {
  color: white;
}
