dt-home {
  height: 100%;
  display: block;
  background: #F2F2F1;
}
@media (min-width: 995px) {
  dt-home {
    margin-top: -20px;
  }
}
dt-home .scrollable-content {
  background: #F2F2F1;
}
@media (min-width: 995px) {
  dt-home {
    background: lightgray;
  }
  dt-home .scrollable-content {
    background: lightgray;
  }
}
section.dt-home {
  box-sizing: border-box;
  min-height: calc(100vh -  295px);
}
section.dt-home .has-footer {
  min-height: calc(100vh -  365px);
  padding-bottom: 70px;
}
@media (min-width: 995px) {
  section.dt-home .has-footer {
    margin-bottom: 90px;
    min-height: calc(100vh - 455px);
  }
}
@media (min-width: 995px) {
  section.dt-home {
    margin: 0 auto;
    padding: 50px 0 20px;
    width: 970px;
  }
  section.dt-home .border {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  .has-footer section.dt-home {
    margin-bottom: 90px;
  }
}
section.dt-home .days-list a {
  position: relative;
  min-height: 94px;
}
section.dt-home .days-list .reserving {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.dt-home .days-list .reserving .shadow {
  position: absolute;
  background-color: gray;
  opacity: 0.4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
section.dt-home .days {
  min-height: 100%;
  padding: 20px 10px;
  background-color: #F2F2F1;
  box-sizing: border-box;
}
.has-footer section.dt-home .days {
  padding-bottom: 90px;
}
@media (min-width: 995px) {
  .has-footer section.dt-home .days {
    padding-bottom: 20px;
  }
}
section.dt-home .days .expressyourself {
  margin-top: 10px;
  height: 60px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  background-color: black;
  color: white;
  padding: 10px;
}
section.dt-home .days .expressyourself i.icon {
  height: 60px;
  width: 50px;
  margin-right: 10px;
  line-height: 60px;
  margin-bottom: 5px;
}
section.dt-home .days .expressyourself i.icon.express {
  background: url('assets/dinnertakeaway/expressyourself.svg') no-repeat center / contain;
}
section.dt-home .days .expressyourself span {
  text-transform: uppercase;
  font-size: 24px;
  white-space: nowrap;
  line-height: 60px;
}
section.dt-home .dtErrorMessage {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #B40000;
}
section.dt-home .dinnerTakeawayDescription {
  padding: 10px;
  background-color: #fff;
  color: #282828;
}
@media (min-width: 995px) {
  section.dt-home .dinnerTakeawayDescription {
    padding: 10px 20px;
  }
}
section.dt-home .dinnerTakeawayDescription span.until {
  display: block;
}
section.dt-home .dinnerTakeawayDescription span.until strong {
  color: #67A537;
  text-transform: uppercase;
  font-weight: bolder;
}
section.dt-home h2 {
  margin: 0;
  color: #ACACAC;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 13px;
}
section.dt-home .days-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
section.dt-home .days-list > li {
  margin: 10px 0 0;
  background-color: white;
  border: 1px solid #E7E7E7;
  color: #282828;
  display: block;
  font-size: 14px;
}
section.dt-home .days-list > li a {
  display: block;
}
footer.dt-home {
  background-color: #7EC14E;
  position: fixed;
  bottom: 0;
  height: 70px;
  width: 100%;
  color: white;
}
footer.dt-home ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  height: 100%;
}
footer.dt-home ul li {
  height: 100%;
  width: 100%;
}
footer.dt-home ul li a {
  height: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
footer.dt-home ul li a i.icon {
  height: 24px;
  margin-bottom: 5px;
}
footer.dt-home ul li a i.icon.history {
  background: url('assets/dinnertakeaway/history.svg') no-repeat center / contain;
}
footer.dt-home ul li a i.icon.express {
  background: url('assets/dinnertakeaway/expressyourself.svg') no-repeat center / contain;
}
footer.dt-home ul li a i.icon.tc {
  background: url('assets/dinnertakeaway/tc.svg') no-repeat center / contain;
}
footer.dt-home ul li a span {
  text-transform: uppercase;
  opacity: 0.7;
  font-size: 14px;
}
footer.dt-home ul li a:hover:not([disabled]),
footer.dt-home ul li a:hover:not(.disabled) {
  background-color: #67A537;
}
@media (min-width: 995px) {
  footer.dt-home {
    position: fixed;
  }
}
pop-in.dt-home h2 {
  font-weight: normal;
  text-transform: uppercase;
}
pop-in.dt-home ul {
  list-style: none;
  padding: 0;
  font-size: 18px;
  text-transform: uppercase;
  margin: 20px -20px;
}
pop-in.dt-home ul [role=alert] {
  text-transform: none;
  font-size: 16px;
}
pop-in.dt-home ul li {
  border-top: 1px solid #E7E7E7;
  display: flex;
  padding: 5px 20px;
  box-sizing: border-box;
}
pop-in.dt-home ul li.phone {
  border-top-width: 0px;
  flex-direction: column;
  align-items: flex-start;
}
pop-in.dt-home ul li span {
  text-align: left;
  flex-grow: 1;
  align-self: center;
}
pop-in.dt-home ul li:last-child {
  border-bottom: 1px solid #E7E7E7;
}
