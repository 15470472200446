﻿@import (reference) '_variables.less';

.culture-page {
    @alertHeight: 30px;
    .md({
        min-height: calc(~"100% - 295px");
        position: absolute;
        width: 100%;
        background: lightgray;
    });

    [role=alert] {
        height: @alertHeight;
        padding-top: 5px;
        padding-bottom: 5px;
        box-sizing: border-box;
    }

    &.has-error {
        .scrollable-content {
            top: @alertHeight;
        }
    }
}
