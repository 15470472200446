.typologies-component {
  margin: 10px 20px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 15px;
}
.typologies-component .type-title {
  font-size: 20px;
  color: #67A537;
  font-weight: bold;
}
.typologies-component .typologies-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
.typologies-component .typologies-container .typologie-libelles {
  flex: 50%;
}
.typologies-component .typologies-container .typologie-libelles ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.typologies-component .typologies-container .typologie-libelles ul li {
  font-size: 14px;
}
@media (min-width: 995px) {
  .typologies-component .typologies-container .typologie-libelles ul li {
    font-size: 16px;
  }
}
.typologies-component .typologies-container .typologie-pictogrammes {
  flex: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;
}
.typologies-component .typologies-container .typologie-pictogrammes .pictogramme {
  flex: calc(33.33333333%);
  flex-grow: 0;
  box-sizing: border-box;
  padding-top: 10px;
  padding-left: 10px;
}
@media (min-width: 995px) {
  .typologies-component .typologies-container .typologie-pictogrammes .pictogramme {
    flex: 0 0 80px;
  }
}
.typologies-component .typologies-container .typologie-pictogrammes .pictogramme img {
  max-width: 100%;
  vertical-align: middle;
}
.typologies-component .typologie-link {
  align-self: center;
  margin-left: 5px;
}
