.compose-formule .formule-details {
  display: block;
  padding-left: 20px;
  line-height: 130px;
}
@media (min-width: 995px) {
  .compose-formule .formule-details {
    display: none;
  }
}
.compose-formule .restaurant-slide {
  display: none;
}
@media (min-width: 995px) {
  .compose-formule .restaurant-slide {
    display: block;
    height: 100px;
    top: -150px;
  }
  .compose-formule .restaurant-slide .restaurant-informations .libelle-container {
    padding-right: 0;
  }
}
.compose-formule .product-menu {
  margin-bottom: 75px;
}
.compose-formule .cat-libelle {
  font-weight: 600;
}
.compose-formule .cat-libelle,
.compose-formule .cat-sousLibelle {
  width: 100%;
  color: #ffffff;
  font-size: 28px;
  text-align: left;
  text-shadow: 1px 1px 2px #000000;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  color: white;
}
@media (min-width: 995px) {
  .compose-formule .cat-libelle,
  .compose-formule .cat-sousLibelle {
    width: auto;
  }
}
.compose-formule .scrollable-content {
  top: 180px !important;
  height: calc(100vh - 255px);
}
@media (min-width: 995px) {
  .compose-formule .scrollable-content {
    height: auto;
    padding: 0;
    top: 90px!important;
    position: static;
  }
}
.formule-buttons {
  position: absolute;
  width: calc(100% - 36px);
  bottom: 0;
  padding: 18px;
  background-color: #f5f5f5;
}
.formule-buttons button {
  width: 48%;
  display: inline;
  margin: 0;
  float: right;
}
.formule-buttons button.annuler {
  background-color: black;
  float: left;
}
@media (min-width: 995px) {
  .main.compose-formule {
    width: 66%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }
  .formule-buttons {
    width: calc(100% - 36px);
    display: inline-block;
    margin: 0;
    position: static;
    -webkit-transform: none;
            transform: none;
  }
  .compose-formule .product-menu {
    margin-bottom: 0;
  }
}
@media (min-width: 995px) and (min-width: 995px) {
  .main.compose-formule {
    height: auto;
  }
}
