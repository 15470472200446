@import (reference) './_variables.less';

.title .picto {
    /*width: 27px;*/
    height: 27px;
    margin-right: 8px;
    box-sizing: border-box;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
    border-radius: 50%;
    background-color: white;
    padding: 5px;

    display: block;
    width: auto;

    &.bgGreen {
        background: @green;

        .tastytime &{
            background: @beige;
        }
        .roomservicebyelior &{
            background: @eliorRed;
        }
    }

    &.bgGray {
        background: @darkGray;
    }

    svg {
        width: 1em;
        height: 1em;
    }
}
