@media (min-width: 995px) {
  .credit-page {
    background: lightgray;
    position: absolute;
    width: 100%;
    min-height: calc(100% - 295px - 20px);
  }
}
.credit-page .account {
  height: 100px;
  box-sizing: border-box;
  background-image: url("assets/default-restaurant-blurred.jpg");
  background-size: cover;
  background-position: 50%;
  display: flex;
  align-items: center;
}
@media (min-width: 995px) {
  .credit-page .account {
    display: none;
  }
}
.credit-page .scrollable-content {
  height: calc(100vh - 170px);
}
@media (min-width: 995px) {
  .credit-page .scrollable-content {
    height: auto !important;
  }
}
.credit-page .credit-container {
  background-color: white;
}
@media (min-width: 995px) {
  .credit-page .credit-container {
    margin: 50px auto;
    width: 970px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 50px;
  }
}
.credit-page .credit-container .title {
  text-transform: uppercase;
  color: #949494;
  font-weight: 700;
  font-size: 14px;
  margin: 6px 18px;
  display: block;
}
.credit-page .credit-container .montants {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
.credit-page .credit-container .montants .label-montant {
  height: 25vw;
  max-height: 150px;
  flex-basis: 33.33333333%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7e7e7;
  float: left;
  margin: 0 auto;
  outline-style: solid;
  outline-color: white;
  outline-width: 1px;
}
.credit-page .credit-container .montants .label-montant.active {
  background-color: #b0b0b0;
}
.credit-page .credit-container .montants .label-montant.active span {
  color: white;
}
.credit-page .credit-container .montants .label-montant span {
  color: #a1a1a1;
  font-size: 35px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
}
.credit-page .credit-container .loading-button {
  margin: 20px 20px;
}
.credit-page .credit-container button.secondary {
  text-transform: none !important;
}
.credit-page .credit-container .refill-unavailable {
  text-align: center;
}
.credit-page .credit-container .refill-unavailable .picto,
.credit-page .credit-container .refill-unavailable .compte-picto {
  height: 100px;
  border-bottom: 5px solid #67A537;
  padding-bottom: 25px;
  margin: 20px;
}
.credit-page .credit-container .refill-unavailable .oops {
  text-transform: uppercase;
  font-size: 21px;
}
.credit-page .credit-container .refill-unavailable .unavailable-message {
  background-color: #F2F2F1;
  width: calc(100% - 40px);
  padding: 20px;
  font-size: 21px;
  margin-top: 20px;
}
/* Style des fenêtres résultat d'un rechargement ou d'un paiement (click & collect) */
.creditreturn-page {
  padding: 30px 30px 0 30px;
  padding-right: 30px;
}
.creditreturn-page .message {
  background-color: #f4f4f4;
  padding: 25px;
}
.creditreturn-page .message .libelle {
  font-size: 22px;
  margin-bottom: 25px;
}
.creditreturn-page .message button {
  margin-bottom: 0;
}
