@media (min-width: 995px) {
  .restaurant-externe-page {
    background: lightgray;
    position: absolute;
    width: 100%;
    margin-top: 10px;
    height: calc(100% - 295px - 20px - 10px);
  }
  .restaurant-externe-page .scrollable-content {
    height: 100% !important;
  }
}
.restaurant-externe-page .restaurant-externe-container {
  display: block;
  border: 0;
  width: 100%;
}
.restaurant-externe-page .scrollable-content {
  height: calc(100vh - 70px);
}
