@media (min-width: 995px) {
  .tickets-page {
    background: lightgray;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.tickets-page .account {
  height: 100px;
  box-sizing: border-box;
  background-image: url("assets/default-restaurant-blurred.jpg");
  background-size: cover;
  background-position: 50%;
  display: flex;
  align-items: center;
}
@media (min-width: 995px) {
  .tickets-page .account {
    display: none;
  }
}
.tickets-page .scrollable-content {
  height: calc(100vh - 170px);
}
.tickets-page .tickets-container {
  background-color: white;
}
@media (min-width: 995px) {
  .tickets-page .tickets-container {
    margin: 50px auto;
    width: 970px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 50px;
    overflow-y: auto;
  }
}
.tickets-page .tickets-container .title {
  text-transform: uppercase;
  color: #949494;
  font-weight: 700;
  font-size: 14px;
  margin: 6px 18px;
  display: block;
}
.tickets-page .tickets-container .ticket-line {
  padding: 18px;
  border-top: 1px solid #e7e7e7;
  padding-right: 80px;
  position: relative;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 17px;
}
.tickets-page .tickets-container .ticket-line .prix {
  position: absolute;
  right: 30px;
  color: #67A537;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.tickets-page .tickets-container .ticket-line .chevroon {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.tickets-page .tickets-container .ticket-line .ticket-date {
  color: #333333;
}
.tickets-page .tickets-container .ticket-line .ticket-hour {
  color: #a1a1a1;
}
.tickets-page .tickets-container .ticket-line:last-of-type {
  border-bottom: 1px solid #e7e7e7;
}
.tickets-page .refill-unavailable {
  text-align: center;
  padding-top: 30px;
}
